.node-blog-post,
.node-landing-page,
.node-page,
.node-service-detail,
.node-recurring-event,
.node-special-event,
.node-news-item {
  &.view-mode-tile {
    > a {
      .field-signature-media,
      .field-signature-image {
        margin: 0 16px;
      }

      .group-overlay {
        background: white !important;
        border: 1px solid #efefef;
        border-top: 3px solid #ed5025;
        padding: 24px;
        position: relative;

        .field-type h4,
        .field-activity-type h4 {
          .card-eyebrow;
          margin-bottom: 10px;
          margin-top: 0;
        }

        .field-title h3 {
          .card-item-heading;
          margin-top: 0;
        }

        .field-post-date,
        .field-date-time h4 {
          .card-mustache;
          margin-top: 0;
        }
      }


      &:hover,
      &:focus {

          text-decoration: none !important;


        .field-signature-media,
        .field-signature-image {
          position: relative;
          &:before {
            background-color: rgba(148,16,94,0.5);
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        .group-overlay {
          border-color: #ed5025;
          .field-type h4 {
            text-decoration: none;
          }



          .field-post-date {
            text-decoration: none;
          }
          .field-title h3 {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
