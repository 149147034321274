#block-mazzoni-sitewide-search {
  display: none;
}


#region-user-first {
  grid-column: span 12;
}

#block-search-form {

  display: none;
  height: 102px;
  left: 50%;                   /* Move 50% from left */            /* Move 10px from bottom */
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9999;
  max-width: 1200px;

  form {
    background: white;
    margin: 0 auto;

    height: 102px;

    @media @lg-and-up {
      margin: 0 -20px;
    }


    > div {
      display: flex;

      align-items: center;
      width: 95%;
      height: 102px;
      margin: 0 auto;
    }

  }

  .container-inline {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 12px;
    width: 100%;




    &:before {
      -webkit-font-smoothing: antialiased;
      color: #cbccce;
      content: '\f002';
      font-family: 'Font Awesome 5 Free';
      font-size: 1.5rem;
      font-style: normal;
      font-variant: normal;
      font-weight: 900;
      text-rendering: auto;
      white-space: nowrap;
    }
  }

  .form-item {
    flex-grow: 1;
  }

  #edit-search-block-form--2 {
    border: 0;
    color: #cbccce;
    font-family: @pt;
    font-size: 30px;
    letter-spacing: -0.1px;
    line-height: 39px;

    &:focus {
      border: 0;
      outline: none;
    }

    &::placeholder {
      color: #cbccce;
    }
  }

  .form-actions {
    display: flex;
    align-items: center;
  }


  .form-submit {
    .element-invisible;
  }
}
