.paragraphs-item-signpost-container {
  padding-top: 1.5rem;

  @media @md-and-up {
    padding-top: 120px;
    padding-bottom: 80px;
  }
  .field-pg-heading {
    .section-heading;
  }
}



.paragraphs-items-field-pg-signposts {
  display: grid;
  grid-template-columns: 1fr;
  @media @md-and-up {
    grid-template-columns: 1fr 1fr 1fr;
  };

  column-gap: 1.5rem;
  row-gap: 1.5rem;

  .field-pg-signposts {
    position: relative;
    display: flex;

  }

  .paragraphs-item-signpost {
    //height: 100%;

    &[onclick] {
      border: 1px solid #9B9B9B;
      padding: 1.5rem;
      cursor: pointer;

      &:hover,
      &:focus {
        border-color: #EE482E;
      }
    }
  }

  .field-signpost-image {
    margin-bottom: 2rem;
  }

  .field-signpost-heading {
    .signpost-heading;
    margin-bottom: .5rem;
  }
}
