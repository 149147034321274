.node-blog-post {
  &.view-mode-full {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 2rem;

    column-gap: @grid-gutter-width;

    @media @md-and-up {
      margin-top: 4rem;
    }


    .group-header {
      margin-bottom: 2rem;

      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 8;
      }

      .field-post-date {
        color: #e0275e;
        font-family: 'PT Sans';
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

    .group-left {
      display: flex;
      flex-direction: column;

      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 8;
      }


      .field-signature-image,
      .field-signature-media {
        margin-bottom: 2rem;
      }

      .field-body {
        font-size: 17px;
        @media @lg-and-up {
          padding: 0 72px;
        }
      }

      .assets-container {
        @media @lg-and-up {
          padding: 0 72px;
        }
        .field-label {
          .assets-item-heading;
        }

        .field-files,
        .field-links {
          a {
            .assets-item;
          }
        }
      }
    }

    .group-right {
      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 4;
      }
    }

    .group-footer {
      grid-column: span 12;
    }
  }

  &.view-mode-teaser {
    border-bottom: 1px solid #979797;
    display: grid;
    grid-template-columns: 1fr;
    padding: 2rem 0;

    column-gap: @grid-gutter-width;

    &.has-media {
      grid-template-columns: 3fr 5fr;
    }

    .group-left {
    }

    .group-right {
      h4 {
        .taxonomy-term-text;

        margin-top: 0;
      }

      h3 > a {
        .card-item-heading;
      }

      .field-post-date {
        .card-mustache;
      }
    }
  }
}
.node-type-blog-post .title-wrap {
  display: none;
}


.view-display-id-news_related .view-content .views-row {
  margin-bottom: 2rem;
}

.author-container {
  display: flex;
  font-size: 1.1rem;
  margin-top: 1rem;
  .author-label {
    margin-right: .25rem;
  }

  .author-name {
    font-weight: 700;
  }
}
