.node-type-listing-page {
  .grid-9 {
    grid-column: span 12;
    @media @md-and-up {
      grid-column: span 8;
    }
  }

  .grid-3 {
    margin-top: 86px;

    grid-column: span 12;
    @media @md-and-up {
      grid-column: span 4;
    }
  }

  .page-title {
    .page-title-alt;

    border-bottom: 1px solid #979797;
    line-height: 49px;
    padding-bottom: 2rem;
  }

  .view-filters {
    display: none;
  }
}

.node-type-listing-page .title-wrap {
  display: none;
}


#node-listing-page-12328 {
  .page-title {
    border-bottom: 0;
  }
}
