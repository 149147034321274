.front {
  #zone-preface-wrapper {
      display: block !important;

      .region-preface-first {
          height: auto;
      }

      .region-inner {
          flex-direction: column;

          .title-wrap {
              display: none;
          }
      }
  }
}
