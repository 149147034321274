html {
  /* To make use of full height of page*/
  min-height: 100%;
  margin: 0;
}
body {
  min-height: 100%;
  margin: 0;
}

.container-12 {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  width: 100%;

  column-gap: @grid-gutter-width;

  .grid-1,
  .grid-2,
  .grid-3,
  .grid-4,
  .grid-5,
  .grid-6,
  .grid-7,
  .grid-8,
  .grid-9,
  .grid-10,
  .grid-11,
  .grid-12 {
    box-sizing: border-box;
  }
}

.container-12 .grid-12 {
  margin: 0;
  width: 100%;

  grid-column: span 12;
}

.container-12 .grid-3 {
  margin: 0;

  grid-column: span 12;

  @media @md-and-up {
    grid-column: span 3;
  }
}

.container-12 .grid-9 {
  margin: 0;

  grid-column: span 12;

  @media @md-and-up {
    grid-column: span 9;
  }
}
