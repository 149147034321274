.paragraphs-item-billboard {
  position: relative;

  @media @xs-only {
    .full-bleed;
  }


  .group-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
  }

  .field-pg-image {
    img {
      height: 100%;
      width: 100%;
    }
  }

  .group-footer {
    align-items: center;
    background: rgba(224,39,94,0.75);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    margin: auto;
    margin: 0;
    padding: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;

    @media @md-and-up {
      margin: 80px 162px;
      padding: 3rem;
    }

    .field-pg-heading {
      color: @white;
      font-family: @lb;
      font-size: 40px;
      letter-spacing: 0.63px;
      text-align: center;
      line-height: 1.1;

      @media @md-and-up {
        font-size: 80px;
        letter-spacing: 1.25px;
        line-height: 93.14px;
      }
    }

    .field-pg-body {
      color: #fff;
      font-family: 'PT Sans';
      font-size: 17px;
      letter-spacing: 0;
      line-height: 27px;
      text-align: center;
      @media @md-and-up {
        color: @white;
        font-family: 'PT Sans';
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32.99px;
        text-align: center;
      }
    }
  }
}
















.node-blog-post.view-mode-tile > a .group-overlay {
  background: white !important;
  border: 1px solid #efefef;
  border-top: 3px solid #ed5025;
  position: relative;

  .field-type h4 {
    color: #ed5025;
    font-family: 'PT Sans';
    font-size: 16px !important;
    font-weight: bold !important;
    letter-spacing: 0.6px !important;
    line-height: 24px !important;
  }

  .field-title h3 {
    color: #94105e !important;
    font-family: 'PT Sans';
    font-size: 24px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    text-transform: none !important;
  }

  .field-post-date {
    color: #e0275e;
    font-family: 'PT Sans';
    font-size: 16px !important;
    font-weight: bold !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
  }
}

.view-display-id-activities_homepage .view-content {
  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 30px;
  row-gap: 30px;
}

.node-recurring-event.view-mode-featured {
  border-top: 1px solid #979797;
  padding: 20px 0;

  .field-title h2 {
    color: #94105e;
    font-family: 'PT Sans';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }

  .field-event-date-time-info {
    color: #e0275e;
    font-family: 'PT Sans';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.6px;
    line-height: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .field-body {
    color: #2f2f2f;
    font-family: 'PT Sans';
    font-size: 17px;
    letter-spacing: 0;
    line-height: 24px;
  }
}






.front.logged-in {
  #zone-preface-wrapper {
    display: none;
  }
}
