.paragraphs-item-mosaic {
  margin: 80px 0;

  .group-header {
    display: grid;
    grid-template-columns: 1fr;

    column-gap: 24px;
    row-gap: 48px;

    @media @md-and-up {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .field-pg-heading {
    .section-heading;

    grid-column: span 1;

    @media @md-and-up {

    grid-column: span 3;

    }
  }

  .field-pg-content {
    .vocabulary-service-type {
      border: 1px solid #9b9b9b;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 3rem 1.25rem;

      .field-term-image {
        margin-bottom: 16px;
        text-align: center;
      }

      h2 {
        .card-heading;
      }

      .taxonomy-term-description {
        color: #2f2f2f;
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 1.5;
        text-align: left;
        margin: 0;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
