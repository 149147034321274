#block-views-homepage-featured-block {

  .tile;
  position:relative;
  margin-top: 11px;

  .view-content {

    color:@white;
    margin:auto;
    position:relative;
    z-index:20;
    width:72%;

    .resp(@mobilemax, {
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      text-align: center;
      margin: 0;
    });

    a { color:@white; }

    .node {
      position: relative;
      padding-left: 84px;

      &:before {
        content: ' ';
        width: 55px;
        height: 55px;
        position: absolute;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 20px;

        .resp(@mobilemax, {
          content: none;
        });
      }

      &.node-blog-post:before {
        background-image: url(../images/write_page.svg);
      }
      &.node-resource:before {
        background-image: url(../images/briefcase.svg);
      }
      &.node-news-item:before {
        background-image: url(../images/news.svg);
      }
      &.node-special-event:before {
        background-image: url(../images/icon-tickets--white.svg);
      }
      &.node-recurring-event:before {
        background-image: url(../images/calendar.svg);
      }

      .resp(@mobilemax, {
        padding-left: 0;
      });

      .field-type h4,
      > h4 {

        color:@orange;
        text-transform:uppercase;
        letter-spacing:1px;
        font-weight:bold;
        font-size:14px;
        margin:0 0 5px;
        .pt;

        .resp(@mobilemax, {
          padding: 0 40px 17px;
          position: relative;

          &:after {
            content:  ' ';
            position: absolute;
            bottom: 6px;
            left: 20%;
            right: 20%;
            border-bottom: 1px solid fade(@pink, 30%);
          }
        });

      }

      .field-title {
        position: relative;

        h3,
        > h3 {

          .pt;
          margin:0;
          text-transform: none;
          font-size:20px;
          line-height: 1.2;

        }
      }

      .field-post-date {

        color:@g3;
        font-size: 14px;
        margin-top: 3px;

      }

    }

    .node-recurring-event {

      .field-title h2 {
        font-family: 'PT Sans',sans-serif;
        color: white;
        text-transform: none;
        font-size: 20px;
        font-weight: bold;
         margin: 0px;
      }

      .field-event-type2 {
        color: #ee482e;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 5px;
        font-family: 'PT Sans',sans-serif;
        margin: 0px !important;
      }
    }

  }

  .item-list {

    position:absolute;
    z-index:10;
    top:50%;
    left:0;
    -webkit-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    transform:translateY(-50%);
    height:60px;
    width:100%;

    .resp(@mobilemax, {
      top: 0;
      -webkit-transform:translateY(0);
      -ms-transform:translateY(0);
      transform:translateY(0);
    });

    .pager {

      height:60px;
      padding:0;

      background: transparent;

      .r(0);

      .pager-current,
      .ajax-progress { display:none; }

      .pager-previous,
      .pager-next {

        position:absolute;
        background:transparent;
        top:0;
        .r(0);

        width: auto;

        span {
          display: none;
        }

        a {

          .pt;
          .trans;
          background:rgba(56,6,39,.3);
          display:block;
          height:60px;
          padding:0;
          font-size:38px;
          text-align:center;
          width:60px;
          color:@white;
          .r(0);

          text-indent: 0;
          &:before {
            content: none;
          }

          &:hover {
            background:rgba(56,6,39,.5);
            text-decoration: none;
          }

        }

      }

      .pager-previous {

        left:3.25%;

        .resp(@mobilemax, {
          margin: 0;
          left: 0;
        });

      }

      .pager-next {

        right:3.25%;

        .resp(@mobilemax, {
          margin: 0;
          right: 0;
        });

      }

    }

  }

  .block-inner {
    background:rgba(112,12,77,.7);
    padding: 24px 30px;
  }
}
