.top-level-nav {
  .resp(@tabletmax, {
    display: none;
  });

  .menu-name-main-menu,
  .menu-name-menu-trans-wellness-menu {
    .menu-title-search,
    .menu-title-secondary-menu,
    .menu-title-callout-links,
    .menu-title-trans-wellness-callout-links {
      display: none !important;
    }

    > .menu {
      display: table;
      font-size: 0;
      text-align: center;
      width: 100%;

      // TOP LEVEL
      > li {
        .trans;

        display: table-cell;
        font-size: 13px;
        height: 50px;
        line-height: inherit;
        position: relative;
        text-align: left;
        vertical-align: middle;

        > a {
          .trans;

          color: #94105e;
          cursor: pointer;
          display: block;
          display: block;
          font-family: 'PT Sans';
          font-size: 16px;
          font-weight: bold;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 20px;
          padding: 24px 9px;
          text-align: center;
          text-transform: uppercase;

          &:hover {
            text-decoration: none;
          }
        }

        &:hover,
        &:focus {
          .menu {
          }
        }
      }


      // 1ST NESTING
      > li > .menu {
        .trans(all, 0ms, ease, 250ms);

        background-color: white;
        border: 1px solid #e5e5e5;
        display: none;
        flex-direction: column;
        left: 0;
        line-height: 1.4;
        max-width: 220px;
        min-width: 200px;
        overflow: hidden;
        position: absolute;

        &:before {
          background-color: white;
          bottom: 0;
          content: ' ';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
      }
      > li:hover > .menu,
      > li:focus > .menu {
        .cf;

        display: flex;
        max-height: auto;
        padding: 18px 24px;
      }



      // 2ND NESTING
      > li > .menu > li {
        .sizing;

        display: inline-block;
        font-size: 15px;
        padding: 10px 0;
        position: relative;
        vertical-align: top;
        z-index: 3;



        > a {
          .trans;

          color: #2f2f2f;
          display: block;
          font-family: 'PT Sans';
          font-size: 17px;
          letter-spacing: 0;
          line-height: 23px;
        }

        &:hover > a {
          color: #94105e;
          text-decoration: none;
        }

        &.menu-item--aside {
          color: @white;
          display: block;
          font-size: 22px;
          font-style: italic;
          left: 50%;
          line-height: 1.4;
          padding-left: 5%;
          position: absolute;
          right: 40px;
          text-transform: none;
          top: 40px;
          width: 45%;
        }
      }

      // 3RD NESTING
      > li > .menu > li > .menu {
        display: none;

        > li {
          > a {
            color: @white;
            display: none;
            font-size: 14px;
            line-height: 1.4;
            padding: 5px 15px;
            text-transform: none;
          }
        }
      }
    }
  }

  .menu-name-menu-trans-wellness-menu > .menu > li {
    > .menu {
      max-width: 100%;
      > li {
        padding: 10px 30px;
        text-align: center;
        width: 20%;
      }
      @supports (display: flex) {
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        justify-content: space-around;

        > li {
          width: auto;
        }
      }
    }
    &:hover > .menu {
      padding: 30px 40px 25px;

      column-count: 1;
    }
  }
}


.toggleNav {
  display: none;
  @media @xs-only {
    @barColorClosed: @p2;
    @barColorOpen: @white;
    .trans;

    box-sizing: border-box;
    display: inline-block;
    float: none;
    height: 80px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 80px;

    &.isClosed {
      background-color: transparent;
    }
    &.isOpen {
      background-color: @white;
    }

    .text {
      .trans;

      background-color: #94105e;
      display: block;
      height: 3px;
      left: 30px;
      position: absolute;
      right: 20px;
      text-indent: -9999px;
      top: 50%;
      transform: translateY(-50%);

      &:before,
      &:after {
        .trans;

        background-color: #94105e;
        content: ' ';
        height: 3px;
        left: 0;
        position: absolute;
        right: 0;
        transform-origin: 50%;
        width: 100%;
      }

      &:before {
        transform: rotate(0) translateY(-8px);
      }
      &:after {
        transform: rotate(0) translateY(8px);
      }
    }

    &.isOpen .text {
      background-color: transparent;

      &:before,
      &:after {
        background-color: #94105e;
      };
      &:before {
        transform: rotate(45deg) translateY(0);
      }
      &:after {
        transform: rotate(-45deg) translateY(0); ;
      }
    }
  }
}
.mobile_main_menu {
  .resp(@tabletmax, {
    .trans;

    display: inline-block;
    overflow: hidden;
    width: 100%;

    &.isClosed {
      max-height: 0;
    }
    &.isOpen {
      max-height: 3000px;
    }
  });

  clear: both;
  display: none;

  .menu-item--aside {
    display: none;
  }

  .menu-title-trans-wellness-conference a,
  .menu-title-patient-portal a,
  .menu-title-donate-now a,
  .menu-title-submit-a-workshop a,
  .menu-title-tickets--registration a,
  .menu-title-general-conference-registration- a,
  .menu-title-professional-track-registration-only- a {
    padding-left: 64px !important;
    position: relative;

    &:before {
      background-color: fade(@purple-900, 30%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      bottom: 0;
      content: ' ';
      left: 0;
      position: absolute;
      top: 0;
      width: 48px;
    }
  }

  .menu-title-trans-wellness-conference a:before {
    background-image: url('../images/icon-trans-wellness--white.svg');
  }
  .menu-title-patient-portal a:before {
    background-image: url('../images/icon-patient-portal--white.svg');
  }
  .menu-title-donate-now a:before {
    background-image: url('../images/icon-donate--white.svg');
  }
  .menu-title-submit-a-workshop a:before {
    background-image: url('../images/icon-lightbulb--white.svg');
  }
  .menu-title-tickets--registration a:before {
    background-image: url('../images/icon-tickets--white.svg');
  }
  .menu-title-general-conference-registration- a:before {
    background-image: url('../images/icon-tickets--white.svg');
  }
  .menu-title-professional-track-registration-only- a:before {
    background-image: url('../images/briefcase.svg');
  }

  .menu-title-search {
    .nolink {
      display: none;
    }

    .block-mazzoni {
      input {
        .placeholder({
          color: @white;
          letter-spacing: 0.3px;
        });

        background-color: @p1;
        border: none;
        border-bottom: 1px solid @p0;
        box-sizing: border-box;
        color: @white;
        font-family: @pt;
        font-size: 16px;
        font-weight: bold;
        height: auto;
        line-height: 1.7;
        min-height: 0;
        padding: 10px 16px;
        text-shadow: 2px 2px 4px fade(@black, 25%);

        &:focus {
          .placeholder({
            color: fade(@white, 25%);
            text-shadow: none;
          });

          background-color: @orange;
          color: @white;
        }
      }

      .form-actions {
        .element-invisible;
      }
    }
  }

  .menu-name-main-menu,
  .menu-name-menu-trans-wellness-menu {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
    a {
      &:not(.contextual-links-trigger) {
        background-color: white;
        color: #94105e;
        display: block;
        font-family: @pt;
        font-size: 16px;
        font-weight: bold;
        padding: 15px 20px;
        text-transform: uppercase;


        &:hover {
          text-decoration: none;
        }

        &.active {
        }
      }

      &.containsToggler {
        padding-right: 56px;
        position: relative;
      }
      .toggler {
        .resp(@tabletmax, {
          align-items: center;
          background-color: white;
          bottom: 0;
          color: #94105E;
          display: flex;
          font-family: 'Font Awesome 5 Free';
          font-size: 1.5rem;
          font-style: normal;
          font-variant: normal;
          font-weight: 900;
          justify-content: center;
          position: absolute;
          right: 0;
          text-rendering: auto;
          top: 0;
          white-space: nowrap;
          width: 40px;
          z-index: 20;

          &:before {
            content: '\f107';
          }



          &.toggleOpen {

            &:before {
              content: '\f106';
            }
          }
        });

        display: none;
      }
    }
    > .menu {
    }
    > .menu > li {
    }
    > .menu > li > .menu {
      overflow: hidden;

      &.hasToggler {
        .resp(@tabletmax, {
          display: none;

          &.menuOpen {
            display: block;
          }
        });
      }
    }

    > .menu > li > .menu > li {
      > a {
        background-color: white;
        text-transform: none;
        font-weight: normal;
        padding: 10px 20px;

        color: #2F2F2F;

        &:hover,
        &.active {
        }
      }
    }
  }
}
