
.page-title {
  color: #94105e;
  font-family: @pt;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 1rem;

  @media @md-and-up {
    font-size: 42px;
    letter-spacing: 0.14px;
  }
}

.page-title-alt {
  color: #ed5025;
  font-family: @lb;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.17px;
  line-height: 1.1;

  @media @md-and-up {
    font-size: 50px;
    letter-spacing: 0.17px;
    line-height: 1.1;
  }
}




.primary-heading {
  color: #2f2f2f;
  font-family: @pt;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;

  @md-and-up {
    font-size: 32px;
  }
}



.secondary-heading {
  color: #2f2f2f;
  font-family: @pt;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;

  @md-and-up {
    font-size: 24px;
  }
}


.tertiary-heading {
  color: #2f2f2f;
  font-family: @pt;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;

  @md-and-up {
    font-size: 20px;
  }
}


.quaternary-heading {
}


.quinary-heading {
}


.blockquote-copy {
  color: #94105e;
  font-family: @pt;
  font-size: 29px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 42px;
}

.mustache-post-date {
  color: #e0275e;
  font-family: @pt;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.mustache-title {
  color: #e0275e;
  font-family: 'PT Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 24px;
}

.eyebrow-term {
  color: #ed5025;
  font-family: @pt;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.assets-item-heading {
  border-bottom: 1px solid #b2b2b2;
  color: #ed5025;
  font-family: @pt;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.68px;
  line-height: 24px;
  padding-bottom: .5rem;
}

.assets-item {
  color: #2f2f2f;
  font-family: @pt;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 22px;

  &:hover,
  &:focus {
    border-bottom: 1px solid #e0275e;
    text-decoration: none;
  }
}

.sidebar-item-heading {
  color: #ee482e;
  font-family: @lb;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
}

.tag-filter {
  color: #2f2f2f;
  font-family: @pt;
  font-size: 16px;
  letter-spacing: 0.75px;
  line-height: 28px;
  text-transform: uppercase;
}


.card-item-heading {
  color: #94105e;
  font-family: @pt;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}

.card-eyebrow {
  color: #ed5025;
  font-family: @pt;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 24px;
  text-transform: uppercase;
}

.card-mustache {
  color: #e0275e;
  font-family: @pt;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.card-overview-text {
  color: #000;
  font-family: @pt;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.card-heading {
  color: #94105e;
  font-family: @lb;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}


.signpost-heading {
  color: #94105e;
  font-family: @lb;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0;
  text-align: center;
}

.section-heading {
  color: #ee482e;
  font-family: @lb;
  font-size: 36px;
  letter-spacing: 0.56px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: center;

  @media @md-and-up {
    font-size: 48px;
    letter-spacing: 0.75px;
    margin-bottom: 3rem;
  }
}


.posting-heading {
  color: #94105e;
  font-family: @pt;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}


.footer-section-heading {
  color: #94105e;
  font-family: @pt;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.footer-heading {
  color: #2f2f2f;
  font-family: @pt;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.footer-menu-item {
  color: #e0275e;
  font-family: @pt;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}


.disclaimer-text {
  color: #2f2f2f;
  font-family: @pt;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.6px;
  line-height: 26px;
}

.copyright-text {
  color: #fff;
  font-family: @pt;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 22px;

  @media @md-and-up {
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 20px;
  }
}

.taxonomy-term-text {
  color: #ed5025;
  font-family: @pt;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-transform: uppercase;
}

.story-heading {
  color: #94105e;
  font-family: 'PT Sans';
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 58px;
  @media @md-and-up {
    font-size: 40px;
    letter-spacing: 0;
    line-height: 58px;
  }
}

.story-text {
  color: #2f2f2f;
  font-family: 'PT Sans';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;

  @media @md-and-up {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 38px;
  }
}
