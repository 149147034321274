// This is crazy specific to handle a
// single iframe embed for the Press page.
.page-node-10258 {
  .view-mode-full {
    .field-body {
      max-width: none;
      width: 100%;
    }
  }
}
