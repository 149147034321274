



/* --- [ Donate Now ] --------------- */

.page-donate,
.context-donate-now {
  #zone-content-wrapper { padding-top:0; }



}

#fsPage1213473-1 { overflow:hidden; }

table tr {

	border-bottom:1px solid #dedede;

}

#fsCell15531034,
#fsCell15498037 {

	margin-bottom:20px;
	padding-bottom:20px;

	legend { margin-bottom:5px; }

	input,label { float:left; }

	label { margin:-3px 10px 0 5px; }

	#field15531034_othervalue { margin-top:-8px }

}

.fsForm .fsCell { padding:9px !important; }

input[type=button] {

	background:#008576;
	color:#fff;
	padding:3px 15px;
	-webkit-box-shadow:inset 0 0 3px rgba(255,255,255,.3),2px 2px 1px rgba(0,0,0,.15) !important;
	-moz-box-shadow:inset 0 0 3px rgba(255,255,255,.3),2px 2px 1px rgba(0,0,0,.15) !important;
	box-shadow:inset 0 0 3px rgba(255,255,255,.3),2px 2px 1px rgba(0,0,0,.15) !important;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	line-height:1;
	font-size:13px !important;
	border:0;
	cursor:pointer;

	-webkit-transition: all 100ms linear;
	-moz-transition: all 100ms linear;
	-o-transition: all 100ms linear;
	transition: all 100ms linear;
	text-shadow:1px 1px 0 rgba(0,0,0,.3);

}

input[type=button]:hover {

	background:#e8941a;

}

.fsPagination { padding-right:4px; }
