.node-service-detail {
  &.view-mode-full {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: @grid-gutter-width;

    @media @md-and-up {
      margin-top: 4rem;
    }


    .group-header {
      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 8;
      }
    }

    .group-left {
      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 8;
      }

      display: grid;
      grid-template-columns: repeat(8, 1fr);

      .field-body {
        font-size: 17px;
        grid-column: span 8;
        @media @md-and-up {
        grid-column: 2 / span 6;
        }

      }
    }

    .group-right {
      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 4;
      }
    }

    .group-footer {
      grid-column: span 12;
    }
  }
}
.node-type-service-detail .title-wrap {
  display: none;
}

.field-patient-portal-link {
  margin-top: 2rem;
  a {
    .btn;
     .btn-secondary;
  }
}
