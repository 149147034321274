.node-type-landing-page {
  .zone-preface-wrapper {

  }

  .zone-preface {

  }

  .region-preface-first {
    height: 450px;
  }

  .region-preface-first-inner {
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: column;

    &:before {
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      width: 1px;
      margin-left: -1px;
    }

    > .title-wrap {
      border-top: 6px solid #EE482E;
      width: 90vw;
      max-width: 1200px;
      align-self: flex-end;
      background-color: @white;
      margin: 0 auto;
      padding-bottom: 0 !important;
      order: 2;

      .title {
        .page-title-alt;
        text-align: center;
         margin: 0 auto;
         max-width: 90%;
      }

    }
  }
}




.node-landing-page.view-mode-full {
  .field-overview-text {
    color: #2F2F2F;
    font-family: "PT Sans";
    font-size: 20px;
    letter-spacing: 0.14px;
    line-height: 34px;
    text-align: center;
    max-width: 64ch;
    margin: 0 auto;
  }
}
