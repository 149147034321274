// Custom styles for the pager component.
//
// Override variables in 00-base/_01-variables.scss before customizing here.
//
// -----------------------------------------------------------------------------


.pager {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 !important;
  margin-top: 2rem !important;
  padding: 0 !important;
  position: relative;

  > li {
    align-items: center;
    border: 2px solid #ee482e;
    display: flex;
    font-size: 18px;
    font-size: 15px;
    font-weight: bold;
    height: 42px;
    justify-content: center;
    line-height: 1.5;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0 !important;
    text-align: center;
    width: 42px;

    a,
    span {
      .rem-calc(15);

      align-items: center;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      line-height: 1;
      width: 100%;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      background: #94105e;
      border-color: #94105e;
      color: @white;

      a {
        color: @white;
        text-decoration: none;
      }
    }

    &.pager-current {
      background: #94105e;
      border-color: #94105e;
      color: @white;
    }


    &.pager-first {
      border: 0;
      height: 48px;
      margin-right: 0;
      width: 48px;
      a,
      span {
        -webkit-font-smoothing: antialiased;
        color: #ee482e;
        font-family: 'Font Awesome 5 Free';
        font-size: 0;
        font-style: normal;
        font-variant: normal;
        font-weight: 900;
        text-rendering: auto;
        white-space: nowrap;
        &:before {
          content: '\f100';
          font-size: 1.4rem;
        }

        &:hover,
        &:focus {
          background: @white;
          color: #ee482e;
        }
      }
    }

    &.pager-previous {
      border: 0;

      a,
      span {
        -webkit-font-smoothing: antialiased;
        color: #ee482e;
        font-family: 'Font Awesome 5 Free';
        font-size: 0;
        font-style: normal;
        font-variant: normal;
        font-weight: 900;
        text-rendering: auto;
        white-space: nowrap;
        &:before {
          content: '\f104';
          font-size: 1.4rem;
        }

        &:hover,
        &:focus {
          background: @white;
          color: #ee482e;
        }
      }
    }
    &.pager-next {
      border: 0;
      margin-right: 0;
      a,
      span {
        -webkit-font-smoothing: antialiased;
        color: #ee482e;
        font-family: 'Font Awesome 5 Free';
        font-size: 0;
        font-style: normal;
        font-variant: normal;
        font-weight: 900;
        text-rendering: auto;
        white-space: nowrap;
        &:before {
          content: '\f105';
          font-size: 1.4rem;
        }

        &:hover,
        &:focus {
          background: @white;
          color: #ee482e;
        }
      }
    }
    &.pager-last {
      border: 0;
      a,
      span {
        -webkit-font-smoothing: antialiased;
        color: #ee482e;
        font-family: 'Font Awesome 5 Free';
        font-size: 0;
        font-style: normal;
        font-variant: normal;
        font-weight: 900;
        text-rendering: auto;
        white-space: nowrap;
        &:before {
          content: '\f101';
          font-size: 1.4rem;
        }

        &:hover,
        &:focus {
          background: @white;
          color: #ee482e;
        }
      }
    }

    &.pager-ellipsis {
      -webkit-font-smoothing: antialiased;
      border: 0;
      color: #ee482e;
      font-family: 'Font Awesome 5 Free';
      font-size: 0;
      font-style: normal;
      font-variant: normal;
      font-weight: 900;
      margin-right: 0;
      text-rendering: auto;
      white-space: nowrap;
      &:before {
        content: '\f141';
        font-size: 1.4rem;
      }

      &:hover,
      &:focus {
        background: white;
        color: #ee482e;
      }
    }
  }
}
