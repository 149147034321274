.paragraphs-item-custom-feed {
  padding: 5rem 0;

  .field-pg-heading {
    .section-heading;
  }

  .view-content {
    display: grid;
    grid-template-columns: 1fr;

    column-gap: 1.5rem;
    row-gap: 3rem;

    @media @md-and-up {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.view-display-id-activities_homepage {
  .view-content {
    display: grid;
    grid-template-columns: 1fr;

    column-gap: 1.25rem;
    row-gap: 3rem;

    @media @md-and-up {
      grid-template-columns: 1fr 1fr;
    }
  }
}


.view-id-menu_links.view-display-id-default {
  .views-row {
    > a {
      display: block;
      position: relative;

      @media @xs-and-up {
        min-height: 213px;
      }

      &::before {
        background: rgba(0,0,0,0.5);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;;
      }

      img {
        min-height: 240px;
      }

      .field-title {
        color: #fff;
        font-family: 'PT Sans';
        font-size: 24px;
        font-weight: bold;
        left: 50%;
        letter-spacing: 0;
        line-height: 32px;
        margin: 0;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover,
      &:focus {
        &:before {
          background: rgba(148,16,94,0.5);
        }
      }
    }
  }
}
