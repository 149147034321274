// Mosaic
#block-views-homepage-mosaic-block {

  > .block-inner > .content {
    position: relative;

    .view-id-homepage_mosaic {
      overflow: hidden;
    }
  }

  .person-expanded {
    .img img { width:100%; height:auto; }


    #person-expanded {
      position:absolute;
      top: 0;
      bottom: 30px;
      z-index:30;
      overflow: hidden;
      height: auto;
      //.trans(all,.2s,ease);
      background:@p2;
      display: none;
      width: ~"calc(40% - 15px)";

      &.show-person {
        display: block;
      }

      &.person-column-0 {
        left: 0;
      }
      &.person-column-1 {
        left: 20.5%;
      }
      &.person-column-2 {
        left: 41%;
      }
      &.person-column-3 {
        left: calc(~"60% + 15px");
      }
      &.person-column-4 {
        right: 0;
      }

      .resp(@tabletmax, {
        &.person-column-0,
        &.person-column-1,
        &.person-column-2,
        &.person-column-3,
        &.person-column-4 {
          left: 20px;
          bottom: 25px;
          width: 100%;
          right: 20px;
          max-width: 100%;
          width: auto;
        }
      });
    }

    .person-expanded-content {

      position:relative;
      width: 100%;
      height: 100%;
      overflow:  hidden;

      .overlay {

        background:rgba(137,15,94,.8);
        position:absolute;
        height:100%;
        width:100%;
        top:0;
        left:0;
        padding:30px;
        color:@white;
        .sizing;

        h2 {

          font-size:22px;
          letter-spacing:2px;
          max-width:350px;
          line-height: 1.5;

          .resp(@mobilemax, {
            font-size: 18px;
            margin: 0;
            padding-right: 32px;
          });

        }

        .quote-wrapper {

          clear:both;
          width:100%;
          font-size:16px;
          line-height:1.8;

          .link:before { content:"\00a0"; }

          .quote {

            .resp-range(@tabletmax + 1, 1200px, {
              position: absolute;
              top: 36%;
              bottom: 20%;
              left: 30px;
              right: 30px;
              overflow: auto;
            });

            .resp(@mobilemax, {
              position: absolute;
              top: 28%;
              bottom: 20%;
              left: 30px;
              right: 30px;
              overflow: auto;
              font-size: 15px;
              line-height: 1.5;
              margin-top: 10px;
            });
          }

        }

        a { color:@white; font-weight:bold; }

        .related {

          position:absolute;
          border-top:1px solid rgba(255,255,255,.3);
          padding-top:15px;
          bottom:30px;
          width:394px;
          line-height:1;

          a:after { content:"\00a0\00BB"; }

          .resp(@tabletmax, {
            width: 100%;
            width: auto;
            right: 30px;
            left: 30px;
          });
        }

        .close-x {

          height:40px;
          width:40px;
          position:absolute;
          top:23px;
          right:20px;

          display:block;
          background:transparent url('../images/x.svg') center center / 18px 18px no-repeat;
          display:block;
          text-indent:-9999px;
          overflow:hidden;
          opacity:.6;
          cursor:pointer;
          .trans;

          &:hover { opacity:1; }

        }

      }

    }

  }

  .people-thumbnails {

    background:@white;
    margin: -15px 0 15px;

    .row {

      width: auto;
      min-width: 100%;
      margin: 0 -15px;
      font-size: 0;

      .resp(@tabletmax, {
        margin: 0;
        padding: 0 10px;
        box-sizing: border-box;
        &:nth-of-type(n + 2) { display: none; }
      });


      .person {

        position:relative;
        width: 20%;
        padding: 15px;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;

        .resp(@tabletmax, {
          width: 50%;
          padding: 10px;
          &:nth-of-type(n + 5) { display: none; }
        });

        img { width:100%; height:auto; }

        .person-content {

          position:absolute;
          width:auto;
          height:auto;
          top: 15px;
          right: 15px;
          bottom: 15px;
          left: 15px;

          .resp(@tabletmax, {
            top: 10px;
            right: 10px;
            bottom: 10px;
            left: 10px;
          });

          .person-hover {

            width:100%;
            height:100%;

            background:rgba(137,15,94,.8);
            color:@white;

            opacity:0;
            cursor:pointer;

            .trans;

            .person-text {

              display:table;
              height:100%;
              width:100%;
              vertical-align:middle;

              .person-text-inner {

                display:table-cell;
                height:100%;
                width:100%;
                vertical-align:middle;
                text-align:center;

                .info {

                  .lb;
                  font-size:20px;
                  letter-spacing:.5px;
                  line-height: 1.5;

                }

              }

            }

          }

          &:hover .person-hover { opacity:1; }

        }

      }

      .person-last { margin-right:0; }

    }

  }

}
