.btn {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.btn-primary {
  .btn;

  background-color: #e0275e;
  border-color: #e0275e;
  color: @white !important;
  &:hover,
  &:focus {
    background-color: #94105e;
    border-color: #94105e;
  }
}

.btn-secondary {
  .btn;

  background-color: @white;
  border-color: #e0275e;
  color: #94105e !important;
  &:hover,
  &:focus {
    background-color: #e0275e;
    border-color: #e0275e;
    color: @white !important;
  }
}

.btn-tertiary {
  .btn;

  background-color: #380627;
  border-color: #380627;
  color: @white !important;
  &:hover,
  &:focus {
    background-color: @white;
    border-color: #380627;
    color:  #380627 !important;
  }
}
