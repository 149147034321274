img { vertical-align:bottom; }

img,input { max-width:100%; }

.field-signature-image img {
  width: 100%;
  height: auto;
}
.field-trans-image img {
  width: 100%;
}
