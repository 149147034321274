.sidebar-container,
.block-mazzoni-filter-topic,
.block-mazzoni-filter-type,
.block-mazzoni-filter-activity {
  .sidebar-wrapper;

  h2 {
    .sidebar-item-heading;
    border-bottom: 0 !important;
    margin-bottom: 1rem;
  }

  .menu, ul {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;
    list-style-type: none;
    margin-bottom: 0;
    > li {
      > a {
        .tag-filter;


      }

      &.selected a {
        text-decoration: underline;
      }
    }
  }

  .event__date,
  .event__location,
  .event__contact {
    display: table;

    &:before {
      -webkit-font-smoothing: antialiased;
      color: #94105e;
      display: inline-block;
      font-family: 'Font Awesome 5 Free';
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      font-size: 1.4rem;
      margin-right: 1rem;
      font-weight: 900;

    }

    .field__items {
      display: table-cell;
      font-size: 1.2rem;
    }

  }

  .event__date {
    margin-bottom: 1rem;
    &:before {
      content: '\f073';
    }
  }

  .event__location {
    &:before {
      content: '\f3c5';
    }
  }

  .event__contact {
    &:before {
      content: '\f007';
    }
  }

  .event__directions {
    margin-left: 2.1rem;
    font-size: 1.1rem;
  }

}

.sidebar-related {

  h2 {
    .sidebar-item-heading;
  }

  .field-related-content {
    margin-bottom: 2rem;
  }
}
