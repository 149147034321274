.region-footer-first,
.region-footer-second {
  .full-bleed();

  background: #f9f9f9;
  color: #2f2f2f;
  padding-top: 2rem;

  @media @md-and-up {
    padding-top: 4rem;
  }
}

.region-footer-first-inner {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 20px;
  max-width: 1200px;

  column-gap: @grid-gutter-width;
  row-gap: @grid-gutter-width;

  @media @md-and-up {
    grid-template-columns: 3fr 5fr 2fr 2fr;
    margin: 0 auto;
    padding: 0 20px;
  }

  &:before {
    display: none !important;
  }
}

.region-footer-second-inner {
  @media @md-and-up {
    padding: 0 20px;
  }
}



#section-footer #region-postscript-second {
  background: white;
  padding: 32px 0;

  @media @md-and-up {
    padding: 64px 0 0 0;
  }

  @media @lg-and-up {
    padding: 64px 0;
  }

  .region-inner {
    background: #94105e;
    margin-right: 20px;
    margin-left: 20px;
    max-width: 1104px;
    padding: 1.5rem 1rem;

    @media @md-and-up {
      margin-right: auto;
      margin-left: auto;
      padding: 3rem;
    }

    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media @md-and-up {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }

    .cta--copy {
      align-self: flex-start;



      @media @md-and-up {
        flex-grow: 1;
      }


      h2 {
        color: #fff;
        font-family: @lb;
        font-size: 32px;
        letter-spacing: 0.5px;
        line-height: 1.1;
        margin-top: 0;
        max-width: 32ch;
      }

      p {
        color: #fff;
        font-family: @pt;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        max-width: 64ch;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .cta--buttons {
      align-self: center;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      @media @md-and-up {
        align-self: unset;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0;
      }

      .btn {
        .btn-tertiary;

        margin-bottom: 1rem;
        padding: 10px 15px;
        padding: 16px 23px;

        @media @md-and-up {
          min-width: auto;
        }

        &:nth-child(2) {
          @media @md-and-up {
            margin-left: 1rem;
          }
        }
      }
    }

    .block-title {
      color: #fff;
      font-family: @lb;
      font-size: 32px;
      font-weight: 900;
      letter-spacing: 0.5px;
      line-height: 96px;
    }

    p {
      color: #fff;
      font-family: 'PT Sans';
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
}

#logo_footer {
  margin: 0 auto;

  text-align: center;

  @media @md-and-up {
    margin-top: -30px;
  }
  img {
    height: 200px;

    @media @md-and-up {
      height: 200px;
    }
  }
}

// Locations
#block-block-5 {
  margin-top: 1rem;

  @media @md-and-up {
    margin: 0;
    width: 100% !important;

    grid-row: span 2;
  }

  .block-title {
    .footer-section-heading;
  }

  .subheading {
    .footer-heading;

    margin-top: 0;
  }
}

// Quick Links
#block-menu-menu-footer-quick-links {
  margin-top: 1rem;

  @media @md-and-up {
    margin: 0;

    grid-row: span 2;
  }
  .block-title {
    .footer-section-heading;
  }

  ul {
    > li {
      &:first-child > a {
        padding-top: 0;
      }

      > a {
        .footer-menu-item;

        display: inline-block;
        padding: .5rem 0;
      }
    }
  }
}

// Join the Conversation / Social Links
#block-block-7 {
  margin-top: 1rem;

  @media @md-and-up {
    margin: 0;

    grid-row: span 2;
  }
  .block-title {
    .footer-section-heading;

    margin-top: 0;
  }

  #social-links {
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    padding-left: 0;

    > li {
      margin-right: .5rem;
      > a {
        align-items: center;
        border: 2px solid #e0275e;
        border-radius: 1000px;
        display: flex;
        height: 32px;
        justify-content: center;
        width: 32px;
        img {
          display: none;
        }
        &:before {
          -webkit-font-smoothing: antialiased;
          color: #94105e;
          display: inline-block;
          font-family: 'Font Awesome 5 Brands';
          font-size: 1rem;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
        }


        &.facebook-link {
          &:before {
            content: '\f39e';
          }
        }

        &.twitter-link {
          &:before {
            content: '\f099';
            padding: 5px 15px;
          }
        }

        &.google-plus-link {
          &:before {
            content: '\f16d';
          }
        }

        &:hover,
        &:focus {
          background-color: #e0275e;
          text-decoration: none;

          &:before {
            color: white;
          }
        }
      }
    }
  }
}


#maz-addys {
  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 1rem;

  .addy {
    color: #2f2f2f;
    font-family: @pt;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    width: 100% !important;
  }
}


#block-menu-menu-footer-menu {
  display: none;
}

#block-mazzoni-sf-newsletter-email {
  margin-top: 1rem;

  grid-row: 2;

  @media @md-and-up {
    margin-top: 0;
  }

  .block-title {
    .footer-section-heading;

    margin-bottom: 0;
  }

  form > div {
    .footer-heading;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: bold;
    margin-top: .5rem;
    margin-bottom: 0;

  }

  form > div div {
    margin-bottom: .5rem;
    width: 100%;
  }

  .form-item {
    margin-bottom: 0;
    max-width: 168px;
    width: 100%;


    @media @lg-and-up {
      max-width: 160px;
    }
    label {
      .element-invisible;
    }
  }

  .form-submit {
    .btn;
    .btn-primary;

    display: inline-block;
    font-size: 14px;
    line-height: 1;
    margin-left: 0;
    margin-left: .5rem;
    min-width: auto;
    padding: 8px 20px;
    margin-bottom: 0;
  }
}

#block-block-8 {
  border-top: 1px solid #979797;
  margin: 1rem 20px;
  max-width: 1200px;
  padding-top: 1rem;

  @media @md-and-up {
    margin: 1rem auto;
  }
  p {
    .disclaimer-text;
  }
}

#block-block-9 {
  .full-bleed();

  background-color: #94105e;
  color: white;


  .block-inner {
    display: flex;
    margin: 0 20px;
    max-width: 1200px;
    padding: 1.5rem 0;

    @media @md-and-up {
      margin-right: auto;
      margin-left: auto;
      padding: 1.5rem 20px;
    }

    p {
      .copyright-text;

      margin-bottom: 0;
      text-align: center;

      @media @md-and-up {
        text-align: left;
      }

      a {
        color: white;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}


#block-ma-ux-ma-jawn {
  display: none;
}


#directions-link {
  display: none;
}
