
// Chosen
// ================================
@inputpadding: 9px 10px 8px;
@inputheight: auto;

@iconsdir: '../images/';
@arrowdown: url("@{iconsdir}arrowdown.svg");
@small-x: url("@{iconsdir}small-x.svg");

.icon-base {
  background: none;
  font-weight: normal;
  line-height: 1;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 28px;
    height: 30px;
    text-indent: 0;
    text-align: center;
    background-image: none;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.icon-arrow-down {
  position: relative;
  .icon-base;
  &:before {
    background-image: @arrowdown;
  }
}

.icon-chevron-up {
  position: relative;
  .icon-base;
  &:before {
    background-image: @arrowdown;
  }
}



//* @group Base *//
.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100% !important;

  .chosen-drop {
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: 1010;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid;
    border-top: 0;

    box-shadow: 0px 0px 2px @divider;
    &:before {
      content: ' ';
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      height: 2px;
    }
  }
  &.chosen-with-drop .chosen-drop {
    left: 0;
  }

  a {
    cursor: pointer;
  }
}

//* @end .chosen-container *//

//* @group Single Chosen *//
.chosen-container-single {
  .chosen-single {
    position: relative;
    display: table;
    width: 100%;
    overflow: hidden;
    padding: @inputpadding;
    height: @inputheight;
    border: 1px solid;
    border-radius: 0;

    background-clip: padding-box;
    box-shadow: none;

    text-decoration: none;
    white-space: nowrap;
    &:extend(.select-option);

    span {
      display: table-cell;
      vertical-align: middle;
      overflow: hidden;
      margin-right: 26px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    abbr {
      position: absolute;
      top: 6px;
      right: 26px;
      display: block;
      width: 12px;
      height: 12px;
      //background: url('chosen-sprite.png') -42px 1px no-repeat;
      font-size: 1px;
      &:hover {
        background-position: -42px -10px;
      }
    }

    div {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 18px;
      height: 100%;

      b {
        display: block;
        width: 100%;
        height: 100%;
        background: transparent;

        .icon-arrow-down;
      }
    }
  }

  .activeSelection & .chosen-single {
    &:extend(.select-option);

    letter-spacing: 1px;
  }

  .chosen-single-with-deselect span {
    margin-right: 38px;
  }

  .chosen-default {
    color: #999;
  }

  &.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px;
  }

  .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    input[type="text"] {
      box-sizing: border-box;
      margin: 1px 0;
      padding: @inputpadding !important;
      width: 100%;
      height: auto;
      outline: 0;
      border: none;

      &:extend(.select-option);
      border-radius: 0;
    }
  }

  .chosen-drop {
    margin-top: -1px;
    border-radius: 0;
    background-clip: padding-box;
  }

  &.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px;
  }
}

//* @end .chosen-single *//


//* .chosen-container-multi *//
.chosen-container-multi {
  .chosen-choices {
    margin: 0 !important;
    border: 1px solid transparent;
    background-image: none;

    li.search-field {
      margin: 0;
      float: none;
      display: inline-block;
      vertical-align: middle;

      input[type="text"] {
        padding: @inputpadding !important;
      }
    }

    li.search-choice {
      margin: 4px;
      float: none;
      display: inline-block;
      vertical-align: middle;
      background-image: none;
      border: none;
      background-color: @p2;
      color: @white;
      font-weight: bold;
      font-family: @pt;
      padding: @inputpadding;
      padding-right: 30px !important;
      position: relative;
      overflow: hidden;

      a {
        position: absolute !important;
        top: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        left: auto !important;
        width: 20px !important;
        height: auto !important;
        box-sizing: border-box;
        background: none !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-image: @small-x !important;
        border-left: 1px solid fade(@p1,50%);

        &:hover {
          background-color: @p1 !important;
        }
      }
    }
  }
  .chosen-drop {
    border: 1px solid @g2;
    border-top: none;
    color: @g1;
    font-weight: bold;

    .chosen-results { margin: 0 !important; }
    .chosen-results > li {
      position: relative;
      padding-left: 30px;
      padding-top: 12px;

      &:before {
        content: ' ';
        display: inline-block;
        width: 12px;
        height: 12px;
        border: 1px solid @divider;
        border-radius: 0;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }

      &.highlighted:before {
        border-color: @white;
      }
    }
    .result-selected {
      color: @g1;
      background: @g4;
      opacity: .35;

      &:before {
        background-image: @small-x;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 8px;
      }
    }
  }
}
//* @end .chosen-container-multi *//


/* @group Results */
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;

  li {
    display: none;
    margin: 0;
    padding: @inputpadding;
    &:extend(.select-option);
    -webkit-touch-callout: none;

    &.highlighted {
      color: @white;
      background-color: @orange;
    }

    &.active-result {
      display: list-item;
      cursor: pointer;
    }
    &.disabled-result {
      display: list-item;

      cursor: default;
    }
    &.highlighted {
      background-image: none;

    }
    &.no-results {
      display: list-item;

    }
    &.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default;
    }
    &.group-option {
      padding-left: 15px;
    }
    em {
      font-style: normal;
      text-decoration: underline;
    }
  }
}

//* @group Active  *//
.chosen-container-active {
  .chosen-single {
    border: 1px solid @p4;
    box-shadow: 0px 0px 2px @divider;
  }

  &.chosen-with-drop .chosen-single {
    border: 1px solid @p4;
    border-radius: 0;
    background-image: none;
    box-shadow: 0 2px 2px @divider;
    overflow: visible;
    &:after {
      content: ' ';
      position: absolute;
      bottom: -3px;
      left: -2px;
      right: -2px;
      height: 3px;

    }

    div {
      border-left: none;
      background: transparent;

      b {

        .icon-chevron-up;
      }
    }
  }

  .chosen-choices {
    border: 1px solid @g2;
    box-shadow: none;

    li.search-field input[type="text"] {
      color: @g2;
      padding: @inputpadding !important;
      box-sizing: border-box;
    }
  }
}

//* @end .chosen-container-active *//

/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

/* @end */
