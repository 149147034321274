// Navigation
#section-header #zone-menu-wrapper {
  background-color: #fff !important;
  border: 1px solid #e5e5e5  !important;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.1)  !important;
  position: relative  !important;
  z-index: 40  !important;
}

#block-megamenu-main-menu {
  #megamenu-main-menu {
    background: transparent;
    position: relative;
    text-align: center;

    > li {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      position: static;

      > h2 {
        .pt;
        .shadow(2px,2px,4px,rgba(0,0,0,.25));

        display: inline-block;
        font-size: 14.5px;
        letter-spacing: .5px;
        text-transform: uppercase;

        > a {
          .trans;

          color: @white;
          cursor: pointer;
          display: block;
          font-weight: bold;
          height: 50px;
          padding: 0 30px;

          &:hover {
            background-color: rgba(87,10,60,.7);
            text-decoration: none;
          }
        }
      }
    }

    .megamenu-bin {
      .tile;
      .sizing;
      .r(0);

      border: none !important;
      margin-top: -1px;
      max-width: 1200px !important;
      outline: none;
      width: 100% !important;

      > li {
        background: transparent;
        display: inline-block;
        height: auto;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        text-align: left;
        vertical-align: top;
        width: 180px;

        h3 {
          background: transparent;
        }

        a {
          background: transparent;
          color: @white;
        }

        ul {
          border: none;
          border-left: 1px solid rgba(255,255,255,.25);
        }
      }
    }
  }
}





// Main Menu





.top-level-nav .menu-name-main-menu > .menu {
  > li {
    border-top: 2px solid transparent;
    > a {
    }

    &:hover,
    &:focus {
      border-top-color: #94105e;
      .menu {
      }
    }
  }
  > .menu-title-about-us {
    background: #f7f7f7;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;

    &:hover,
    &:focus {
      border-top-color: #ee482e;
    }
    > a {
      color: #ee482e !important;
    }
  }
}
