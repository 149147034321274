.node-type-landing-page.not-front .block-site-alert {
  left: 50%;
  order: 1;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  order: 1;
}

.block-site-alert {
  position: relative;
}

div.site-alert {
  .full-bleed();

  font-weight: normal;

  div.low,
  div.high,
  div.info {
    background-image: none;
    border: 0;
  }

  div.high {
    background-color: #e0275e;

    div.text {
      color: @white;

      a {
        color: @white;
        text-decoration: underline;
      }
    }
  }

  div.low {
    background-color: #eaeaea;

    div.text {
      color: #2f2f2f;

      a {
        color: #2f2f2f;
        text-decoration: underline;
      }
    }
  }

  div.text {
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px 30px;
    text-align: center;

    p:last-child {
      margin-bottom: 0;
    }
  }
}
