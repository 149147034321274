h1 {
  .page-title;
}

h2 {
  .primary-heading;
}

h3 {
  .secondary-heading;
}

h4 {
  .tertiary-heading;
}

h5 {
  .quaternary-heading;
}

h6 {
  .quinary-heading;
}
