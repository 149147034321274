.node-recurring-event.view-mode-featured {
  border-top: 1px solid #979797;
  padding: 20px 0;

  .field-title h2 a {
    .posting-heading;
  }

  .field-event-date-time-info {
    .mustache-title;
  }

  .field-body {
    .card-overview-text;
  }
}

.node-recurring-event,
.node-special-event {
  &.view-mode-teaser {
    border-bottom: 1px solid #979797;
    display: grid;
    grid-template-columns: 1fr;
    padding: 2rem 0;

    column-gap: @grid-gutter-width;

    &.has-media {
      grid-template-columns: 3fr 5fr;
    }

    .group-left {
    }

    .group-right {
      .field-activity-type {
        .taxonomy-term-text;

        margin-top: 0;
      }

      h3 {
        margin-top: 0;
      }

      h3 > a {
        .card-item-heading;
      }

      .field-event-date-time-info {
        .card-mustache;
      }
    }
  }
}

.node-recurring-event,
.node-special-event {
  &.view-mode-full {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 4rem;

    column-gap: @grid-gutter-width;


    .group-header {
      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 8;
      }
    }

    .group-left {
      display: flex;
      flex-direction: column;

      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 8;
      }


      .field-signature-image,
      .field-signature-media {
        margin-bottom: 2rem;
      }

      .field-body {
        font-size: 17px;
        @media @lg-and-up {
          padding: 0 72px;
        }
      }

      .assets-container {
        @media @lg-and-up {
          padding: 0 72px;
        }
        .field-label {
          .assets-item-heading;
        }

        .field-files,
        .field-links {
          a {
            .assets-item;
          }
        }
      }
    }

    .group-right {
      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 4;
      }
    }

    .group-footer {
      grid-column: span 12;
    }
  }
}

.node-type-recurring-event,
.node-type-special-event {
  .title-wrap {
    display: none;
  }
}

.view-display-id-activies_related .view-content .views-row {
  margin-bottom: 2rem;
}

.view-display-id-events_listing {

  .view-content {
    margin-top: 34px !important;
  }
  .group-header {

    h3 {
      margin-top: 2.5rem;
      border-bottom: 1px solid #979797;
      padding-bottom: 2.5rem;
      margin-bottom: 0;
    }

    &:first-child {
      h3 {
        margin-top: 0;
      }
    }
  }

  .view-content {
    margin-top: 3rem;
  }
}
