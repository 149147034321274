:focus {
  outline-color: @brand-primary;
}

button,
input[type="reset"],
input[type="submit"],
input[type="button"],
input.button,
a.button,
div.button > a,
span.button > a {

  .button;

}

input[type="submit"][disabled] {
  .role-Committee-member.page-node-edit.node-type-workshop & {
    display: none;
  }
}

.form-submit,
.checkout-buttons .checkout-cancel,
.checkout-buttons .checkout-back {

  .button;
  border:none;
  cursor:pointer;

}

// hide character count on textarea input:
.form-item .counter { display: none; visibility: hidden; }

@inputpadding: 9px 10px 8px;
@inputheight: auto;

textarea,
select,
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
select {
  &::-ms-expand {
    display: none;
  }
}
button,
input {
  &::-moz-focus-inner{
    border: 0;
    padding: 0;
  }
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  background: @white;
  border: 1px solid @g3;
  border-radius: 0;
  padding: @inputpadding;
  .sizing;
  height: @inputheight;
  min-height: 40px;
  width: 100%;
  font-size: 16px;
  .pt;

  &.error {
    color: @error;
    outline: 2px solid @error;
    .trans;

    &:focus {
      outline-color: fade(@error, 15%);
      color: @g0;
    }
  }
}

textarea,
select { height: auto; }

select {
  box-shadow: none;
  background-image: url('/sites/all/themes/custom/images/arrowdown.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 20px;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  width: 15px;
  border: 1px solid @g2;
  background-color: @white;
  display: inline-block;
  height: 15px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 4px;
  border-radius: 0;

  &:checked {
    outline: none;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:focus,
  &:active {
    border-color: @p4;
  }
}

label {
  text-align: left;
  display: block;
  margin-bottom: 6px;
  position: relative;


  .form-required {
    float: left;
    margin-right: 2px;
    .resp(@mobilemax, {
      float: none;
    });
  }

}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  vertical-align: top;
  margin-right: -30px;

  ~ label {
    font-weight: normal;
    display: inline-block;
    vertical-align: top;
    padding-left: 34px;
    margin-top: 0px;
  }
}


input[type="checkbox"] {
  &:checked {
    background-image: url(/sites/all/themes/custom/images/small-x.svg);
  }
}
input[type="radio"] {
  border-radius: 100%;
  &:checked {
    background-image: url(/sites/all/themes/custom/images/radio-dot.svg);
  }
}

.__inputs-no-border {
  textarea,
  select,
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  input[type="checkbox"],
  input[type="radio"] {
    border-color: @white;
    box-shadow: 0 6px 12px -5px rgba(0,0,0,.05);
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  }
}

.__inputs-has-border {
  textarea,
  select,
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  input[type="checkbox"],
  input[type="radio"] {
    border-color: @g3;
    box-shadow: none;
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-shadow: none;
  }
}

textarea {
  &.thoroughfare {
    min-height: 140px;
  }
}
.grippie {
  display: none;
}

.url-textfield {
  display: none !important;
}
