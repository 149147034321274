// Custom styles for the CTA component.
//
// Override variables in 00-base/_variables.scss before customizing here.
//
// -----------------------------------------------------------------------------


.cta--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0;

  @media @md-and-up {
    margin: 64px 0;
  }

  @media @md-and-up {
    flex-direction: row;
  }

  .field-pg-cta {
    padding: 0 8px;
    text-align: center;
    a {
      .btn-primary;
    }

    &:last-child {
      margin-top: 1rem;

      @media @md-and-up {
        margin-top: 0;
      }
      a {
        .btn-secondary;
      }
    }

    &:only-child {
      a {
        .btn-primary;
      }
    }
  }
}
