/* --- [ Colors ] --------------- */
/** Transparent **/
/** Black **/
/** White **/
/** Gray **/
/** Red **/
/** Orange **/
/** Yellow **/
/** Green **/
/** Teal **/
/** Blue **/
/** Indigo **/
/** Purple **/
/** Pink **/
/* --- [ Reusable Styles ] --------------- */
.cf:before,
.cf:after {
  content: " ";
  display: table;
  clear: both;
}
.abs-0 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.unbullet {
  margin: 0;
  padding: 0;
  list-style: none;
}
.noshadow {
  text-shadow: 0 0 0 transparent;
}
.noboxshadow {
  -webkit-box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
}
.grad {
  background-image: linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.3)), color-stop(1, rgba(255, 255, 255, 0)));
}
.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.element-visible {
  position: static !important;
  clip: auto;
  overflow: hidden;
  height: auto;
}
.button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  float: none;
  background-image: none !important;
  background: #fff;
  padding: 10px 30px;
  color: #e0275e !important;
  border: 2px solid #e0275e;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  text-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  font-family: 'PT Sans', sans-serif;
}
.button:hover {
  border-color: #890f5e !important;
  color: #890f5e !important;
  text-decoration: none;
}
.button:focus {
  transform: translateY(2px);
  -webkit-transform: translateY(2px);
}
.button.disabled,
.button.cancelled {
  background: transparent !important;
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  pointer-events: none;
}
.button.disabled:hover,
.button.cancelled:hover,
.button.disabled:focus,
.button.cancelled:focus {
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  transform: none;
}
.full-bleed {
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  position: relative !important;
  right: 50% !important;
  width: 100vw !important;
}
.sidebar-wrapper {
  border: 1px solid #979797;
  border-top: 4px solid #ee482e;
  padding: 25px 40px;
  margin-bottom: 2rem;
}
.js-breaks__mobilemax {
  width: 480px;
}
.js-breaks__tabletmax {
  width: 768px;
}
.js-breaks__widemin {
  width: 1220px;
}
/* --- [ Typography ] --------------- */
/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans Italic'), local('PTSans-Italic'), url(https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0e0ysmIAjcQ-woy.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans Italic'), local('PTSans-Italic'), url(https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0e0w8mIAjcQ-woy.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans Italic'), local('PTSans-Italic'), url(https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0e0ycmIAjcQ-woy.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans Italic'), local('PTSans-Italic'), url(https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0e0x8mIAjcQ-w.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'), url(https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOydIhUd0TA7i2bI.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'), url(https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOydKxUd0TA7i2bI.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'), url(https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOydIRUd0TA7i2bI.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'), url(https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOydLxUd0TA7iw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans'), local('PTSans-Regular'), url(https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0-ExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans'), local('PTSans-Regular'), url(https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0aExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans'), local('PTSans-Regular'), url(https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0yExcOPIDUg-g.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Sans'), local('PTSans-Regular'), url(https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79D0KExcOPIDU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold'), local('PTSans-Bold'), url(https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0OOtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold'), local('PTSans-Bold'), url(https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0OqtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold'), local('PTSans-Bold'), url(https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0OCtLR8a8zILig.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Sans Bold'), local('PTSans-Bold'), url(https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh0O6tLR8a8zI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lovelo Black';
  src: url('/sites/all/themes/custom/type/lovelo-black.eot');
  src: url('/sites/all/themes/custom/type/lovelo-black.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/type/lovelo-black.woff2') format('woff2'), url('/sites/all/themes/custom/type/lovelo-black.woff') format('woff'), url('/sites/all/themes/custom/type/lovelo-black.ttf') format('truetype'), url('/sites/all/themes/custom/type/lovelo-black.svg#loveloblack') format('svg');
  font-weight: normal;
  font-style: normal;
}
.pt {
  font-family: 'PT Sans', sans-serif;
}
.lb {
  font-family: 'Lovelo Black', sans-serif;
}
html {
  /* To make use of full height of page*/
  min-height: 100%;
  margin: 0;
}
body {
  min-height: 100%;
  margin: 0;
}
.container-12 {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  width: 100%;
  column-gap: 27px;
}
.container-12 .grid-1,
.container-12 .grid-2,
.container-12 .grid-3,
.container-12 .grid-4,
.container-12 .grid-5,
.container-12 .grid-6,
.container-12 .grid-7,
.container-12 .grid-8,
.container-12 .grid-9,
.container-12 .grid-10,
.container-12 .grid-11,
.container-12 .grid-12 {
  box-sizing: border-box;
}
.container-12 .grid-12 {
  margin: 0;
  width: 100%;
  grid-column: span 12;
}
.container-12 .grid-3 {
  margin: 0;
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .container-12 .grid-3 {
    grid-column: span 3;
  }
}
.container-12 .grid-9 {
  margin: 0;
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .container-12 .grid-9 {
    grid-column: span 9;
  }
}
.page-title {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 992px) {
  .page-title {
    font-size: 42px;
    letter-spacing: 0.14px;
  }
}
.page-title-alt {
  color: #ed5025;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.17px;
  line-height: 1.1;
}
@media only screen and (min-width: 992px) {
  .page-title-alt {
    font-size: 50px;
    letter-spacing: 0.17px;
    line-height: 1.1;
  }
}
.primary-heading {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;
}
@md-and-up {
  font-size: 32px;
}
.secondary-heading {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;
}
@md-and-up {
  font-size: 24px;
}
.tertiary-heading {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;
}
@md-and-up {
  font-size: 20px;
}
.blockquote-copy {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 29px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 42px;
}
.mustache-post-date {
  color: #e0275e;
  font-family: 'PT Sans', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.mustache-title {
  color: #e0275e;
  font-family: 'PT Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 24px;
}
.eyebrow-term {
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}
.assets-item-heading {
  border-bottom: 1px solid #b2b2b2;
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.68px;
  line-height: 24px;
  padding-bottom: 0.5rem;
}
.assets-item {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 22px;
}
.assets-item:hover,
.assets-item:focus {
  border-bottom: 1px solid #e0275e;
  text-decoration: none;
}
.sidebar-item-heading {
  color: #ee482e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
}
.tag-filter {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.75px;
  line-height: 28px;
  text-transform: uppercase;
}
.card-item-heading {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.card-eyebrow {
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 24px;
  text-transform: uppercase;
}
.card-mustache {
  color: #e0275e;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.card-overview-text {
  color: #000;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}
.card-heading {
  color: #94105e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.signpost-heading {
  color: #94105e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0;
  text-align: center;
}
.section-heading {
  color: #ee482e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 36px;
  letter-spacing: 0.56px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  .section-heading {
    font-size: 48px;
    letter-spacing: 0.75px;
    margin-bottom: 3rem;
  }
}
.posting-heading {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.footer-section-heading {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
.footer-heading {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1rem;
}
.footer-menu-item {
  color: #e0275e;
  font-family: 'PT Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.disclaimer-text {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.6px;
  line-height: 26px;
}
.copyright-text {
  color: #fff;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 22px;
}
@media only screen and (min-width: 992px) {
  .copyright-text {
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 20px;
  }
}
.taxonomy-term-text {
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-transform: uppercase;
}
.story-heading {
  color: #94105e;
  font-family: 'PT Sans';
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 58px;
}
@media only screen and (min-width: 992px) {
  .story-heading {
    font-size: 40px;
    letter-spacing: 0;
    line-height: 58px;
  }
}
.story-text {
  color: #2f2f2f;
  font-family: 'PT Sans';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}
@media only screen and (min-width: 992px) {
  .story-text {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 38px;
  }
}
.btn {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn-primary {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #e0275e;
  border-color: #e0275e;
  color: #fff !important;
}
.btn-primary:hover,
.btn-primary:focus {
  text-decoration: none;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #94105e;
  border-color: #94105e;
}
.btn-secondary {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  border-color: #e0275e;
  color: #94105e !important;
}
.btn-secondary:hover,
.btn-secondary:focus {
  text-decoration: none;
}
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #e0275e;
  border-color: #e0275e;
  color: #fff !important;
}
.btn-tertiary {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #380627;
  border-color: #380627;
  color: #fff !important;
}
.btn-tertiary:hover,
.btn-tertiary:focus {
  text-decoration: none;
}
.btn-tertiary:hover,
.btn-tertiary:focus {
  background-color: #fff;
  border-color: #380627;
  color: #380627 !important;
}
:focus {
  outline-color: #890f5e;
}
button,
input[type="reset"],
input[type="submit"],
input[type="button"],
input.button,
a.button,
div.button > a,
span.button > a {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  float: none;
  background-image: none !important;
  background: #fff;
  padding: 10px 30px;
  color: #e0275e !important;
  border: 2px solid #e0275e;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  text-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  font-family: 'PT Sans', sans-serif;
}
button:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
input.button:hover,
a.button:hover,
div.button > a:hover,
span.button > a:hover {
  border-color: #890f5e !important;
  color: #890f5e !important;
  text-decoration: none;
}
button:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
input[type="button"]:focus,
input.button:focus,
a.button:focus,
div.button > a:focus,
span.button > a:focus {
  transform: translateY(2px);
  -webkit-transform: translateY(2px);
}
button.disabled,
input[type="reset"].disabled,
input[type="submit"].disabled,
input[type="button"].disabled,
input.button.disabled,
a.button.disabled,
div.button > a.disabled,
span.button > a.disabled,
button.cancelled,
input[type="reset"].cancelled,
input[type="submit"].cancelled,
input[type="button"].cancelled,
input.button.cancelled,
a.button.cancelled,
div.button > a.cancelled,
span.button > a.cancelled {
  background: transparent !important;
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  pointer-events: none;
}
button.disabled:hover,
input[type="reset"].disabled:hover,
input[type="submit"].disabled:hover,
input[type="button"].disabled:hover,
input.button.disabled:hover,
a.button.disabled:hover,
div.button > a.disabled:hover,
span.button > a.disabled:hover,
button.cancelled:hover,
input[type="reset"].cancelled:hover,
input[type="submit"].cancelled:hover,
input[type="button"].cancelled:hover,
input.button.cancelled:hover,
a.button.cancelled:hover,
div.button > a.cancelled:hover,
span.button > a.cancelled:hover,
button.disabled:focus,
input[type="reset"].disabled:focus,
input[type="submit"].disabled:focus,
input[type="button"].disabled:focus,
input.button.disabled:focus,
a.button.disabled:focus,
div.button > a.disabled:focus,
span.button > a.disabled:focus,
button.cancelled:focus,
input[type="reset"].cancelled:focus,
input[type="submit"].cancelled:focus,
input[type="button"].cancelled:focus,
input.button.cancelled:focus,
a.button.cancelled:focus,
div.button > a.cancelled:focus,
span.button > a.cancelled:focus {
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  transform: none;
}
.role-Committee-member.page-node-edit.node-type-workshop input[type="submit"][disabled] {
  display: none;
}
.form-submit,
.checkout-buttons .checkout-cancel,
.checkout-buttons .checkout-back {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  float: none;
  background-image: none !important;
  background: #fff;
  padding: 10px 30px;
  color: #e0275e !important;
  border: 2px solid #e0275e;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  text-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  font-family: 'PT Sans', sans-serif;
  border: none;
  cursor: pointer;
}
.form-submit:hover,
.checkout-buttons .checkout-cancel:hover,
.checkout-buttons .checkout-back:hover {
  border-color: #890f5e !important;
  color: #890f5e !important;
  text-decoration: none;
}
.form-submit:focus,
.checkout-buttons .checkout-cancel:focus,
.checkout-buttons .checkout-back:focus {
  transform: translateY(2px);
  -webkit-transform: translateY(2px);
}
.form-submit.disabled,
.checkout-buttons .checkout-cancel.disabled,
.checkout-buttons .checkout-back.disabled,
.form-submit.cancelled,
.checkout-buttons .checkout-cancel.cancelled,
.checkout-buttons .checkout-back.cancelled {
  background: transparent !important;
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  pointer-events: none;
}
.form-submit.disabled:hover,
.checkout-buttons .checkout-cancel.disabled:hover,
.checkout-buttons .checkout-back.disabled:hover,
.form-submit.cancelled:hover,
.checkout-buttons .checkout-cancel.cancelled:hover,
.checkout-buttons .checkout-back.cancelled:hover,
.form-submit.disabled:focus,
.checkout-buttons .checkout-cancel.disabled:focus,
.checkout-buttons .checkout-back.disabled:focus,
.form-submit.cancelled:focus,
.checkout-buttons .checkout-cancel.cancelled:focus,
.checkout-buttons .checkout-back.cancelled:focus {
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  transform: none;
}
.form-item .counter {
  display: none;
  visibility: hidden;
}
textarea,
select,
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  padding: 9px 10px 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: auto;
  min-height: 40px;
  width: 100%;
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
}
textarea.error,
select.error,
input[type="date"].error,
input[type="datetime"].error,
input[type="datetime-local"].error,
input[type="email"].error,
input[type="month"].error,
input[type="number"].error,
input[type="password"].error,
input[type="search"].error,
input[type="tel"].error,
input[type="text"].error,
input[type="time"].error,
input[type="url"].error,
input[type="week"].error {
  color: red;
  outline: 2px solid red;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
textarea.error:focus,
select.error:focus,
input[type="date"].error:focus,
input[type="datetime"].error:focus,
input[type="datetime-local"].error:focus,
input[type="email"].error:focus,
input[type="month"].error:focus,
input[type="number"].error:focus,
input[type="password"].error:focus,
input[type="search"].error:focus,
input[type="tel"].error:focus,
input[type="text"].error:focus,
input[type="time"].error:focus,
input[type="url"].error:focus,
input[type="week"].error:focus {
  outline-color: rgba(255, 0, 0, 0.15);
  color: #444;
}
textarea,
select {
  height: auto;
}
select {
  box-shadow: none;
  background-image: url('/sites/all/themes/custom/images/arrowdown.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 20px;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  width: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: inline-block;
  height: 15px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 4px;
  border-radius: 0;
}
input[type="checkbox"]:checked,
input[type="radio"]:checked {
  outline: none;
  background-repeat: no-repeat;
  background-position: center;
}
input[type="checkbox"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:active,
input[type="radio"]:active {
  border-color: #57354b;
}
label {
  text-align: left;
  display: block;
  margin-bottom: 6px;
  position: relative;
}
label .form-required {
  float: left;
  margin-right: 2px;
}
@media only screen and (max-width: 480px) {
  label .form-required {
    float: none;
  }
}
input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  vertical-align: top;
  margin-right: -30px;
}
input[type="checkbox"] ~ label,
input[type="radio"] ~ label {
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  padding-left: 34px;
  margin-top: 0px;
}
input[type="checkbox"]:checked {
  background-image: url(/sites/all/themes/custom/images/small-x.svg);
}
input[type="radio"] {
  border-radius: 100%;
}
input[type="radio"]:checked {
  background-image: url(/sites/all/themes/custom/images/radio-dot.svg);
}
.__inputs-no-border textarea,
.__inputs-no-border select,
.__inputs-no-border input[type="date"],
.__inputs-no-border input[type="datetime"],
.__inputs-no-border input[type="datetime-local"],
.__inputs-no-border input[type="email"],
.__inputs-no-border input[type="month"],
.__inputs-no-border input[type="number"],
.__inputs-no-border input[type="password"],
.__inputs-no-border input[type="search"],
.__inputs-no-border input[type="tel"],
.__inputs-no-border input[type="text"],
.__inputs-no-border input[type="time"],
.__inputs-no-border input[type="url"],
.__inputs-no-border input[type="week"],
.__inputs-no-border input[type="checkbox"],
.__inputs-no-border input[type="radio"] {
  border-color: #fff;
  box-shadow: 0 6px 12px -5px rgba(0, 0, 0, 0.05);
}
.__inputs-no-border input[type="checkbox"],
.__inputs-no-border input[type="radio"] {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
}
.__inputs-has-border textarea,
.__inputs-has-border select,
.__inputs-has-border input[type="date"],
.__inputs-has-border input[type="datetime"],
.__inputs-has-border input[type="datetime-local"],
.__inputs-has-border input[type="email"],
.__inputs-has-border input[type="month"],
.__inputs-has-border input[type="number"],
.__inputs-has-border input[type="password"],
.__inputs-has-border input[type="search"],
.__inputs-has-border input[type="tel"],
.__inputs-has-border input[type="text"],
.__inputs-has-border input[type="time"],
.__inputs-has-border input[type="url"],
.__inputs-has-border input[type="week"],
.__inputs-has-border input[type="checkbox"],
.__inputs-has-border input[type="radio"] {
  border-color: #e5e5e5;
  box-shadow: none;
}
.__inputs-has-border input[type="checkbox"],
.__inputs-has-border input[type="radio"] {
  box-shadow: none;
}
textarea.thoroughfare {
  min-height: 140px;
}
.grippie {
  display: none;
}
.url-textfield {
  display: none !important;
}
.icon-base {
  background: none;
  font-weight: normal;
  line-height: 1;
  position: relative;
}
.icon-base:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 28px;
  height: 30px;
  text-indent: 0;
  text-align: center;
  background-image: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}
.icon-arrow-down {
  background: none;
  font-weight: normal;
  line-height: 1;
  position: relative;
}
.icon-arrow-down:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 28px;
  height: 30px;
  text-indent: 0;
  text-align: center;
  background-image: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}
.icon-arrow-down:before {
  background-image: url("./01-atoms/images/arrowdown.svg");
}
.icon-chevron-up {
  background: none;
  font-weight: normal;
  line-height: 1;
  position: relative;
}
.icon-chevron-up:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 28px;
  height: 30px;
  text-indent: 0;
  text-align: center;
  background-image: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}
.icon-chevron-up:before {
  background-image: url("./01-atoms/images/arrowdown.svg");
}
.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  *display: inline;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100% !important;
}
.chosen-container .chosen-drop {
  position: absolute;
  top: 100%;
  left: -9999px;
  z-index: 1010;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid;
  border-top: 0;
  box-shadow: 0px 0px 2px #E6E6E6;
}
.chosen-container .chosen-drop:before {
  content: ' ';
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  height: 2px;
}
.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
}
.chosen-container a {
  cursor: pointer;
}
.chosen-container-single .chosen-single {
  position: relative;
  display: table;
  width: 100%;
  overflow: hidden;
  padding: 9px 10px 8px;
  height: auto;
  border: 1px solid;
  border-radius: 0;
  background-clip: padding-box;
  box-shadow: none;
  text-decoration: none;
  white-space: nowrap;
}
.chosen-container-single .chosen-single span {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  font-size: 1px;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 100%;
}
.chosen-container-single .chosen-single div b {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  background: none;
  font-weight: normal;
  line-height: 1;
  position: relative;
}
.chosen-container-single .chosen-single div b:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 28px;
  height: 30px;
  text-indent: 0;
  text-align: center;
  background-image: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}
.chosen-container-single .chosen-single div b:before {
  background-image: url("./01-atoms/images/arrowdown.svg");
}
.activeSelection .chosen-container-single .chosen-single {
  letter-spacing: 1px;
}
.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}
.chosen-container-single .chosen-default {
  color: #999;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}
.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.chosen-container-single .chosen-search input[type="text"] {
  box-sizing: border-box;
  margin: 1px 0;
  padding: 9px 10px 8px !important;
  width: 100%;
  height: auto;
  outline: 0;
  border: none;
  border-radius: 0;
}
.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-radius: 0;
  background-clip: padding-box;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  left: -9999px;
}
.chosen-container-multi .chosen-choices {
  margin: 0 !important;
  border: 1px solid transparent;
  background-image: none;
}
.chosen-container-multi .chosen-choices li.search-field {
  margin: 0;
  float: none;
  display: inline-block;
  vertical-align: middle;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  padding: 9px 10px 8px !important;
}
.chosen-container-multi .chosen-choices li.search-choice {
  margin: 4px;
  float: none;
  display: inline-block;
  vertical-align: middle;
  background-image: none;
  border: none;
  background-color: #890f5e;
  color: #fff;
  font-weight: bold;
  font-family: 'PT Sans', sans-serif;
  padding: 9px 10px 8px;
  padding-right: 30px !important;
  position: relative;
  overflow: hidden;
}
.chosen-container-multi .chosen-choices li.search-choice a {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: auto !important;
  width: 20px !important;
  height: auto !important;
  box-sizing: border-box;
  background: none !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-image: url("./01-atoms/images/small-x.svg") !important;
  border-left: 1px solid rgba(87, 10, 60, 0.5);
}
.chosen-container-multi .chosen-choices li.search-choice a:hover {
  background-color: #570a3c !important;
}
.chosen-container-multi .chosen-drop {
  border: 1px solid #ccc;
  border-top: none;
  color: #666;
  font-weight: bold;
}
.chosen-container-multi .chosen-drop .chosen-results {
  margin: 0 !important;
}
.chosen-container-multi .chosen-drop .chosen-results > li {
  position: relative;
  padding-left: 30px;
  padding-top: 12px;
}
.chosen-container-multi .chosen-drop .chosen-results > li:before {
  content: ' ';
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #E6E6E6;
  border-radius: 0;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.chosen-container-multi .chosen-drop .chosen-results > li.highlighted:before {
  border-color: #fff;
}
.chosen-container-multi .chosen-drop .result-selected {
  color: #666;
  background: #f2f2f2;
  opacity: 0.35;
}
.chosen-container-multi .chosen-drop .result-selected:before {
  background-image: url("./01-atoms/images/small-x.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px;
}
/* @group Results */
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
  display: none;
  margin: 0;
  padding: 9px 10px 8px;
  -webkit-touch-callout: none;
}
.chosen-container .chosen-results li.highlighted {
  color: #fff;
  background-color: #ee482e;
}
.chosen-container .chosen-results li.active-result {
  display: list-item;
  cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  cursor: default;
}
.chosen-container .chosen-results li.highlighted {
  background-image: none;
}
.chosen-container .chosen-results li.no-results {
  display: list-item;
}
.chosen-container .chosen-results li.group-result {
  display: list-item;
  font-weight: bold;
  cursor: default;
}
.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}
.chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}
.chosen-container-active .chosen-single {
  border: 1px solid #57354b;
  box-shadow: 0px 0px 2px #E6E6E6;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  border: 1px solid #57354b;
  border-radius: 0;
  background-image: none;
  box-shadow: 0 2px 2px #E6E6E6;
  overflow: visible;
}
.chosen-container-active.chosen-with-drop .chosen-single:after {
  content: ' ';
  position: absolute;
  bottom: -3px;
  left: -2px;
  right: -2px;
  height: 3px;
}
.chosen-container-active.chosen-with-drop .chosen-single div {
  border-left: none;
  background: transparent;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background: none;
  font-weight: normal;
  line-height: 1;
  position: relative;
}
.chosen-container-active.chosen-with-drop .chosen-single div b:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 28px;
  height: 30px;
  text-indent: 0;
  text-align: center;
  background-image: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
}
.chosen-container-active.chosen-with-drop .chosen-single div b:before {
  background-image: url("./01-atoms/images/arrowdown.svg");
}
.chosen-container-active .chosen-choices {
  border: 1px solid #ccc;
  box-shadow: none;
}
.chosen-container-active .chosen-choices li.search-field input[type="text"] {
  color: #ccc;
  padding: 9px 10px 8px !important;
  box-sizing: border-box;
}
/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}
/* @end */
img {
  vertical-align: bottom;
}
img,
input {
  max-width: 100%;
}
.field-signature-image img {
  width: 100%;
  height: auto;
}
.field-trans-image img {
  width: 100%;
}
a:link,
a:visited,
a:active {
  color: #e0275e;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.pager {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 !important;
  margin-top: 2rem !important;
  padding: 0 !important;
  position: relative;
}
.pager > li {
  align-items: center;
  border: 2px solid #ee482e;
  display: flex;
  font-size: 18px;
  font-size: 15px;
  font-weight: bold;
  height: 42px;
  justify-content: center;
  line-height: 1.5;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0 !important;
  text-align: center;
  width: 42px;
}
.pager > li a,
.pager > li span {
  font-size: 15px;
  font-size: 0.9375rem;
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  line-height: 1;
  width: 100%;
}
.pager > li:last-child {
  margin-right: 0;
}
.pager > li:hover,
.pager > li:focus {
  background: #94105e;
  border-color: #94105e;
  color: #fff;
}
.pager > li:hover a,
.pager > li:focus a {
  color: #fff;
  text-decoration: none;
}
.pager > li.pager-current {
  background: #94105e;
  border-color: #94105e;
  color: #fff;
}
.pager > li.pager-first {
  border: 0;
  height: 48px;
  margin-right: 0;
  width: 48px;
}
.pager > li.pager-first a,
.pager > li.pager-first span {
  -webkit-font-smoothing: antialiased;
  color: #ee482e;
  font-family: 'Font Awesome 5 Free';
  font-size: 0;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  white-space: nowrap;
}
.pager > li.pager-first a:before,
.pager > li.pager-first span:before {
  content: '\f100';
  font-size: 1.4rem;
}
.pager > li.pager-first a:hover,
.pager > li.pager-first span:hover,
.pager > li.pager-first a:focus,
.pager > li.pager-first span:focus {
  background: #fff;
  color: #ee482e;
}
.pager > li.pager-previous {
  border: 0;
}
.pager > li.pager-previous a,
.pager > li.pager-previous span {
  -webkit-font-smoothing: antialiased;
  color: #ee482e;
  font-family: 'Font Awesome 5 Free';
  font-size: 0;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  white-space: nowrap;
}
.pager > li.pager-previous a:before,
.pager > li.pager-previous span:before {
  content: '\f104';
  font-size: 1.4rem;
}
.pager > li.pager-previous a:hover,
.pager > li.pager-previous span:hover,
.pager > li.pager-previous a:focus,
.pager > li.pager-previous span:focus {
  background: #fff;
  color: #ee482e;
}
.pager > li.pager-next {
  border: 0;
  margin-right: 0;
}
.pager > li.pager-next a,
.pager > li.pager-next span {
  -webkit-font-smoothing: antialiased;
  color: #ee482e;
  font-family: 'Font Awesome 5 Free';
  font-size: 0;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  white-space: nowrap;
}
.pager > li.pager-next a:before,
.pager > li.pager-next span:before {
  content: '\f105';
  font-size: 1.4rem;
}
.pager > li.pager-next a:hover,
.pager > li.pager-next span:hover,
.pager > li.pager-next a:focus,
.pager > li.pager-next span:focus {
  background: #fff;
  color: #ee482e;
}
.pager > li.pager-last {
  border: 0;
}
.pager > li.pager-last a,
.pager > li.pager-last span {
  -webkit-font-smoothing: antialiased;
  color: #ee482e;
  font-family: 'Font Awesome 5 Free';
  font-size: 0;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  white-space: nowrap;
}
.pager > li.pager-last a:before,
.pager > li.pager-last span:before {
  content: '\f101';
  font-size: 1.4rem;
}
.pager > li.pager-last a:hover,
.pager > li.pager-last span:hover,
.pager > li.pager-last a:focus,
.pager > li.pager-last span:focus {
  background: #fff;
  color: #ee482e;
}
.pager > li.pager-ellipsis {
  -webkit-font-smoothing: antialiased;
  border: 0;
  color: #ee482e;
  font-family: 'Font Awesome 5 Free';
  font-size: 0;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  margin-right: 0;
  text-rendering: auto;
  white-space: nowrap;
}
.pager > li.pager-ellipsis:before {
  content: '\f141';
  font-size: 1.4rem;
}
.pager > li.pager-ellipsis:hover,
.pager > li.pager-ellipsis:focus {
  background: white;
  color: #ee482e;
}
blockquote {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 29px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 42px;
  border-left: 4px solid #ED5025;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 27px;
}
blockquote p {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 29px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 42px;
}
h1 {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 992px) {
  h1 {
    font-size: 42px;
    letter-spacing: 0.14px;
  }
}
h2 {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;
}
@md-and-up {
  font-size: 32px;
}
h3 {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;
}
@md-and-up {
  font-size: 24px;
}
h4 {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.2;
}
@md-and-up {
  font-size: 20px;
}
/* --- [ Videos ] --------------- */
.embedded-video {
  background: #ccc;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 100%;
}
.region-content .node .embedded-video {
  margin-left: 0;
  max-width: 620px;
}
.field-signature-video {
  position: relative;
}
.video-embed-description {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(137, 15, 94, 0.8);
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 12px 18px;
  position: absolute;
  right: 0;
}
.player {
  padding-bottom: 56.33%;
  position: relative;
}
.player .ratio {
  display: block;
  height: auto;
  width: 100%;
}
.player iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.region[class*="sidebar"] .form-item label {
  display: inline-block;
  margin-top: 6px;
}
.region[class*="sidebar"] .form-item.form-type-checkbox label,
.region[class*="sidebar"] .form-item.form-type-radio label {
  margin-top: 0;
}
.region[class*="sidebar"] .form-submit {
  display: block;
  margin-top: 16px;
  width: 100%;
}
.region[class*="sidebar"] .field-registration-product {
  margin-bottom: 20px;
}
.region[class*="sidebar"] .field-registration-product:last-child {
  margin-bottom: 0;
}
.region[class*="sidebar"] .commerce-add-to-cart > div > * ~ * {
  margin-top: 20px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-wrapper {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #E6E6E6;
  min-width: 100%;
  width: calc(100% + 60px);
  margin: 20px -30px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-wrapper > * {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-wrapper > *:nth-child(odd) {
  padding-right: 10px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-wrapper > *:nth-child(even) {
  padding-left: 10px;
}
.region[class*="sidebar"] .commerce-add-to-cart .price {
  font-family: 'Lovelo Black', sans-serif;
  font-size: 24px;
  color: #890f5e;
  width: auto;
}
.region[class*="sidebar"] .commerce-add-to-cart .form-item-quantity {
  position: relative;
  width: 500px;
}
.region[class*="sidebar"] .commerce-add-to-cart .form-item-quantity label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  border: 1px solid transparent;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons:after {
  content: ' ';
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: 0;
  background-color: #bfbfbf;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link {
  display: block;
  float: none;
  height: 50%;
  width: 30px;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a {
  margin: 0;
  display: block;
  height: 100%;
  border: none;
  border-radius: 0;
  padding: 0;
  line-height: 1;
  text-indent: -9999px;
  position: relative;
  background-color: #ccc;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:before {
  content: ' ';
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 10px 10px;
  background-position: center;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:focus {
  transform: none;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link a:active {
  background-color: #e5e5e5;
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link[class*="increase"] a:before {
  background-image: url(../images/arrowup--gray.svg);
}
.region[class*="sidebar"] .commerce-add-to-cart .quantity-buttons .commerce-quantity-plusminus-link[class*="decrease"] a:before {
  background-image: url(../images/arrowdown--gray.svg);
}
.region[class*="sidebar"] .commerce-add-to-cart label[for="edit-quantity"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
legend {
  float: left;
  width: 100%;
  box-sizing: border-box;
  font-size: 14.5px;
  letter-spacing: 0.05em;
  line-height: 1.5;
  padding: 10px 16px 9px;
  background-color: #890f5e;
  color: #fff;
}
legend + * {
  clear: left;
}
legend span {
  display: block;
  color: #890f5e;
  margin-top: 28px;
  margin-bottom: 0;
  line-height: 1;
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  font-size: 14.5px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: 0.03em;
  color: #fff;
}
legend span a {
  display: block;
}
legend span a:link {
  color: inherit;
}
legend span a:hover {
  text-decoration: none;
}
.views-exposed-widgets {
  display: table;
}
@supports (display: flex) {
  .views-exposed-widgets {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
  }
}
.views-exposed-widgets .views-exposed-widget {
  float: none;
  display: table-cell;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 10px;
  vertical-align: bottom;
}
@supports (display: flex) {
  .views-exposed-widgets .views-exposed-widget {
    display: block;
  }
}
#views-form-commerce-cart-block-default .form-submit {
  margin-left: auto;
  margin-right: 16px;
  display: block;
  width: auto;
  padding: 4px 8px 3px;
  position: relative;
  top: -20px;
  font-size: 13.5px;
}
#views-form-commerce-cart-block-default .form-submit:hover {
  color: #fff !important;
  border-color: #e0275e !important;
  background-color: #e0275e;
}
.cart_contents legend {
  padding: 0;
  background-color: transparent;
}
.cart_contents legend span {
  color: #ee482e;
  padding-bottom: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 3px solid #e5e5e5;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1.5;
}
fieldset {
  position: relative;
  padding: 0;
  border: none;
  margin: 0;
  margin: 0 auto;
  box-sizing: border-box;
}
fieldset ~ fieldset,
fieldset ~ [id*="ajax-wrapper"] {
  margin-top: 30px;
}
fieldset textarea,
fieldset select,
fieldset input[type="date"],
fieldset input[type="datetime"],
fieldset input[type="datetime-local"],
fieldset input[type="email"],
fieldset input[type="month"],
fieldset input[type="number"],
fieldset input[type="password"],
fieldset input[type="search"],
fieldset input[type="tel"],
fieldset input[type="text"],
fieldset input[type="time"],
fieldset input[type="url"],
fieldset input[type="week"],
fieldset input[type="checkbox"],
fieldset input[type="radio"] {
  border-color: #fff;
  box-shadow: 0 6px 12px -5px rgba(0, 0, 0, 0.05);
}
fieldset input[type="checkbox"],
fieldset input[type="radio"] {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
}
.not-logged-in.page-user fieldset textarea,
.page-user-edit fieldset textarea,
.not-logged-in.page-user fieldset select,
.page-user-edit fieldset select,
.not-logged-in.page-user fieldset input[type="date"],
.page-user-edit fieldset input[type="date"],
.not-logged-in.page-user fieldset input[type="datetime"],
.page-user-edit fieldset input[type="datetime"],
.not-logged-in.page-user fieldset input[type="datetime-local"],
.page-user-edit fieldset input[type="datetime-local"],
.not-logged-in.page-user fieldset input[type="email"],
.page-user-edit fieldset input[type="email"],
.not-logged-in.page-user fieldset input[type="month"],
.page-user-edit fieldset input[type="month"],
.not-logged-in.page-user fieldset input[type="number"],
.page-user-edit fieldset input[type="number"],
.not-logged-in.page-user fieldset input[type="password"],
.page-user-edit fieldset input[type="password"],
.not-logged-in.page-user fieldset input[type="search"],
.page-user-edit fieldset input[type="search"],
.not-logged-in.page-user fieldset input[type="tel"],
.page-user-edit fieldset input[type="tel"],
.not-logged-in.page-user fieldset input[type="text"],
.page-user-edit fieldset input[type="text"],
.not-logged-in.page-user fieldset input[type="time"],
.page-user-edit fieldset input[type="time"],
.not-logged-in.page-user fieldset input[type="url"],
.page-user-edit fieldset input[type="url"],
.not-logged-in.page-user fieldset input[type="week"],
.page-user-edit fieldset input[type="week"],
.not-logged-in.page-user fieldset input[type="checkbox"],
.page-user-edit fieldset input[type="checkbox"],
.not-logged-in.page-user fieldset input[type="radio"],
.page-user-edit fieldset input[type="radio"] {
  border-color: #e5e5e5;
  box-shadow: none;
}
.not-logged-in.page-user fieldset input[type="checkbox"],
.page-user-edit fieldset input[type="checkbox"],
.not-logged-in.page-user fieldset input[type="radio"],
.page-user-edit fieldset input[type="radio"] {
  box-shadow: none;
}
.not-logged-in.page-user fieldset[id*="field-primary-contact"],
.page-user-edit fieldset[id*="field-primary-contact"] {
  margin-top: 30px !important;
}
.not-logged-in.page-user fieldset [id*="contact-name-add-more-wrapper"],
.page-user-edit fieldset [id*="contact-name-add-more-wrapper"] {
  border: 1px solid #f2f2f2;
  border-top-width: 0;
  padding: 20px 30px 30px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 480px) {
  .not-logged-in.page-user fieldset [id*="contact-name-add-more-wrapper"],
  .page-user-edit fieldset [id*="contact-name-add-more-wrapper"] {
    padding: 20px 20px 30px;
  }
}
.not-logged-in.page-user fieldset [id*="contact-name-add-more-wrapper"] > .clearfix,
.page-user-edit fieldset [id*="contact-name-add-more-wrapper"] > .clearfix {
  line-height: 0;
}
.not-logged-in.page-user fieldset [id*="contact-name-add-more-wrapper"] > .clearfix:before,
.page-user-edit fieldset [id*="contact-name-add-more-wrapper"] > .clearfix:before,
.not-logged-in.page-user fieldset [id*="contact-name-add-more-wrapper"] > .clearfix:after,
.page-user-edit fieldset [id*="contact-name-add-more-wrapper"] > .clearfix:after {
  content: none;
}
.collapsible legend {
  background-color: #e5e5e5;
}
.collapsible .fieldset-wrapper {
  background-color: #f2f2f2;
  padding: 20px 30px 30px;
}
@media only screen and (max-width: 480px) {
  .collapsible .fieldset-wrapper {
    padding: 20px 20px 30px;
  }
}
.collapsible .fieldset-wrapper legend {
  background-color: #570a3c;
  color: #fff;
}
.collapsible .fieldset-wrapper legend * {
  color: inherit;
}
.collapsible .fieldset-wrapper fieldset {
  margin-top: 30px;
}
.collapsible > .fieldset-wrapper > .form-wrapper .fieldset-wrapper {
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (max-width: 480px) {
  .collapsible > .fieldset-wrapper > .form-wrapper .fieldset-wrapper {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.collapsible > .fieldset-wrapper > .form-wrapper .fieldset-wrapper > .form-item {
  border-top: 1px solid #e5e5e5;
  padding-top: 12px;
  margin-top: 16px !important;
}
.collapsible > .fieldset-wrapper > .form-wrapper .fieldset-wrapper > .form-item:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0 !important;
}
.collapsible .fieldset-title {
  position: relative;
  color: #444;
  text-decoration: none;
}
.collapsible .fieldset-title:hover {
  text-decoration: none;
}
.collapsible .fieldset-title:after {
  content: ' ';
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/down-caret--gray.svg);
  background-size: contain;
  top: 8px;
  right: 0;
  transform: scale(1, -1, 1);
  width: 16px;
  height: 16px;
  -webkit-transition: transform 100ms ease 0ms;
  -moz-transition: transform 100ms ease 0ms;
  -o-transition: transform 100ms ease 0ms;
  transition: transform 100ms ease 0ms;
}
.collapsible .fieldset-wrapper .fieldset-title:after {
  background-image: url(../images/down-caret--white.svg);
}
html.js .collapsible.collapsed {
  height: auto;
}
.collapsible.collapsed .fieldset-title:after {
  transform: scale3d(1, -1, 1);
}
form {
  margin: 0 auto;
  box-sizing: border-box;
  /*.form-item-quantity {
    background-color: @g2;
    width: 100%;
    float: right;
    width: 50%;

    input#edit-quantity {
      width: auto;
      float: left;
    }
    .commerce-quantity-plusminus-link {
      display: inline-block;

      a {
        margin: 0;
        border: none !important;
        border-radius: 0;
        background-color: fade(@g1, 50%);

        &:focus {
          transform: none;
        }
      }
    }
  }

  div#edit-price {
    background-color: @g2;
    float: left;
    color: @white;
    font-size: 30px;
    font-weight: bold;
    width: 50%;
  }*/
}
form .__half-width {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
}
form .__auto-width {
  display: inline-block;
  vertical-align: top;
  width: auto;
  box-sizing: border-box;
}
form .__p-left {
  padding-left: 10px;
}
form .__p-right {
  padding-right: 10px;
}
form .__m-top {
  margin: 0;
  margin-top: 14px;
}
form .__m-top-0 {
  margin-top: 0;
}
form .__border-top {
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin-top: 28px;
  position: relative;
}
form label,
form .group-subscribe > div > span,
form .group-demo > div > span {
  color: #890f5e;
  margin-top: 28px;
  margin-bottom: 0;
  line-height: 1;
  margin: 6px 0;
  font-family: 'PT Sans', sans-serif;
  font-size: 14.5px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: 0.03em;
  color: #570a3c;
  display: inline-block;
  vertical-align: middle;
}
form .form-type-checkbox,
form .form-type-radio {
  position: relative;
}
form .form-type-checkbox label,
form .form-type-radio label {
  text-transform: none;
  color: #57354b;
}
form .form-type-checkbox input ~ label,
form .form-type-radio input ~ label {
  font-weight: normal;
}
form .form-type-checkbox .ajax-progress-throbber,
form .form-type-radio .ajax-progress-throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
}
form .form-type-checkbox .ajax-progress-throbber .message,
form .form-type-radio .ajax-progress-throbber .message {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
form .form-type-checkbox .ajax-progress-throbber .throbber,
form .form-type-radio .ajax-progress-throbber .throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  background-image: url(../images/white-dots.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
  height: auto;
  margin: 0;
  animation: fadeinout 750ms infinite;
}
form .form-wrapper + fieldset,
form fieldset + .form-wrapper {
  margin-top: 30px;
}
form .fieldset-description {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #890f5e;
  background-color: rgba(255, 255, 255, 0.75);
  color: #570a3c;
}
form .fieldset-description > * {
  margin-top: 12px;
}
form .fieldset-description > *:last-child {
  margin-bottom: 0;
}
form .fieldset-description ul,
form .fieldset-description ol {
  margin-left: 0 !important;
}
form .fieldset-description ul li ~ li,
form .fieldset-description ol li ~ li {
  margin-top: 8px;
}
form .description {
  color: #444;
  opacity: 0.9;
  margin-top: 8px;
}
form .form-required,
form .name-required-component-marker {
  color: #ee482e;
}
form#mazzoni-recurring-events-form {
  max-width: 800px;
  background-color: #f2f2f2;
  padding: 20px 30px;
}
form#mazzoni-recurring-events-form textarea,
form#mazzoni-recurring-events-form select,
form#mazzoni-recurring-events-form input[type="date"],
form#mazzoni-recurring-events-form input[type="datetime"],
form#mazzoni-recurring-events-form input[type="datetime-local"],
form#mazzoni-recurring-events-form input[type="email"],
form#mazzoni-recurring-events-form input[type="month"],
form#mazzoni-recurring-events-form input[type="number"],
form#mazzoni-recurring-events-form input[type="password"],
form#mazzoni-recurring-events-form input[type="search"],
form#mazzoni-recurring-events-form input[type="tel"],
form#mazzoni-recurring-events-form input[type="text"],
form#mazzoni-recurring-events-form input[type="time"],
form#mazzoni-recurring-events-form input[type="url"],
form#mazzoni-recurring-events-form input[type="week"],
form#mazzoni-recurring-events-form input[type="checkbox"],
form#mazzoni-recurring-events-form input[type="radio"] {
  border-color: #fff;
  box-shadow: 0 6px 12px -5px rgba(0, 0, 0, 0.05);
}
form#mazzoni-recurring-events-form input[type="checkbox"],
form#mazzoni-recurring-events-form input[type="radio"] {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
}
form#mazzoni-recurring-events-form input[type="submit"] {
  margin-left: auto;
  margin-right: 0;
  display: block;
  width: auto;
  background-color: #e0275e;
  color: #fff !important;
}
form#mazzoni-recurring-events-form input[type="submit"]:hover {
  background-color: #890f5e;
}
form#mazzoni-recurring-events-form > div > .form-item,
form#mazzoni-recurring-events-form > div > .container-inline-date {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup {
  width: 100%;
  margin-right: 0;
  float: none;
}
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > label,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > label,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > label {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 20%;
}
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes input,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time input,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes select,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency select,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time select,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time select,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup select {
  margin-left: 10px;
  margin-right: 10px;
}
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-select,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-select,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-select,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-select,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-select,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-text,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-text,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-text,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-text,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-text,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .date-padding,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .date-padding,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .date-padding,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .date-padding,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .date-padding {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  float: none;
}
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-select label,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-select label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-select label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-select label,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-select label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-text label,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-text label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-text label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-text label,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-text label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .date-padding label,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .date-padding label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .date-padding label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .date-padding label,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .date-padding label,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-select .form-item,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-select .form-item,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-select .form-item,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-select .form-item,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-select .form-item,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-text .form-item,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-text .form-item,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-text .form-item,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-text .form-item,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-text .form-item,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .date-padding .form-item,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .date-padding .form-item,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .date-padding .form-item,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .date-padding .form-item,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .date-padding .form-item {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-select label input,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-select label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-select label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-select label input,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-select label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-text label input,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-text label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-text label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-text label input,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-text label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .date-padding label input,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .date-padding label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .date-padding label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .date-padding label input,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .date-padding label input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-select .form-item input,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-select .form-item input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-select .form-item input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-select .form-item input,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-select .form-item input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .form-text .form-item input,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .form-text .form-item input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .form-text .form-item input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .form-text .form-item input,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .form-text .form-item input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > .date-padding .form-item input,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency > .date-padding .form-item input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time > .date-padding .form-item input,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time > .date-padding .form-item input,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup > .date-padding .form-item input {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes .description,
form#mazzoni-recurring-events-form .form-type-select.form-item-frequency .description,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-start-time .description,
form#mazzoni-recurring-events-form .form-type-textfield.form-item-end-time .description,
form#mazzoni-recurring-events-form .container-inline-date > .form-type-date-popup .description {
  display: inline-block;
  vertical-align: middle;
}
form#mazzoni-recurring-events-form .form-type-textfield.form-item-num-nodes > label {
  width: 40%;
}
form#commerce-registration-node-registrations-settings-form input[type="submit"] {
  margin-left: auto;
  margin-right: 0;
  display: block;
  width: 100%;
  margin-top: 30px;
}
form#commerce-registration-node-registrations-settings-form > div > .form-item,
form#commerce-registration-node-registrations-settings-form > div > .container-inline-date {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date + .container-inline-date {
  margin-top: 20px;
  border-top: 1px solid #e5e5e5;
  padding-top: 12px;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup {
  width: 100%;
  margin-right: 0;
  float: none;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > label {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 20%;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup select {
  margin-left: 10px;
  margin-right: 10px;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  float: none;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select label,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text label,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding label,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select .form-item,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text .form-item,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding .form-item {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select label input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text label input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding label input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-select .form-item input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .form-text .form-item input,
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup > .date-padding .form-item input {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}
form#commerce-registration-node-registrations-settings-form .container-inline-date > .form-type-date-popup .description {
  display: inline-block;
  vertical-align: middle;
}
form[id^="commerce-checkout"] {
  margin: 0 auto;
  box-sizing: border-box;
}
form[id^="commerce-checkout"] > div > fieldset:not(.cart_contents):not(.checkout-buttons):not(.registration_information) > .fieldset-wrapper,
form[id^="commerce-checkout"] > div > div[id*="ajax-wrapper"] > fieldset > .fieldset-wrapper {
  background-color: #f2f2f2;
  padding: 20px 30px 30px;
}
form[id^="commerce-checkout"] .field-name-field-first-name,
form[id^="commerce-checkout"] .field-name-field-last-name,
form[id^="commerce-checkout"] .field-name-field-company,
form[id^="commerce-checkout"] .field-name-field-race-ethnicity,
form[id^="commerce-checkout"] .field-name-field-gender,
form[id^="commerce-checkout"] .field-name-field-sexual-orientation,
form[id^="commerce-checkout"] .field-name-field-year-of-birth,
form[id^="commerce-checkout"] .field-name-field-phone,
form[id^="commerce-checkout"] .form-item[class*="locality"],
form[id^="commerce-checkout"] .form-item[class*="administrative-area"] {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
  margin: 0;
  margin-top: 14px;
}
form[id^="commerce-checkout"] .form-item[class*="postal-code"] {
  display: inline-block;
  vertical-align: top;
  width: auto;
  box-sizing: border-box;
}
form[id^="commerce-checkout"] .field-name-field-first-name:not([id*="edit-registration-information"]),
form[id^="commerce-checkout"] .field-name-field-last-name:not([id*="edit-registration-information"]) {
  margin-top: 0;
}
form[id^="commerce-checkout"] .field-name-field-first-name,
form[id^="commerce-checkout"] .field-name-field-company,
form[id^="commerce-checkout"] .field-name-field-race-ethnicity,
form[id^="commerce-checkout"] .field-name-field-sexual-orientation,
form[id^="commerce-checkout"] .field-name-field-phone,
form[id^="commerce-checkout"] .form-item[class*="locality"] {
  padding-right: 10px;
}
form[id^="commerce-checkout"] .field-name-field-last-name,
form[id^="commerce-checkout"] .field-name-field-gender,
form[id^="commerce-checkout"] .field-name-field-year-of-birth,
form[id^="commerce-checkout"] .form-item[class*="administrative-area"] {
  padding-left: 10px;
}
form[id^="commerce-checkout"] .addressfield-container-inline {
  font-size: 0;
}
form[id^="commerce-checkout"] .addressfield-container-inline > .addressfield-container-inline,
form[id^="commerce-checkout"] .addressfield-container-inline > .form-item,
form[id^="commerce-checkout"] .street-block {
  margin: 0;
  margin-top: 14px;
  float: none;
}
form[id^="commerce-checkout"] .name-block > .form-item:first-child {
  margin-top: 0;
}
form[id^="commerce-checkout"] .field-type-addressfield:not([id^="edit-customer-profile-billing"]),
form[id^="commerce-checkout"] [id*="billing"] > [id^="addressfield-wrapper"],
form[id^="commerce-checkout"] .group-subscribe,
form[id^="commerce-checkout"] .group-demo {
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
  margin-top: 28px;
  position: relative;
}
form[id^="commerce-checkout"] #payment-details > .form-item,
form[id^="commerce-checkout"] #payment-details > .commerce-credit-card-expiration {
  margin: 0;
  margin-top: 14px;
}
form[id^="commerce-checkout"] #payment-details .form-type-select,
form[id^="commerce-checkout"] #payment-details .form-type-textfield {
  display: inline-block;
  vertical-align: top;
  width: auto;
  box-sizing: border-box;
  vertical-align: bottom;
}
form[id^="commerce-checkout"] #payment-details .commerce-month-year-divider {
  color: transparent;
  text-indent: -9999px;
  height: 100%;
  display: inline-block;
  padding: 9px 4px 8px;
  position: relative;
  margin: 0 8px 0 6px;
}
form[id^="commerce-checkout"] #payment-details .commerce-month-year-divider:after {
  content: ' ';
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%) rotate(15deg);
  width: 2px;
  background-color: #e5e5e5;
}
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-type"] {
  padding-right: 10px;
}
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-number"] {
  padding-left: 10px;
}
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"],
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] {
  white-space: nowrap;
  margin-bottom: 0;
}
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"] label,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] label {
  margin-right: 10px;
}
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"] label,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] label,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"] select,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] select,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-month"] .form-text,
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] .form-text {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-exp-year"] {
  margin-bottom: 0;
}
form[id^="commerce-checkout"] #payment-details > .commerce-credit-card-expiration {
  display: inline-block;
  margin-top: 24px;
  padding-right: 10px;
}
form[id^="commerce-checkout"] #payment-details .form-item[class*="credit-card-code"] {
  padding-left: 10px;
}
form#ma-donate-donate-form,
form.crm-core-profile-entry-form {
  padding: 0 30px 30px;
  background-color: #f2f2f2;
}
@media only screen and (max-width: 768px) {
  form#ma-donate-donate-form,
  form.crm-core-profile-entry-form {
    padding: 0 20px 30px;
  }
}
form#ma-donate-donate-form .form-item,
form.crm-core-profile-entry-form .form-item {
  margin-bottom: 20px;
}
form#ma-donate-donate-form *,
form.crm-core-profile-entry-form * {
  box-sizing: border-box;
}
form#ma-donate-donate-form textarea,
form.crm-core-profile-entry-form textarea,
form#ma-donate-donate-form select,
form.crm-core-profile-entry-form select,
form#ma-donate-donate-form input[type="date"],
form.crm-core-profile-entry-form input[type="date"],
form#ma-donate-donate-form input[type="datetime"],
form.crm-core-profile-entry-form input[type="datetime"],
form#ma-donate-donate-form input[type="datetime-local"],
form.crm-core-profile-entry-form input[type="datetime-local"],
form#ma-donate-donate-form input[type="email"],
form.crm-core-profile-entry-form input[type="email"],
form#ma-donate-donate-form input[type="month"],
form.crm-core-profile-entry-form input[type="month"],
form#ma-donate-donate-form input[type="number"],
form.crm-core-profile-entry-form input[type="number"],
form#ma-donate-donate-form input[type="password"],
form.crm-core-profile-entry-form input[type="password"],
form#ma-donate-donate-form input[type="search"],
form.crm-core-profile-entry-form input[type="search"],
form#ma-donate-donate-form input[type="tel"],
form.crm-core-profile-entry-form input[type="tel"],
form#ma-donate-donate-form input[type="text"],
form.crm-core-profile-entry-form input[type="text"],
form#ma-donate-donate-form input[type="time"],
form.crm-core-profile-entry-form input[type="time"],
form#ma-donate-donate-form input[type="url"],
form.crm-core-profile-entry-form input[type="url"],
form#ma-donate-donate-form input[type="week"],
form.crm-core-profile-entry-form input[type="week"],
form#ma-donate-donate-form input[type="checkbox"],
form.crm-core-profile-entry-form input[type="checkbox"],
form#ma-donate-donate-form input[type="radio"],
form.crm-core-profile-entry-form input[type="radio"] {
  border-color: #fff;
  box-shadow: 0 6px 12px -5px rgba(0, 0, 0, 0.05);
}
form#ma-donate-donate-form input[type="checkbox"],
form.crm-core-profile-entry-form input[type="checkbox"],
form#ma-donate-donate-form input[type="radio"],
form.crm-core-profile-entry-form input[type="radio"] {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
}
form#ma-donate-donate-form > div,
form.crm-core-profile-entry-form > div {
  font-size: 0;
}
form#ma-donate-donate-form > div > *,
form.crm-core-profile-entry-form > div > * {
  font-size: 14.5px;
}
form#ma-donate-donate-form > div > [class*="individual-contact-name"],
form.crm-core-profile-entry-form > div > [class*="individual-contact-name"],
form#ma-donate-donate-form > div .field-type-addressfield > [id*="customer-address"] > .street-block,
form.crm-core-profile-entry-form > div .field-type-addressfield > [id*="customer-address"] > .street-block,
form#ma-donate-donate-form > div .field-type-addressfield > [id*="customer-address"] > [class*="form-item"],
form.crm-core-profile-entry-form > div .field-type-addressfield > [id*="customer-address"] > [class*="form-item"],
form#ma-donate-donate-form > div .field-type-addressfield > [id*="customer-address"] > [class*="addressfield-container"] > [class*="form-item"],
form.crm-core-profile-entry-form > div .field-type-addressfield > [id*="customer-address"] > [class*="addressfield-container"] > [class*="form-item"],
form#ma-donate-donate-form > div > [class*="form-item"][class*="credit-card"],
form.crm-core-profile-entry-form > div > [class*="form-item"][class*="credit-card"] {
  margin-bottom: 12px;
}
form#ma-donate-donate-form > div > [class*="field-type"][class*="addressfield"],
form.crm-core-profile-entry-form > div > [class*="field-type"][class*="addressfield"] {
  margin-bottom: 8px;
}
form#ma-donate-donate-form fieldset.form-wrapper legend,
form.crm-core-profile-entry-form fieldset.form-wrapper legend {
  display: none;
}
form#ma-donate-donate-form h2,
form.crm-core-profile-entry-form h2,
form#ma-donate-donate-form [id*="customer-address"] > label:first-child,
form.crm-core-profile-entry-form [id*="customer-address"] > label:first-child {
  display: block;
  background: #890f5e;
  color: #fff;
  font-size: 14.5px;
  padding: 10px 16px 9px;
  margin: 40px -30px 20px;
}
@media only screen and (max-width: 768px) {
  form#ma-donate-donate-form h2,
  form.crm-core-profile-entry-form h2,
  form#ma-donate-donate-form [id*="customer-address"] > label:first-child,
  form.crm-core-profile-entry-form [id*="customer-address"] > label:first-child {
    margin: 30px -20px 20px;
  }
}
form#ma-donate-donate-form h2:first-child,
form.crm-core-profile-entry-form h2:first-child,
form#ma-donate-donate-form [id*="customer-address"] > label:first-child:first-child,
form.crm-core-profile-entry-form [id*="customer-address"] > label:first-child:first-child {
  margin-top: 0;
}
form#ma-donate-donate-form .form-item-amount,
form.crm-core-profile-entry-form .form-item-amount {
  font-size: 0;
  margin: 0 -4px;
}
@supports (display: flex) {
  form#ma-donate-donate-form .form-item-amount,
  form.crm-core-profile-entry-form .form-item-amount {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
form#ma-donate-donate-form .form-item-amount > *,
form.crm-core-profile-entry-form .form-item-amount > * {
  display: inline-block;
  vertical-align: middle;
  min-width: 100px;
}
@supports (display: flex) {
  @media only screen and (max-width: 480px) {
    form#ma-donate-donate-form .form-item-amount > *,
    form.crm-core-profile-entry-form .form-item-amount > * {
      flex-basis: 50%;
    }
  }
}
form#ma-donate-donate-form .form-item-amount > select,
form.crm-core-profile-entry-form .form-item-amount > select {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
form#ma-donate-donate-form .form-item-amount #edit-amount,
form.crm-core-profile-entry-form .form-item-amount #edit-amount {
  clear: both;
}
form#ma-donate-donate-form .form-item-amount #edit-amount input[type="radio"],
form.crm-core-profile-entry-form .form-item-amount #edit-amount input[type="radio"] {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
form#ma-donate-donate-form .form-item-amount #edit-amount .form-item-amount,
form.crm-core-profile-entry-form .form-item-amount #edit-amount .form-item-amount {
  float: left;
  width: 16.66%;
  margin: 4px;
  min-width: 100px;
}
form#ma-donate-donate-form .form-item-amount #edit-amount .form-item-amount label,
form.crm-core-profile-entry-form .form-item-amount #edit-amount .form-item-amount label {
  display: inline-block;
  color: #fff !important;
  font-weight: bold;
  text-align: center;
  text-shadow: none;
  padding: 10px;
  box-sizing: border-box;
  border-color: transparent !important;
  border-width: 4px;
  background-clip: padding-box;
  background-color: #ee482e;
  box-shadow: none !important;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
form#ma-donate-donate-form .form-item-amount #edit-amount .form-item-amount label:hover,
form.crm-core-profile-entry-form .form-item-amount #edit-amount .form-item-amount label:hover {
  cursor: pointer;
}
form#ma-donate-donate-form .form-item-amount #edit-amount .form-item-amount input:not(:checked) + label,
form.crm-core-profile-entry-form .form-item-amount #edit-amount .form-item-amount input:not(:checked) + label {
  background-color: #ee482e;
}
form#ma-donate-donate-form .form-item-amount #edit-amount .form-item-amount input:checked + label,
form.crm-core-profile-entry-form .form-item-amount #edit-amount .form-item-amount input:checked + label {
  background-color: #380627;
}
form#ma-donate-donate-form div.form-item-other #edit-other,
form.crm-core-profile-entry-form div.form-item-other #edit-other {
  display: inline-block;
  box-sizing: border-box;
  box-shadow: none;
  border: 4px solid transparent;
  background-clip: padding-box;
  background-repeat: no-repeat !important;
  background-position: 12px center !important;
  background-image: url(../images/dollar-sign--orange.svg) !important;
  padding-left: 30px;
  outline: 1px solid #f2f2f2;
  outline-offset: -5px;
}
form#ma-donate-donate-form div.form-item-other label,
form.crm-core-profile-entry-form div.form-item-other label {
  height: 1px;
  width: 1px;
  position: absolute;
}
form#ma-donate-donate-form .form-item-Dedication-Type--c,
form.crm-core-profile-entry-form .form-item-Dedication-Type--c,
form#ma-donate-donate-form .form-item-Dedication-Honoree-Name--c,
form.crm-core-profile-entry-form .form-item-Dedication-Honoree-Name--c,
form#ma-donate-donate-form .form-item-Dedication-Recipient-Name--c,
form.crm-core-profile-entry-form .form-item-Dedication-Recipient-Name--c,
form#ma-donate-donate-form .form-item-Dedication-Recipient-Email--c,
form.crm-core-profile-entry-form .form-item-Dedication-Recipient-Email--c {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-Dedication-Type--c,
  form.crm-core-profile-entry-form .form-item-Dedication-Type--c,
  form#ma-donate-donate-form .form-item-Dedication-Honoree-Name--c,
  form.crm-core-profile-entry-form .form-item-Dedication-Honoree-Name--c,
  form#ma-donate-donate-form .form-item-Dedication-Recipient-Name--c,
  form.crm-core-profile-entry-form .form-item-Dedication-Recipient-Name--c,
  form#ma-donate-donate-form .form-item-Dedication-Recipient-Email--c,
  form.crm-core-profile-entry-form .form-item-Dedication-Recipient-Email--c {
    width: 100% !important;
  }
}
form#ma-donate-donate-form .form-item-Dedication-Type--c,
form.crm-core-profile-entry-form .form-item-Dedication-Type--c,
form#ma-donate-donate-form .form-item-Dedication-Recipient-Name--c,
form.crm-core-profile-entry-form .form-item-Dedication-Recipient-Name--c {
  padding-right: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-Dedication-Type--c,
  form.crm-core-profile-entry-form .form-item-Dedication-Type--c,
  form#ma-donate-donate-form .form-item-Dedication-Recipient-Name--c,
  form.crm-core-profile-entry-form .form-item-Dedication-Recipient-Name--c {
    padding-right: 0;
  }
}
form#ma-donate-donate-form .form-item-Dedication-Honoree-Name--c,
form.crm-core-profile-entry-form .form-item-Dedication-Honoree-Name--c,
form#ma-donate-donate-form .form-item-Dedication-Recipient-Email--c,
form.crm-core-profile-entry-form .form-item-Dedication-Recipient-Email--c {
  padding-left: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-Dedication-Honoree-Name--c,
  form.crm-core-profile-entry-form .form-item-Dedication-Honoree-Name--c,
  form#ma-donate-donate-form .form-item-Dedication-Recipient-Email--c,
  form.crm-core-profile-entry-form .form-item-Dedication-Recipient-Email--c {
    padding-left: 0;
  }
}
form#ma-donate-donate-form .form-item-LastName,
form.crm-core-profile-entry-form .form-item-LastName,
form#ma-donate-donate-form .form-item-FirstName,
form.crm-core-profile-entry-form .form-item-FirstName {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  box-sizing: border-box !important;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-LastName,
  form.crm-core-profile-entry-form .form-item-LastName,
  form#ma-donate-donate-form .form-item-FirstName,
  form.crm-core-profile-entry-form .form-item-FirstName {
    width: 100% !important;
  }
}
form#ma-donate-donate-form .form-item-FirstName,
form.crm-core-profile-entry-form .form-item-FirstName {
  padding-right: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-FirstName,
  form.crm-core-profile-entry-form .form-item-FirstName {
    padding-right: 0;
  }
}
form#ma-donate-donate-form .form-item-LastName,
form.crm-core-profile-entry-form .form-item-LastName {
  padding-left: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-LastName,
  form.crm-core-profile-entry-form .form-item-LastName {
    padding-left: 0;
  }
}
form#ma-donate-donate-form .form-item-Spouse-Partner-First-Name--c,
form.crm-core-profile-entry-form .form-item-Spouse-Partner-First-Name--c,
form#ma-donate-donate-form .form-item-Spouse-Partner-Last-Name--c,
form.crm-core-profile-entry-form .form-item-Spouse-Partner-Last-Name--c {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-Spouse-Partner-First-Name--c,
  form.crm-core-profile-entry-form .form-item-Spouse-Partner-First-Name--c,
  form#ma-donate-donate-form .form-item-Spouse-Partner-Last-Name--c,
  form.crm-core-profile-entry-form .form-item-Spouse-Partner-Last-Name--c {
    width: 100% !important;
  }
}
form#ma-donate-donate-form .form-item-Spouse-Partner-First-Name--c,
form.crm-core-profile-entry-form .form-item-Spouse-Partner-First-Name--c {
  padding-right: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-Spouse-Partner-First-Name--c,
  form.crm-core-profile-entry-form .form-item-Spouse-Partner-First-Name--c {
    padding-right: 0;
  }
}
form#ma-donate-donate-form .form-item-Spouse-Partner-Last-Name--c,
form.crm-core-profile-entry-form .form-item-Spouse-Partner-Last-Name--c {
  padding-left: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-Spouse-Partner-Last-Name--c,
  form.crm-core-profile-entry-form .form-item-Spouse-Partner-Last-Name--c {
    padding-left: 0;
  }
}
form#ma-donate-donate-form div.addressfield-container-inline > div.form-item,
form.crm-core-profile-entry-form div.addressfield-container-inline > div.form-item {
  margin: 0;
}
form#ma-donate-donate-form .field-name-commerce-customer-address,
form.crm-core-profile-entry-form .field-name-commerce-customer-address {
  margin-top: 30px;
}
form#ma-donate-donate-form .street-block,
form.crm-core-profile-entry-form .street-block {
  font-size: 0;
}
form#ma-donate-donate-form .street-block > *,
form.crm-core-profile-entry-form .street-block > * {
  font-size: 14.5px;
}
form#ma-donate-donate-form .locality-block,
form.crm-core-profile-entry-form .locality-block {
  font-size: 0;
}
form#ma-donate-donate-form .locality-block > *,
form.crm-core-profile-entry-form .locality-block > * {
  font-size: 14.5px;
}
form#ma-donate-donate-form .locality-block .form-item-address-locality,
form.crm-core-profile-entry-form .locality-block .form-item-address-locality,
form#ma-donate-donate-form .locality-block .form-item-address-administrative-area,
form.crm-core-profile-entry-form .locality-block .form-item-address-administrative-area,
form#ma-donate-donate-form .locality-block .form-item-address-postal-code,
form.crm-core-profile-entry-form .locality-block .form-item-address-postal-code {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  box-sizing: border-box !important;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .locality-block .form-item-address-locality,
  form.crm-core-profile-entry-form .locality-block .form-item-address-locality,
  form#ma-donate-donate-form .locality-block .form-item-address-administrative-area,
  form.crm-core-profile-entry-form .locality-block .form-item-address-administrative-area,
  form#ma-donate-donate-form .locality-block .form-item-address-postal-code,
  form.crm-core-profile-entry-form .locality-block .form-item-address-postal-code {
    width: 100% !important;
  }
}
form#ma-donate-donate-form .locality-block .form-item-address-locality,
form.crm-core-profile-entry-form .locality-block .form-item-address-locality,
form#ma-donate-donate-form .locality-block .form-item-address-postal-code,
form.crm-core-profile-entry-form .locality-block .form-item-address-postal-code {
  padding-right: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .locality-block .form-item-address-locality,
  form.crm-core-profile-entry-form .locality-block .form-item-address-locality,
  form#ma-donate-donate-form .locality-block .form-item-address-postal-code,
  form.crm-core-profile-entry-form .locality-block .form-item-address-postal-code {
    padding-right: 0;
  }
}
form#ma-donate-donate-form .locality-block .form-item-address-administrative-area,
form.crm-core-profile-entry-form .locality-block .form-item-address-administrative-area {
  padding-left: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .locality-block .form-item-address-administrative-area,
  form.crm-core-profile-entry-form .locality-block .form-item-address-administrative-area {
    padding-left: 0;
  }
}
form#ma-donate-donate-form .form-item-credit-card-type,
form.crm-core-profile-entry-form .form-item-credit-card-type,
form#ma-donate-donate-form .form-item-credit-card-card-type,
form.crm-core-profile-entry-form .form-item-credit-card-card-type,
form#ma-donate-donate-form .form-item-credit-card-number,
form.crm-core-profile-entry-form .form-item-credit-card-number,
form#ma-donate-donate-form .commerce-credit-card-expiration,
form.crm-core-profile-entry-form .commerce-credit-card-expiration,
form#ma-donate-donate-form .form-item-credit-card-code,
form.crm-core-profile-entry-form .form-item-credit-card-code {
  display: inline-block !important;
  vertical-align: top !important;
  width: 50% !important;
  box-sizing: border-box !important;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-credit-card-type,
  form.crm-core-profile-entry-form .form-item-credit-card-type,
  form#ma-donate-donate-form .form-item-credit-card-card-type,
  form.crm-core-profile-entry-form .form-item-credit-card-card-type,
  form#ma-donate-donate-form .form-item-credit-card-number,
  form.crm-core-profile-entry-form .form-item-credit-card-number,
  form#ma-donate-donate-form .commerce-credit-card-expiration,
  form.crm-core-profile-entry-form .commerce-credit-card-expiration,
  form#ma-donate-donate-form .form-item-credit-card-code,
  form.crm-core-profile-entry-form .form-item-credit-card-code {
    width: 100% !important;
  }
}
form#ma-donate-donate-form .form-item-credit-card-type,
form.crm-core-profile-entry-form .form-item-credit-card-type,
form#ma-donate-donate-form .form-item-credit-card-card-type,
form.crm-core-profile-entry-form .form-item-credit-card-card-type,
form#ma-donate-donate-form .commerce-credit-card-expiration,
form.crm-core-profile-entry-form .commerce-credit-card-expiration {
  padding-right: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-credit-card-type,
  form.crm-core-profile-entry-form .form-item-credit-card-type,
  form#ma-donate-donate-form .form-item-credit-card-card-type,
  form.crm-core-profile-entry-form .form-item-credit-card-card-type,
  form#ma-donate-donate-form .commerce-credit-card-expiration,
  form.crm-core-profile-entry-form .commerce-credit-card-expiration {
    padding-right: 0;
  }
}
form#ma-donate-donate-form .form-item-credit-card-code,
form.crm-core-profile-entry-form .form-item-credit-card-code,
form#ma-donate-donate-form .form-item-credit-card-number,
form.crm-core-profile-entry-form .form-item-credit-card-number {
  padding-left: 10px;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-item-credit-card-code,
  form.crm-core-profile-entry-form .form-item-credit-card-code,
  form#ma-donate-donate-form .form-item-credit-card-number,
  form.crm-core-profile-entry-form .form-item-credit-card-number {
    padding-left: 0;
  }
}
form#ma-donate-donate-form .commerce-credit-card-expiration,
form.crm-core-profile-entry-form .commerce-credit-card-expiration {
  padding-top: 20px;
  position: relative;
}
form#ma-donate-donate-form .commerce-credit-card-expiration > .form-item,
form.crm-core-profile-entry-form .commerce-credit-card-expiration > .form-item,
form#ma-donate-donate-form .commerce-credit-card-expiration > .commerce-credit-card-expiration,
form.crm-core-profile-entry-form .commerce-credit-card-expiration > .commerce-credit-card-expiration {
  margin: 0;
  margin-top: 14px;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .form-type-select,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-type-select,
form#ma-donate-donate-form .commerce-credit-card-expiration .form-type-textfield,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-type-textfield {
  display: inline-block;
  vertical-align: top;
  width: auto;
  box-sizing: border-box;
  vertical-align: bottom;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .commerce-month-year-divider,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .commerce-month-year-divider {
  color: transparent;
  text-indent: -9999px;
  height: 100%;
  display: inline-block;
  padding: 9px 4px 8px;
  position: relative;
  margin: 0 8px 0 6px;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .commerce-month-year-divider:after,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .commerce-month-year-divider:after {
  content: ' ';
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%) rotate(15deg);
  width: 2px;
  background-color: #E6E6E6;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-type"],
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-type"] {
  padding-right: 10px;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-number"],
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-number"] {
  padding-left: 10px;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"],
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"],
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"],
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] {
  white-space: nowrap;
  margin-bottom: 0;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] label,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] label,
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] label,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] label {
  margin-right: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] label,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] label,
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] label,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] label,
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] select,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] select,
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] select,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] select,
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] .form-text,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-month"] .form-text,
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] .form-text,
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] .form-text {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-year"],
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-exp-year"] {
  margin-bottom: 0;
}
form#ma-donate-donate-form .commerce-credit-card-expiration > .commerce-credit-card-expiration,
form.crm-core-profile-entry-form .commerce-credit-card-expiration > .commerce-credit-card-expiration {
  display: inline-block;
  margin-top: 24px;
  padding-right: 10px;
}
form#ma-donate-donate-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"],
form.crm-core-profile-entry-form .commerce-credit-card-expiration .form-item[class*="credit-card-code"] {
  padding-left: 10px;
}
form#ma-donate-donate-form .form-actions,
form.crm-core-profile-entry-form .form-actions {
  background-color: #e5e5e5;
  padding: 20px;
  margin: 30px -30px -30px;
}
@media only screen and (max-width: 768px) {
  form#ma-donate-donate-form .form-actions,
  form.crm-core-profile-entry-form .form-actions {
    margin: 30px -20px -30px;
  }
}
form#ma-donate-donate-form .form-actions input[type="submit"],
form.crm-core-profile-entry-form .form-actions input[type="submit"] {
  padding: 12px 20px;
  display: block;
  margin-right: 0;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  form#ma-donate-donate-form .form-actions input[type="submit"],
  form.crm-core-profile-entry-form .form-actions input[type="submit"] {
    margin-right: 0;
    display: block;
    width: 100%;
  }
}
.not-logged-in.page-user.context-user #zone-preface-wrapper,
.page-user-login #zone-preface-wrapper,
.page-user-register #zone-preface-wrapper,
.page-user-password #zone-preface-wrapper,
.page-node-add #zone-preface-wrapper,
.page-node-edit #zone-preface-wrapper,
.page-newsletter-signup #zone-preface-wrapper,
.page-node-registrations #zone-preface-wrapper,
.page-user-edit #zone-preface-wrapper,
.not-logged-in.page-user.context-user #zone-content-wrapper,
.page-user-login #zone-content-wrapper,
.page-user-register #zone-content-wrapper,
.page-user-password #zone-content-wrapper,
.page-node-add #zone-content-wrapper,
.page-node-edit #zone-content-wrapper,
.page-newsletter-signup #zone-content-wrapper,
.page-node-registrations #zone-content-wrapper,
.page-user-edit #zone-content-wrapper {
  text-align: center;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .region-inner,
.page-user-login #zone-preface-wrapper .region-inner,
.page-user-register #zone-preface-wrapper .region-inner,
.page-user-password #zone-preface-wrapper .region-inner,
.page-node-add #zone-preface-wrapper .region-inner,
.page-node-edit #zone-preface-wrapper .region-inner,
.page-newsletter-signup #zone-preface-wrapper .region-inner,
.page-node-registrations #zone-preface-wrapper .region-inner,
.page-user-edit #zone-preface-wrapper .region-inner,
.not-logged-in.page-user.context-user #zone-content-wrapper .region-inner,
.page-user-login #zone-content-wrapper .region-inner,
.page-user-register #zone-content-wrapper .region-inner,
.page-user-password #zone-content-wrapper .region-inner,
.page-node-add #zone-content-wrapper .region-inner,
.page-node-edit #zone-content-wrapper .region-inner,
.page-newsletter-signup #zone-content-wrapper .region-inner,
.page-node-registrations #zone-content-wrapper .region-inner,
.page-user-edit #zone-content-wrapper .region-inner {
  max-width: 800px;
  margin: 0 auto;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links,
.page-user-login #zone-preface-wrapper #login-links,
.page-user-register #zone-preface-wrapper #login-links,
.page-user-password #zone-preface-wrapper #login-links,
.page-node-add #zone-preface-wrapper #login-links,
.page-node-edit #zone-preface-wrapper #login-links,
.page-newsletter-signup #zone-preface-wrapper #login-links,
.page-node-registrations #zone-preface-wrapper #login-links,
.page-user-edit #zone-preface-wrapper #login-links,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links,
.page-user-login #zone-content-wrapper #login-links,
.page-user-register #zone-content-wrapper #login-links,
.page-user-password #zone-content-wrapper #login-links,
.page-node-add #zone-content-wrapper #login-links,
.page-node-edit #zone-content-wrapper #login-links,
.page-newsletter-signup #zone-content-wrapper #login-links,
.page-node-registrations #zone-content-wrapper #login-links,
.page-user-edit #zone-content-wrapper #login-links {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links a,
.page-user-login #zone-preface-wrapper #login-links a,
.page-user-register #zone-preface-wrapper #login-links a,
.page-user-password #zone-preface-wrapper #login-links a,
.page-node-add #zone-preface-wrapper #login-links a,
.page-node-edit #zone-preface-wrapper #login-links a,
.page-newsletter-signup #zone-preface-wrapper #login-links a,
.page-node-registrations #zone-preface-wrapper #login-links a,
.page-user-edit #zone-preface-wrapper #login-links a,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links a,
.page-user-login #zone-content-wrapper #login-links a,
.page-user-register #zone-content-wrapper #login-links a,
.page-user-password #zone-content-wrapper #login-links a,
.page-node-add #zone-content-wrapper #login-links a,
.page-node-edit #zone-content-wrapper #login-links a,
.page-newsletter-signup #zone-content-wrapper #login-links a,
.page-node-registrations #zone-content-wrapper #login-links a,
.page-user-edit #zone-content-wrapper #login-links a {
  display: block;
  font-family: 'Lovelo Black', sans-serif;
  color: #AAAAAA;
  text-transform: none;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1;
  padding: 30px;
  background-color: #fff;
  border-bottom: 4px solid rgba(230, 230, 230, 0.5);
  white-space: nowrap;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links a:hover,
.page-user-login #zone-preface-wrapper #login-links a:hover,
.page-user-register #zone-preface-wrapper #login-links a:hover,
.page-user-password #zone-preface-wrapper #login-links a:hover,
.page-node-add #zone-preface-wrapper #login-links a:hover,
.page-node-edit #zone-preface-wrapper #login-links a:hover,
.page-newsletter-signup #zone-preface-wrapper #login-links a:hover,
.page-node-registrations #zone-preface-wrapper #login-links a:hover,
.page-user-edit #zone-preface-wrapper #login-links a:hover,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links a:hover,
.page-user-login #zone-content-wrapper #login-links a:hover,
.page-user-register #zone-content-wrapper #login-links a:hover,
.page-user-password #zone-content-wrapper #login-links a:hover,
.page-node-add #zone-content-wrapper #login-links a:hover,
.page-node-edit #zone-content-wrapper #login-links a:hover,
.page-newsletter-signup #zone-content-wrapper #login-links a:hover,
.page-node-registrations #zone-content-wrapper #login-links a:hover,
.page-user-edit #zone-content-wrapper #login-links a:hover {
  color: #ee482e;
  text-decoration: none;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links a:active,
.page-user-login #zone-preface-wrapper #login-links a:active,
.page-user-register #zone-preface-wrapper #login-links a:active,
.page-user-password #zone-preface-wrapper #login-links a:active,
.page-node-add #zone-preface-wrapper #login-links a:active,
.page-node-edit #zone-preface-wrapper #login-links a:active,
.page-newsletter-signup #zone-preface-wrapper #login-links a:active,
.page-node-registrations #zone-preface-wrapper #login-links a:active,
.page-user-edit #zone-preface-wrapper #login-links a:active,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links a:active,
.page-user-login #zone-content-wrapper #login-links a:active,
.page-user-register #zone-content-wrapper #login-links a:active,
.page-user-password #zone-content-wrapper #login-links a:active,
.page-node-add #zone-content-wrapper #login-links a:active,
.page-node-edit #zone-content-wrapper #login-links a:active,
.page-newsletter-signup #zone-content-wrapper #login-links a:active,
.page-node-registrations #zone-content-wrapper #login-links a:active,
.page-user-edit #zone-content-wrapper #login-links a:active {
  border-color: #e0275e;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links a.lt-active,
.page-user-login #zone-preface-wrapper #login-links a.lt-active,
.page-user-register #zone-preface-wrapper #login-links a.lt-active,
.page-user-password #zone-preface-wrapper #login-links a.lt-active,
.page-node-add #zone-preface-wrapper #login-links a.lt-active,
.page-node-edit #zone-preface-wrapper #login-links a.lt-active,
.page-newsletter-signup #zone-preface-wrapper #login-links a.lt-active,
.page-node-registrations #zone-preface-wrapper #login-links a.lt-active,
.page-user-edit #zone-preface-wrapper #login-links a.lt-active,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links a.lt-active,
.page-user-login #zone-content-wrapper #login-links a.lt-active,
.page-user-register #zone-content-wrapper #login-links a.lt-active,
.page-user-password #zone-content-wrapper #login-links a.lt-active,
.page-node-add #zone-content-wrapper #login-links a.lt-active,
.page-node-edit #zone-content-wrapper #login-links a.lt-active,
.page-newsletter-signup #zone-content-wrapper #login-links a.lt-active,
.page-node-registrations #zone-content-wrapper #login-links a.lt-active,
.page-user-edit #zone-content-wrapper #login-links a.lt-active {
  border-color: #e0275e;
  color: #e0275e;
  pointer-events: none;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links li,
.page-user-login #zone-preface-wrapper #login-links li,
.page-user-register #zone-preface-wrapper #login-links li,
.page-user-password #zone-preface-wrapper #login-links li,
.page-node-add #zone-preface-wrapper #login-links li,
.page-node-edit #zone-preface-wrapper #login-links li,
.page-newsletter-signup #zone-preface-wrapper #login-links li,
.page-node-registrations #zone-preface-wrapper #login-links li,
.page-user-edit #zone-preface-wrapper #login-links li,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links li,
.page-user-login #zone-content-wrapper #login-links li,
.page-user-register #zone-content-wrapper #login-links li,
.page-user-password #zone-content-wrapper #login-links li,
.page-node-add #zone-content-wrapper #login-links li,
.page-node-edit #zone-content-wrapper #login-links li,
.page-newsletter-signup #zone-content-wrapper #login-links li,
.page-node-registrations #zone-content-wrapper #login-links li,
.page-user-edit #zone-content-wrapper #login-links li,
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links > a,
.page-user-login #zone-preface-wrapper #login-links > a,
.page-user-register #zone-preface-wrapper #login-links > a,
.page-user-password #zone-preface-wrapper #login-links > a,
.page-node-add #zone-preface-wrapper #login-links > a,
.page-node-edit #zone-preface-wrapper #login-links > a,
.page-newsletter-signup #zone-preface-wrapper #login-links > a,
.page-node-registrations #zone-preface-wrapper #login-links > a,
.page-user-edit #zone-preface-wrapper #login-links > a,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links > a,
.page-user-login #zone-content-wrapper #login-links > a,
.page-user-register #zone-content-wrapper #login-links > a,
.page-user-password #zone-content-wrapper #login-links > a,
.page-node-add #zone-content-wrapper #login-links > a,
.page-node-edit #zone-content-wrapper #login-links > a,
.page-newsletter-signup #zone-content-wrapper #login-links > a,
.page-node-registrations #zone-content-wrapper #login-links > a,
.page-user-edit #zone-content-wrapper #login-links > a {
  display: table-cell;
  width: 50%;
  white-space: normal;
  vertical-align: bottom;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links li,
.page-user-login #zone-preface-wrapper #login-links li,
.page-user-register #zone-preface-wrapper #login-links li,
.page-user-password #zone-preface-wrapper #login-links li,
.page-node-add #zone-preface-wrapper #login-links li,
.page-node-edit #zone-preface-wrapper #login-links li,
.page-newsletter-signup #zone-preface-wrapper #login-links li,
.page-node-registrations #zone-preface-wrapper #login-links li,
.page-user-edit #zone-preface-wrapper #login-links li,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links li,
.page-user-login #zone-content-wrapper #login-links li,
.page-user-register #zone-content-wrapper #login-links li,
.page-user-password #zone-content-wrapper #login-links li,
.page-node-add #zone-content-wrapper #login-links li,
.page-node-edit #zone-content-wrapper #login-links li,
.page-newsletter-signup #zone-content-wrapper #login-links li,
.page-node-registrations #zone-content-wrapper #login-links li,
.page-user-edit #zone-content-wrapper #login-links li {
  padding: 0;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper #login-links li.active a,
.page-user-login #zone-preface-wrapper #login-links li.active a,
.page-user-register #zone-preface-wrapper #login-links li.active a,
.page-user-password #zone-preface-wrapper #login-links li.active a,
.page-node-add #zone-preface-wrapper #login-links li.active a,
.page-node-edit #zone-preface-wrapper #login-links li.active a,
.page-newsletter-signup #zone-preface-wrapper #login-links li.active a,
.page-node-registrations #zone-preface-wrapper #login-links li.active a,
.page-user-edit #zone-preface-wrapper #login-links li.active a,
.not-logged-in.page-user.context-user #zone-content-wrapper #login-links li.active a,
.page-user-login #zone-content-wrapper #login-links li.active a,
.page-user-register #zone-content-wrapper #login-links li.active a,
.page-user-password #zone-content-wrapper #login-links li.active a,
.page-node-add #zone-content-wrapper #login-links li.active a,
.page-node-edit #zone-content-wrapper #login-links li.active a,
.page-newsletter-signup #zone-content-wrapper #login-links li.active a,
.page-node-registrations #zone-content-wrapper #login-links li.active a,
.page-user-edit #zone-content-wrapper #login-links li.active a {
  border-color: #ee482e;
  color: #ee482e;
  pointer-events: none;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .zone-content form,
.page-user-login #zone-preface-wrapper .zone-content form,
.page-user-register #zone-preface-wrapper .zone-content form,
.page-user-password #zone-preface-wrapper .zone-content form,
.page-node-add #zone-preface-wrapper .zone-content form,
.page-node-edit #zone-preface-wrapper .zone-content form,
.page-newsletter-signup #zone-preface-wrapper .zone-content form,
.page-node-registrations #zone-preface-wrapper .zone-content form,
.page-user-edit #zone-preface-wrapper .zone-content form,
.not-logged-in.page-user.context-user #zone-content-wrapper .zone-content form,
.page-user-login #zone-content-wrapper .zone-content form,
.page-user-register #zone-content-wrapper .zone-content form,
.page-user-password #zone-content-wrapper .zone-content form,
.page-node-add #zone-content-wrapper .zone-content form,
.page-node-edit #zone-content-wrapper .zone-content form,
.page-newsletter-signup #zone-content-wrapper .zone-content form,
.page-node-registrations #zone-content-wrapper .zone-content form,
.page-user-edit #zone-content-wrapper .zone-content form {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 40px;
  text-align: left;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-item,
.page-user-login #zone-preface-wrapper .form-item,
.page-user-register #zone-preface-wrapper .form-item,
.page-user-password #zone-preface-wrapper .form-item,
.page-node-add #zone-preface-wrapper .form-item,
.page-node-edit #zone-preface-wrapper .form-item,
.page-newsletter-signup #zone-preface-wrapper .form-item,
.page-node-registrations #zone-preface-wrapper .form-item,
.page-user-edit #zone-preface-wrapper .form-item,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-item,
.page-user-login #zone-content-wrapper .form-item,
.page-user-register #zone-content-wrapper .form-item,
.page-user-password #zone-content-wrapper .form-item,
.page-node-add #zone-content-wrapper .form-item,
.page-node-edit #zone-content-wrapper .form-item,
.page-newsletter-signup #zone-content-wrapper .form-item,
.page-node-registrations #zone-content-wrapper .form-item,
.page-user-edit #zone-content-wrapper .form-item,
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-actions,
.page-user-login #zone-preface-wrapper .form-actions,
.page-user-register #zone-preface-wrapper .form-actions,
.page-user-password #zone-preface-wrapper .form-actions,
.page-node-add #zone-preface-wrapper .form-actions,
.page-node-edit #zone-preface-wrapper .form-actions,
.page-newsletter-signup #zone-preface-wrapper .form-actions,
.page-node-registrations #zone-preface-wrapper .form-actions,
.page-user-edit #zone-preface-wrapper .form-actions,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-actions,
.page-user-login #zone-content-wrapper .form-actions,
.page-user-register #zone-content-wrapper .form-actions,
.page-user-password #zone-content-wrapper .form-actions,
.page-node-add #zone-content-wrapper .form-actions,
.page-node-edit #zone-content-wrapper .form-actions,
.page-newsletter-signup #zone-content-wrapper .form-actions,
.page-node-registrations #zone-content-wrapper .form-actions,
.page-user-edit #zone-content-wrapper .form-actions,
.not-logged-in.page-user.context-user #zone-preface-wrapper .user-login-links,
.page-user-login #zone-preface-wrapper .user-login-links,
.page-user-register #zone-preface-wrapper .user-login-links,
.page-user-password #zone-preface-wrapper .user-login-links,
.page-node-add #zone-preface-wrapper .user-login-links,
.page-node-edit #zone-preface-wrapper .user-login-links,
.page-newsletter-signup #zone-preface-wrapper .user-login-links,
.page-node-registrations #zone-preface-wrapper .user-login-links,
.page-user-edit #zone-preface-wrapper .user-login-links,
.not-logged-in.page-user.context-user #zone-content-wrapper .user-login-links,
.page-user-login #zone-content-wrapper .user-login-links,
.page-user-register #zone-content-wrapper .user-login-links,
.page-user-password #zone-content-wrapper .user-login-links,
.page-node-add #zone-content-wrapper .user-login-links,
.page-node-edit #zone-content-wrapper .user-login-links,
.page-newsletter-signup #zone-content-wrapper .user-login-links,
.page-node-registrations #zone-content-wrapper .user-login-links,
.page-user-edit #zone-content-wrapper .user-login-links {
  width: 100%;
  display: inline-block;
  margin: 0;
  margin-bottom: 12px;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-item,
.page-user-login #zone-preface-wrapper .form-item,
.page-user-register #zone-preface-wrapper .form-item,
.page-user-password #zone-preface-wrapper .form-item,
.page-node-add #zone-preface-wrapper .form-item,
.page-node-edit #zone-preface-wrapper .form-item,
.page-newsletter-signup #zone-preface-wrapper .form-item,
.page-node-registrations #zone-preface-wrapper .form-item,
.page-user-edit #zone-preface-wrapper .form-item,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-item,
.page-user-login #zone-content-wrapper .form-item,
.page-user-register #zone-content-wrapper .form-item,
.page-user-password #zone-content-wrapper .form-item,
.page-node-add #zone-content-wrapper .form-item,
.page-node-edit #zone-content-wrapper .form-item,
.page-newsletter-signup #zone-content-wrapper .form-item,
.page-node-registrations #zone-content-wrapper .form-item,
.page-user-edit #zone-content-wrapper .form-item {
  margin: 0;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-item ~ .form-item,
.page-user-login #zone-preface-wrapper .form-item ~ .form-item,
.page-user-register #zone-preface-wrapper .form-item ~ .form-item,
.page-user-password #zone-preface-wrapper .form-item ~ .form-item,
.page-node-add #zone-preface-wrapper .form-item ~ .form-item,
.page-node-edit #zone-preface-wrapper .form-item ~ .form-item,
.page-newsletter-signup #zone-preface-wrapper .form-item ~ .form-item,
.page-node-registrations #zone-preface-wrapper .form-item ~ .form-item,
.page-user-edit #zone-preface-wrapper .form-item ~ .form-item,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-item ~ .form-item,
.page-user-login #zone-content-wrapper .form-item ~ .form-item,
.page-user-register #zone-content-wrapper .form-item ~ .form-item,
.page-user-password #zone-content-wrapper .form-item ~ .form-item,
.page-node-add #zone-content-wrapper .form-item ~ .form-item,
.page-node-edit #zone-content-wrapper .form-item ~ .form-item,
.page-newsletter-signup #zone-content-wrapper .form-item ~ .form-item,
.page-node-registrations #zone-content-wrapper .form-item ~ .form-item,
.page-user-edit #zone-content-wrapper .form-item ~ .form-item,
.not-logged-in.page-user.context-user #zone-preface-wrapper .field-name-field-primary-contact,
.page-user-login #zone-preface-wrapper .field-name-field-primary-contact,
.page-user-register #zone-preface-wrapper .field-name-field-primary-contact,
.page-user-password #zone-preface-wrapper .field-name-field-primary-contact,
.page-node-add #zone-preface-wrapper .field-name-field-primary-contact,
.page-node-edit #zone-preface-wrapper .field-name-field-primary-contact,
.page-newsletter-signup #zone-preface-wrapper .field-name-field-primary-contact,
.page-node-registrations #zone-preface-wrapper .field-name-field-primary-contact,
.page-user-edit #zone-preface-wrapper .field-name-field-primary-contact,
.not-logged-in.page-user.context-user #zone-content-wrapper .field-name-field-primary-contact,
.page-user-login #zone-content-wrapper .field-name-field-primary-contact,
.page-user-register #zone-content-wrapper .field-name-field-primary-contact,
.page-user-password #zone-content-wrapper .field-name-field-primary-contact,
.page-node-add #zone-content-wrapper .field-name-field-primary-contact,
.page-node-edit #zone-content-wrapper .field-name-field-primary-contact,
.page-newsletter-signup #zone-content-wrapper .field-name-field-primary-contact,
.page-node-registrations #zone-content-wrapper .field-name-field-primary-contact,
.page-user-edit #zone-content-wrapper .field-name-field-primary-contact,
.not-logged-in.page-user.context-user #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.page-user-login #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.page-user-register #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.page-user-password #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.page-node-add #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.page-node-edit #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.page-newsletter-signup #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.page-node-registrations #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.page-user-edit #zone-preface-wrapper .field-name-field-primary-contact fieldset,
.not-logged-in.page-user.context-user #zone-content-wrapper .field-name-field-primary-contact fieldset,
.page-user-login #zone-content-wrapper .field-name-field-primary-contact fieldset,
.page-user-register #zone-content-wrapper .field-name-field-primary-contact fieldset,
.page-user-password #zone-content-wrapper .field-name-field-primary-contact fieldset,
.page-node-add #zone-content-wrapper .field-name-field-primary-contact fieldset,
.page-node-edit #zone-content-wrapper .field-name-field-primary-contact fieldset,
.page-newsletter-signup #zone-content-wrapper .field-name-field-primary-contact fieldset,
.page-node-registrations #zone-content-wrapper .field-name-field-primary-contact fieldset,
.page-user-edit #zone-content-wrapper .field-name-field-primary-contact fieldset {
  margin-top: 12px;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-user-login #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-user-register #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-user-password #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-node-add #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-node-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-newsletter-signup #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-node-registrations #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-user-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.not-logged-in.page-user.context-user #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-user-login #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-user-register #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-user-password #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-node-add #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-node-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-newsletter-signup #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-node-registrations #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"],
.page-user-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] > label[for*="-form-contact-name"][for$="-0"] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-user-login #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-user-register #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-user-password #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-node-add #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-node-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-newsletter-signup #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-node-registrations #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-user-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-user-login #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-user-register #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-user-password #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-node-add #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-node-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-newsletter-signup #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-node-registrations #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.page-user-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper,
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-user-login #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-user-register #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-user-password #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-node-add #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-node-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-newsletter-signup #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-node-registrations #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-user-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-user-login #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-user-register #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-user-password #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-node-add #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-node-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-newsletter-signup #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-node-registrations #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper,
.page-user-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper {
  float: none !important;
  margin: 0 !important;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-user-login #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-user-register #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-user-password #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-node-add #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-node-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-newsletter-signup #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-node-registrations #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-user-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-user-login #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-user-register #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-user-password #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-node-add #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-node-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-newsletter-signup #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-node-registrations #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.page-user-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-given-wrapper ~ div,
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-user-login #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-user-register #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-user-password #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-node-add #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-node-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-newsletter-signup #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-node-registrations #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-user-edit #zone-preface-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-user-login #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-user-register #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-user-password #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-node-add #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-node-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-newsletter-signup #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-node-registrations #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div,
.page-user-edit #zone-content-wrapper .form-item[class*="-field-primary-contact-"] .name-family-wrapper ~ div {
  margin-top: 12px !important;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper label,
.page-user-login #zone-preface-wrapper label,
.page-user-register #zone-preface-wrapper label,
.page-user-password #zone-preface-wrapper label,
.page-node-add #zone-preface-wrapper label,
.page-node-edit #zone-preface-wrapper label,
.page-newsletter-signup #zone-preface-wrapper label,
.page-node-registrations #zone-preface-wrapper label,
.page-user-edit #zone-preface-wrapper label,
.not-logged-in.page-user.context-user #zone-content-wrapper label,
.page-user-login #zone-content-wrapper label,
.page-user-register #zone-content-wrapper label,
.page-user-password #zone-content-wrapper label,
.page-node-add #zone-content-wrapper label,
.page-node-edit #zone-content-wrapper label,
.page-newsletter-signup #zone-content-wrapper label,
.page-node-registrations #zone-content-wrapper label,
.page-user-edit #zone-content-wrapper label {
  text-align: left;
  display: block;
  margin-bottom: 6px;
  position: relative;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper label .form-required,
.page-user-login #zone-preface-wrapper label .form-required,
.page-user-register #zone-preface-wrapper label .form-required,
.page-user-password #zone-preface-wrapper label .form-required,
.page-node-add #zone-preface-wrapper label .form-required,
.page-node-edit #zone-preface-wrapper label .form-required,
.page-newsletter-signup #zone-preface-wrapper label .form-required,
.page-node-registrations #zone-preface-wrapper label .form-required,
.page-user-edit #zone-preface-wrapper label .form-required,
.not-logged-in.page-user.context-user #zone-content-wrapper label .form-required,
.page-user-login #zone-content-wrapper label .form-required,
.page-user-register #zone-content-wrapper label .form-required,
.page-user-password #zone-content-wrapper label .form-required,
.page-node-add #zone-content-wrapper label .form-required,
.page-node-edit #zone-content-wrapper label .form-required,
.page-newsletter-signup #zone-content-wrapper label .form-required,
.page-node-registrations #zone-content-wrapper label .form-required,
.page-user-edit #zone-content-wrapper label .form-required,
.not-logged-in.page-user.context-user #zone-preface-wrapper label .name-required-component-marker,
.page-user-login #zone-preface-wrapper label .name-required-component-marker,
.page-user-register #zone-preface-wrapper label .name-required-component-marker,
.page-user-password #zone-preface-wrapper label .name-required-component-marker,
.page-node-add #zone-preface-wrapper label .name-required-component-marker,
.page-node-edit #zone-preface-wrapper label .name-required-component-marker,
.page-newsletter-signup #zone-preface-wrapper label .name-required-component-marker,
.page-node-registrations #zone-preface-wrapper label .name-required-component-marker,
.page-user-edit #zone-preface-wrapper label .name-required-component-marker,
.not-logged-in.page-user.context-user #zone-content-wrapper label .name-required-component-marker,
.page-user-login #zone-content-wrapper label .name-required-component-marker,
.page-user-register #zone-content-wrapper label .name-required-component-marker,
.page-user-password #zone-content-wrapper label .name-required-component-marker,
.page-node-add #zone-content-wrapper label .name-required-component-marker,
.page-node-edit #zone-content-wrapper label .name-required-component-marker,
.page-newsletter-signup #zone-content-wrapper label .name-required-component-marker,
.page-node-registrations #zone-content-wrapper label .name-required-component-marker,
.page-user-edit #zone-content-wrapper label .name-required-component-marker {
  float: left;
  margin-right: 2px;
}
@media only screen and (max-width: 480px) {
  .not-logged-in.page-user.context-user #zone-preface-wrapper label .form-required,
  .page-user-login #zone-preface-wrapper label .form-required,
  .page-user-register #zone-preface-wrapper label .form-required,
  .page-user-password #zone-preface-wrapper label .form-required,
  .page-node-add #zone-preface-wrapper label .form-required,
  .page-node-edit #zone-preface-wrapper label .form-required,
  .page-newsletter-signup #zone-preface-wrapper label .form-required,
  .page-node-registrations #zone-preface-wrapper label .form-required,
  .page-user-edit #zone-preface-wrapper label .form-required,
  .not-logged-in.page-user.context-user #zone-content-wrapper label .form-required,
  .page-user-login #zone-content-wrapper label .form-required,
  .page-user-register #zone-content-wrapper label .form-required,
  .page-user-password #zone-content-wrapper label .form-required,
  .page-node-add #zone-content-wrapper label .form-required,
  .page-node-edit #zone-content-wrapper label .form-required,
  .page-newsletter-signup #zone-content-wrapper label .form-required,
  .page-node-registrations #zone-content-wrapper label .form-required,
  .page-user-edit #zone-content-wrapper label .form-required,
  .not-logged-in.page-user.context-user #zone-preface-wrapper label .name-required-component-marker,
  .page-user-login #zone-preface-wrapper label .name-required-component-marker,
  .page-user-register #zone-preface-wrapper label .name-required-component-marker,
  .page-user-password #zone-preface-wrapper label .name-required-component-marker,
  .page-node-add #zone-preface-wrapper label .name-required-component-marker,
  .page-node-edit #zone-preface-wrapper label .name-required-component-marker,
  .page-newsletter-signup #zone-preface-wrapper label .name-required-component-marker,
  .page-node-registrations #zone-preface-wrapper label .name-required-component-marker,
  .page-user-edit #zone-preface-wrapper label .name-required-component-marker,
  .not-logged-in.page-user.context-user #zone-content-wrapper label .name-required-component-marker,
  .page-user-login #zone-content-wrapper label .name-required-component-marker,
  .page-user-register #zone-content-wrapper label .name-required-component-marker,
  .page-user-password #zone-content-wrapper label .name-required-component-marker,
  .page-node-add #zone-content-wrapper label .name-required-component-marker,
  .page-node-edit #zone-content-wrapper label .name-required-component-marker,
  .page-newsletter-signup #zone-content-wrapper label .name-required-component-marker,
  .page-node-registrations #zone-content-wrapper label .name-required-component-marker,
  .page-user-edit #zone-content-wrapper label .name-required-component-marker {
    float: none;
  }
}
.not-logged-in.page-user.context-user #zone-preface-wrapper input[type="checkbox"],
.page-user-login #zone-preface-wrapper input[type="checkbox"],
.page-user-register #zone-preface-wrapper input[type="checkbox"],
.page-user-password #zone-preface-wrapper input[type="checkbox"],
.page-node-add #zone-preface-wrapper input[type="checkbox"],
.page-node-edit #zone-preface-wrapper input[type="checkbox"],
.page-newsletter-signup #zone-preface-wrapper input[type="checkbox"],
.page-node-registrations #zone-preface-wrapper input[type="checkbox"],
.page-user-edit #zone-preface-wrapper input[type="checkbox"],
.not-logged-in.page-user.context-user #zone-content-wrapper input[type="checkbox"],
.page-user-login #zone-content-wrapper input[type="checkbox"],
.page-user-register #zone-content-wrapper input[type="checkbox"],
.page-user-password #zone-content-wrapper input[type="checkbox"],
.page-node-add #zone-content-wrapper input[type="checkbox"],
.page-node-edit #zone-content-wrapper input[type="checkbox"],
.page-newsletter-signup #zone-content-wrapper input[type="checkbox"],
.page-node-registrations #zone-content-wrapper input[type="checkbox"],
.page-user-edit #zone-content-wrapper input[type="checkbox"],
.not-logged-in.page-user.context-user #zone-preface-wrapper input[type="radio"],
.page-user-login #zone-preface-wrapper input[type="radio"],
.page-user-register #zone-preface-wrapper input[type="radio"],
.page-user-password #zone-preface-wrapper input[type="radio"],
.page-node-add #zone-preface-wrapper input[type="radio"],
.page-node-edit #zone-preface-wrapper input[type="radio"],
.page-newsletter-signup #zone-preface-wrapper input[type="radio"],
.page-node-registrations #zone-preface-wrapper input[type="radio"],
.page-user-edit #zone-preface-wrapper input[type="radio"],
.not-logged-in.page-user.context-user #zone-content-wrapper input[type="radio"],
.page-user-login #zone-content-wrapper input[type="radio"],
.page-user-register #zone-content-wrapper input[type="radio"],
.page-user-password #zone-content-wrapper input[type="radio"],
.page-node-add #zone-content-wrapper input[type="radio"],
.page-node-edit #zone-content-wrapper input[type="radio"],
.page-newsletter-signup #zone-content-wrapper input[type="radio"],
.page-node-registrations #zone-content-wrapper input[type="radio"],
.page-user-edit #zone-content-wrapper input[type="radio"] {
  display: inline-block;
  vertical-align: top;
  margin-right: -30px;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper input[type="checkbox"] ~ label,
.page-user-login #zone-preface-wrapper input[type="checkbox"] ~ label,
.page-user-register #zone-preface-wrapper input[type="checkbox"] ~ label,
.page-user-password #zone-preface-wrapper input[type="checkbox"] ~ label,
.page-node-add #zone-preface-wrapper input[type="checkbox"] ~ label,
.page-node-edit #zone-preface-wrapper input[type="checkbox"] ~ label,
.page-newsletter-signup #zone-preface-wrapper input[type="checkbox"] ~ label,
.page-node-registrations #zone-preface-wrapper input[type="checkbox"] ~ label,
.page-user-edit #zone-preface-wrapper input[type="checkbox"] ~ label,
.not-logged-in.page-user.context-user #zone-content-wrapper input[type="checkbox"] ~ label,
.page-user-login #zone-content-wrapper input[type="checkbox"] ~ label,
.page-user-register #zone-content-wrapper input[type="checkbox"] ~ label,
.page-user-password #zone-content-wrapper input[type="checkbox"] ~ label,
.page-node-add #zone-content-wrapper input[type="checkbox"] ~ label,
.page-node-edit #zone-content-wrapper input[type="checkbox"] ~ label,
.page-newsletter-signup #zone-content-wrapper input[type="checkbox"] ~ label,
.page-node-registrations #zone-content-wrapper input[type="checkbox"] ~ label,
.page-user-edit #zone-content-wrapper input[type="checkbox"] ~ label,
.not-logged-in.page-user.context-user #zone-preface-wrapper input[type="radio"] ~ label,
.page-user-login #zone-preface-wrapper input[type="radio"] ~ label,
.page-user-register #zone-preface-wrapper input[type="radio"] ~ label,
.page-user-password #zone-preface-wrapper input[type="radio"] ~ label,
.page-node-add #zone-preface-wrapper input[type="radio"] ~ label,
.page-node-edit #zone-preface-wrapper input[type="radio"] ~ label,
.page-newsletter-signup #zone-preface-wrapper input[type="radio"] ~ label,
.page-node-registrations #zone-preface-wrapper input[type="radio"] ~ label,
.page-user-edit #zone-preface-wrapper input[type="radio"] ~ label,
.not-logged-in.page-user.context-user #zone-content-wrapper input[type="radio"] ~ label,
.page-user-login #zone-content-wrapper input[type="radio"] ~ label,
.page-user-register #zone-content-wrapper input[type="radio"] ~ label,
.page-user-password #zone-content-wrapper input[type="radio"] ~ label,
.page-node-add #zone-content-wrapper input[type="radio"] ~ label,
.page-node-edit #zone-content-wrapper input[type="radio"] ~ label,
.page-newsletter-signup #zone-content-wrapper input[type="radio"] ~ label,
.page-node-registrations #zone-content-wrapper input[type="radio"] ~ label,
.page-user-edit #zone-content-wrapper input[type="radio"] ~ label {
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  padding-left: 34px;
  margin-top: 0px;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-actions,
.page-user-login #zone-preface-wrapper .form-actions,
.page-user-register #zone-preface-wrapper .form-actions,
.page-user-password #zone-preface-wrapper .form-actions,
.page-node-add #zone-preface-wrapper .form-actions,
.page-node-edit #zone-preface-wrapper .form-actions,
.page-newsletter-signup #zone-preface-wrapper .form-actions,
.page-node-registrations #zone-preface-wrapper .form-actions,
.page-user-edit #zone-preface-wrapper .form-actions,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-actions,
.page-user-login #zone-content-wrapper .form-actions,
.page-user-register #zone-content-wrapper .form-actions,
.page-user-password #zone-content-wrapper .form-actions,
.page-node-add #zone-content-wrapper .form-actions,
.page-node-edit #zone-content-wrapper .form-actions,
.page-newsletter-signup #zone-content-wrapper .form-actions,
.page-node-registrations #zone-content-wrapper .form-actions,
.page-user-edit #zone-content-wrapper .form-actions {
  margin-top: 20px;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .form-actions .form-submit,
.page-user-login #zone-preface-wrapper .form-actions .form-submit,
.page-user-register #zone-preface-wrapper .form-actions .form-submit,
.page-user-password #zone-preface-wrapper .form-actions .form-submit,
.page-node-add #zone-preface-wrapper .form-actions .form-submit,
.page-node-edit #zone-preface-wrapper .form-actions .form-submit,
.page-newsletter-signup #zone-preface-wrapper .form-actions .form-submit,
.page-node-registrations #zone-preface-wrapper .form-actions .form-submit,
.page-user-edit #zone-preface-wrapper .form-actions .form-submit,
.not-logged-in.page-user.context-user #zone-content-wrapper .form-actions .form-submit,
.page-user-login #zone-content-wrapper .form-actions .form-submit,
.page-user-register #zone-content-wrapper .form-actions .form-submit,
.page-user-password #zone-content-wrapper .form-actions .form-submit,
.page-node-add #zone-content-wrapper .form-actions .form-submit,
.page-node-edit #zone-content-wrapper .form-actions .form-submit,
.page-newsletter-signup #zone-content-wrapper .form-actions .form-submit,
.page-node-registrations #zone-content-wrapper .form-actions .form-submit,
.page-user-edit #zone-content-wrapper .form-actions .form-submit {
  width: 100%;
  display: inline-block;
  padding: 16px 16px 12px;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .user-login-links,
.page-user-login #zone-preface-wrapper .user-login-links,
.page-user-register #zone-preface-wrapper .user-login-links,
.page-user-password #zone-preface-wrapper .user-login-links,
.page-node-add #zone-preface-wrapper .user-login-links,
.page-node-edit #zone-preface-wrapper .user-login-links,
.page-newsletter-signup #zone-preface-wrapper .user-login-links,
.page-node-registrations #zone-preface-wrapper .user-login-links,
.page-user-edit #zone-preface-wrapper .user-login-links,
.not-logged-in.page-user.context-user #zone-content-wrapper .user-login-links,
.page-user-login #zone-content-wrapper .user-login-links,
.page-user-register #zone-content-wrapper .user-login-links,
.page-user-password #zone-content-wrapper .user-login-links,
.page-node-add #zone-content-wrapper .user-login-links,
.page-node-edit #zone-content-wrapper .user-login-links,
.page-newsletter-signup #zone-content-wrapper .user-login-links,
.page-node-registrations #zone-content-wrapper .user-login-links,
.page-user-edit #zone-content-wrapper .user-login-links {
  margin-top: 10px;
  text-align: left;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .login-forgot,
.page-user-login #zone-preface-wrapper .login-forgot,
.page-user-register #zone-preface-wrapper .login-forgot,
.page-user-password #zone-preface-wrapper .login-forgot,
.page-node-add #zone-preface-wrapper .login-forgot,
.page-node-edit #zone-preface-wrapper .login-forgot,
.page-newsletter-signup #zone-preface-wrapper .login-forgot,
.page-node-registrations #zone-preface-wrapper .login-forgot,
.page-user-edit #zone-preface-wrapper .login-forgot,
.not-logged-in.page-user.context-user #zone-content-wrapper .login-forgot,
.page-user-login #zone-content-wrapper .login-forgot,
.page-user-register #zone-content-wrapper .login-forgot,
.page-user-password #zone-content-wrapper .login-forgot,
.page-node-add #zone-content-wrapper .login-forgot,
.page-node-edit #zone-content-wrapper .login-forgot,
.page-newsletter-signup #zone-content-wrapper .login-forgot,
.page-node-registrations #zone-content-wrapper .login-forgot,
.page-user-edit #zone-content-wrapper .login-forgot {
  margin-top: 12px;
}
.not-logged-in.page-user.context-user #zone-preface-wrapper .login-forgot a,
.page-user-login #zone-preface-wrapper .login-forgot a,
.page-user-register #zone-preface-wrapper .login-forgot a,
.page-user-password #zone-preface-wrapper .login-forgot a,
.page-node-add #zone-preface-wrapper .login-forgot a,
.page-node-edit #zone-preface-wrapper .login-forgot a,
.page-newsletter-signup #zone-preface-wrapper .login-forgot a,
.page-node-registrations #zone-preface-wrapper .login-forgot a,
.page-user-edit #zone-preface-wrapper .login-forgot a,
.not-logged-in.page-user.context-user #zone-content-wrapper .login-forgot a,
.page-user-login #zone-content-wrapper .login-forgot a,
.page-user-register #zone-content-wrapper .login-forgot a,
.page-user-password #zone-content-wrapper .login-forgot a,
.page-node-add #zone-content-wrapper .login-forgot a,
.page-node-edit #zone-content-wrapper .login-forgot a,
.page-newsletter-signup #zone-content-wrapper .login-forgot a,
.page-node-registrations #zone-content-wrapper .login-forgot a,
.page-user-edit #zone-content-wrapper .login-forgot a {
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px dotted  ;
  line-height: 1;
  padding-bottom: 4px;
}
.page-newsletter-signup #zone-content-wrapper form[id*="web-to-lead"] .form-item[class*="email"] + .form-item {
  margin-top: 20px;
}
.page-newsletter-signup #zone-content-wrapper form[id*="web-to-lead"] .form-item.form-type-checkbox {
  background-color: #f2f2f2;
  padding: 20px 20px;
  box-sizing: border-box;
}
.page-newsletter-signup #zone-content-wrapper form[id*="web-to-lead"] .form-item.form-type-checkbox label {
  margin-bottom: 0;
}
.page-newsletter-signup #zone-content-wrapper form[id*="web-to-lead"] .form-item.form-type-checkbox + .form-type-checkbox {
  margin-top: -20px;
}
.page-newsletter-signup #zone-content-wrapper form[id*="web-to-lead"] .form-submit {
  margin-top: 20px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.page-user.not-logged-in.context-user #login-message {
  background-color: #f2f2f2;
  padding: 12px 20px 10px;
  font-family: 'PT Sans', sans-serif;
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.page-user.not-logged-in.context-user .form-item-name .description,
.page-user.not-logged-in.context-user .form-item-pass .description {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.page-user.not-logged-in.context-user .captcha {
  background-color: #f2f2f2;
  padding: 20px;
  margin-top: 30px;
}
.page-user.not-logged-in.context-user .captcha .g-recaptcha div[style],
.page-user.not-logged-in.context-user .captcha .g-recaptcha iframe {
  margin: 0 auto;
  max-width: 100%;
}
.page-user.not-logged-in.context-user .captcha .field-prefix {
  display: block;
}
.page-user.not-logged-in.context-user .captcha .mollom-captcha-content,
.page-user.not-logged-in.context-user .captcha .mollom-image-captcha-instructions,
.page-user.not-logged-in.context-user .captcha .mollom-audio-catcha-instructions {
  display: block;
}
.page-user.not-logged-in.context-user .captcha .mollom-image-captcha-instructions,
.page-user.not-logged-in.context-user .captcha #mollom_captcha_audio,
.page-user.not-logged-in.context-user .captcha .mollom-audio-captcha-switch {
  font-family: 'PT Sans', sans-serif;
  font-size: 13.5px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.page-user.not-logged-in.context-user .captcha .mollom-audio-captcha-switch {
  font-family: 'PT Sans', sans-serif;
}
.page-user.not-logged-in.context-user .captcha #mollom_captcha_unsupported {
  font-family: 'PT Sans', sans-serif;
  font-size: 13.5px;
  display: block;
  padding: 10px;
  background-color: rgba(229, 229, 229, 0.75);
  color: #000000;
}
.page-user.not-logged-in.context-user .captcha .form-text {
  background-color: #fff;
}
/* Controls styling for newsletter sign up from blog page*/
.field-signup-from-blog-post {
  margin: 3em 0 3em 0;
}
.field-signup-from-blog-post input[type="submit"] {
  margin-top: 3em;
}
.page-node-add-workshop #zone-content-wrapper form .form-item-title,
.node-type-workshop.page-node-edit #zone-content-wrapper form .form-item-title,
.page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-professional,
.node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-professional,
.page-node-add-workshop #zone-content-wrapper form .fieldset-wrapper > [class^="field-"],
.node-type-workshop.page-node-edit #zone-content-wrapper form .fieldset-wrapper > [class^="field-"],
.page-node-add-workshop #zone-content-wrapper form .form-wrapper > [class^="field-"],
.node-type-workshop.page-node-edit #zone-content-wrapper form .form-wrapper > [class^="field-"] {
  margin-bottom: 20px;
}
.page-node-add-workshop #zone-content-wrapper form .form-item-title:last-child,
.node-type-workshop.page-node-edit #zone-content-wrapper form .form-item-title:last-child,
.page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-professional:last-child,
.node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-professional:last-child,
.page-node-add-workshop #zone-content-wrapper form .fieldset-wrapper > [class^="field-"]:last-child,
.node-type-workshop.page-node-edit #zone-content-wrapper form .fieldset-wrapper > [class^="field-"]:last-child,
.page-node-add-workshop #zone-content-wrapper form .form-wrapper > [class^="field-"]:last-child,
.node-type-workshop.page-node-edit #zone-content-wrapper form .form-wrapper > [class^="field-"]:last-child {
  margin-bottom: 0;
}
.page-node-add-workshop #zone-content-wrapper form #edit-field-conf-pref-time,
.node-type-workshop.page-node-edit #zone-content-wrapper form #edit-field-conf-pref-time {
  display: block !important;
}
.page-node-add-workshop #zone-content-wrapper form .form-item-title,
.node-type-workshop.page-node-edit #zone-content-wrapper form .form-item-title,
.page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-professional,
.node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-professional {
  padding: 20px 30px 30px;
  background-color: #f2f2f2;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  .page-node-add-workshop #zone-content-wrapper form .form-item-title,
  .node-type-workshop.page-node-edit #zone-content-wrapper form .form-item-title,
  .page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-professional,
  .node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-professional {
    padding: 20px 20px 30px;
  }
}
.page-node-add-workshop #zone-content-wrapper form .form-item-title,
.node-type-workshop.page-node-edit #zone-content-wrapper form .form-item-title {
  margin-bottom: 0;
  padding-bottom: 12px;
}
.page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-professional,
.node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-professional {
  padding-top: 0;
  margin-bottom: 30px;
}
.page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-time-slot,
.node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-time-slot,
.page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-room,
.node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-room {
  display: inline-block;
  background-color: #f2f2f2;
  width: 50%;
  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (max-width: 480px) {
  .page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-time-slot,
  .node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-time-slot,
  .page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-room,
  .node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-room {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-time-slot,
.node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-time-slot {
  padding-right: 10px;
}
.page-node-add-workshop #zone-content-wrapper form .field-name-field-conf-room,
.node-type-workshop.page-node-edit #zone-content-wrapper form .field-name-field-conf-room {
  padding-left: 10px;
}
.schedule-nav-links {
  list-style: none;
  display: table;
  width: 100%;
  border-bottom: 3px solid #f2f2f2;
  padding: 0 !important;
  margin-bottom: 40px !important;
}
@media only screen and (max-width: 480px) {
  .schedule-nav-links {
    display: block;
  }
}
.schedule-nav-links > li {
  display: table-cell;
  vertical-align: bottom;
  padding: 0 !important;
  font-size: 24px;
  line-height: 1.5;
  box-sizing: border-box;
}
@media only screen and (max-width: 480px) {
  .schedule-nav-links > li {
    display: block;
  }
}
.schedule-nav-links > li ~ li {
  border-left: 6px solid #fff;
}
@media only screen and (max-width: 480px) {
  .schedule-nav-links > li ~ li {
    border: none;
    margin-top: 3px;
  }
}
.schedule-nav-links > li a {
  font-family: 'Lovelo Black', sans-serif;
  text-decoration: none;
  padding: 12px 20px 8px;
  box-sizing: border-box;
  display: block;
  text-align: center;
  color: #666 !important;
  background: #f2f2f2;
  font-size: 18px;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.schedule-nav-links > li a:hover {
  background: #ee482e;
  color: #fff !important;
}
.schedule-nav-links > li a.active {
  background: #57354b;
  color: #fff !important;
  border-top: 6px solid #57354b;
  border-bottom: 3px solid #57354b;
  position: relative;
  bottom: -3px;
}
@media only screen and (max-width: 480px) {
  .schedule-nav-links > li a.active {
    border: none;
    bottom: auto;
  }
}
.general-tracks,
.professional-tracks {
  padding: 20px;
  background-color: #f2f2f2;
}
.general-tracks ~ form,
.professional-tracks ~ form {
  margin-top: 30px;
}
.general-tracks > .item-list,
.professional-tracks > .item-list {
  margin-top: 12px;
}
.general-tracks > .item-list ul,
.professional-tracks > .item-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@supports (columns: 2) {
  .general-tracks > .item-list ul,
  .professional-tracks > .item-list ul {
    columns: 2;
    column-gap: 20px;
  }
  @media only screen and (max-width: 480px) {
    .general-tracks > .item-list ul,
    .professional-tracks > .item-list ul {
      columns: 1;
    }
  }
}
.general-tracks > .item-list li,
.professional-tracks > .item-list li {
  display: block;
}
.general-tracks > .item-list li a,
.professional-tracks > .item-list li a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.general-tracks > .item-list li.selected a,
.professional-tracks > .item-list li.selected a {
  color: #570a3c;
  font-weight: bold;
}
.professional-tracks > label:before {
  content: ' ';
  display: inline-block;
  vertical-align: text-bottom;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background: transparent center no-repeat;
  background-image: url('../images/briefcase--purple.svg');
  background-size: contain;
}
.checkout-buttons:before,
.checkout-buttons:after {
  content: " ";
  display: table;
  clear: both;
}
.checkout-buttons > .fieldset-wrapper {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.checkout-buttons .checkout-continue,
.checkout-buttons .button-operator,
.checkout-buttons .checkout-back {
  display: block;
  width: auto;
  box-sizing: border-box;
}
.checkout-buttons .button-operator {
  padding: 10px;
  margin: 0 10px;
  display: none;
}
.field-checkout-message {
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  background-color: #f2f2f2;
  padding: 30px;
  margin-bottom: 50px;
}
.field-checkout-message > *:last-child {
  margin-bottom: 0;
}
.checkout-completion-message {
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
  background-color: #f2f2f2;
  padding: 30px;
}
.checkout-completion-message p:last-child {
  display: inline-block;
  border-top: 1px solid #ccc;
  padding-top: 16px;
  margin-bottom: 0;
  font-size: 16px;
}
body .admin.button {
  display: none;
}
body.role-administrator .admin.button {
  display: inline-block;
}
.views-exposed-widgets {
  margin: 0;
}
.tabs {
  list-style-type: none;
  padding: 0;
  margin-left: -4px;
  margin-right: -4px;
  max-width: 100%;
  box-sizing: border-box;
}
.role-Committee-member.page-node-edit.node-type-workshop .region-content .tabs {
  display: none;
}
.tabs li {
  display: inline-block;
  padding: 4px;
}
.tabs li > a {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  float: none;
  background-image: none !important;
  background: #fff;
  padding: 10px 30px;
  color: #e0275e !important;
  border: 2px solid #e0275e;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  text-shadow: 0 0 0 transparent;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  font-family: 'PT Sans', sans-serif;
  padding: 0.25em 0.5em;
}
.tabs li > a:hover {
  border-color: #890f5e !important;
  color: #890f5e !important;
  text-decoration: none;
}
.tabs li > a:focus {
  transform: translateY(2px);
  -webkit-transform: translateY(2px);
}
.tabs li > a.disabled,
.tabs li > a.cancelled {
  background: transparent !important;
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  pointer-events: none;
}
.tabs li > a.disabled:hover,
.tabs li > a.cancelled:hover,
.tabs li > a.disabled:focus,
.tabs li > a.cancelled:focus {
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  transform: none;
}
.tabs li > a.active {
  border-color: #890f5e !important;
  background-color: #890f5e !important;
  color: #fff !important;
  text-decoration: none;
  pointer-events: none;
}
.social-links,
.block-block-14 [id*="social-links"] {
  padding: 0;
  list-style: none;
  font-size: 14.5px;
  line-height: 1;
}
.social-links li,
.block-block-14 [id*="social-links"] li {
  margin-top: 12px;
}
.social-links li:first-child,
.block-block-14 [id*="social-links"] li:first-child {
  margin-top: 0;
}
.social-links a,
.block-block-14 [id*="social-links"] a {
  display: block;
}
.social-links a img,
.block-block-14 [id*="social-links"] a img {
  vertical-align: middle;
  margin-right: 4px;
}
.social-links a.icon,
.block-block-14 [id*="social-links"] a.icon {
  font-weight: bold;
}
.social-links a.icon img,
.block-block-14 [id*="social-links"] a.icon img {
  display: none;
}
.social-links a.icon:before,
.block-block-14 [id*="social-links"] a.icon:before {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  background-image: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 10px;
}
.social-links a.icon.facebook-link,
.block-block-14 [id*="social-links"] a.icon.facebook-link {
  color: #3b5998;
}
.social-links a.icon.facebook-link:before,
.block-block-14 [id*="social-links"] a.icon.facebook-link:before {
  content: ' ';
  background-image: url(../images/fb_icon.svg);
}
.social-links a.icon.facebook-link img,
.block-block-14 [id*="social-links"] a.icon.facebook-link img {
  display: none;
}
.social-links a.icon.twitter-link,
.block-block-14 [id*="social-links"] a.icon.twitter-link {
  color: #55acee;
}
.social-links a.icon.twitter-link:before,
.block-block-14 [id*="social-links"] a.icon.twitter-link:before {
  content: ' ';
  background-image: url(../images/twitter-bird_icon.svg);
}
.social-links a.icon.instagram-link,
.block-block-14 [id*="social-links"] a.icon.instagram-link {
  color: #3F719B;
}
.social-links a.icon.instagram-link:before,
.block-block-14 [id*="social-links"] a.icon.instagram-link:before {
  content: ' ';
  background-image: url(../images/ig_icon.svg);
}
.social-links a.icon.gplus-link,
.block-block-14 [id*="social-links"] a.icon.gplus-link {
  color: #dd4b39;
}
.social-links a.icon.gplus-link:before,
.block-block-14 [id*="social-links"] a.icon.gplus-link:before {
  content: ' ';
}
.social-links a.icon.enews-link:before,
.block-block-14 [id*="social-links"] a.icon.enews-link:before {
  content: ' ';
  background-image: url(../images/mail_outlines--purple.svg);
}
/* --- [ Global Styles ] --------------- */
html {
  background: #380627;
}
body {
  font-size: 100%;
  font-family: Arial, Helvetica, sans-serif;
  color: #444;
  font-family: 'PT Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  margin: 0;
}
#page {
  background: #fff;
}
#messages {
  margin-bottom: 30px;
  text-align: left;
}
#messages .messages:first-child {
  margin-top: 0;
}
#messages .messages:last-child {
  margin-bottom: 0;
}
.page-node-add-workshop #messages,
.node-type-workshop.page-node-edit #messages {
  max-width: 600px;
  margin: 0 auto;
  float: none;
  margin-bottom: 20px;
}
.block-block-16 {
  max-width: 600px;
  margin: 0 auto;
  float: none;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fffce5;
  border: 1px solid #eedd55;
  color: #884400;
  margin-bottom: 20px;
  text-align: left;
}
.block-block-16 .content *:last-child {
  margin-bottom: 0;
}
.role-Committee-member.page-node-edit.node-type-workshop .block-block-16 {
  display: none;
}
div.workbench-info-block {
  margin: 0 auto !important;
  margin-bottom: 30px !important;
  box-sizing: border-box;
  padding: 12px 20px !important;
  font-size: 14.5px !important;
  background-color: #fffce5;
  border: 1px solid #eedd55;
  color: #884400;
  text-align: left;
}
.page-node-add-workshop div.workbench-info-block,
.node-type-workshop.page-node-edit div.workbench-info-block {
  max-width: 600px;
}
.node-type-workshop.context-trans-wellness div.workbench-info-block {
  max-width: 620px;
  margin-left: 0 !important;
}
.page-node-add-workshop .vertical-tabs,
.node-type-workshop.page-node-edit .vertical-tabs {
  margin: 30px 0 0;
}
.page-node-add-workshop .vertical-tabs .vertical-tabs-list,
.node-type-workshop.page-node-edit .vertical-tabs .vertical-tabs-list {
  margin: 0 !important;
  border: none;
}
.page-node-add-workshop .vertical-tabs .vertical-tabs-list li,
.node-type-workshop.page-node-edit .vertical-tabs .vertical-tabs-list li {
  border: none;
  border-bottom: 1px solid #e5e5e5;
  background-color: transparent;
}
.page-node-add-workshop .vertical-tabs .vertical-tabs-panes,
.node-type-workshop.page-node-edit .vertical-tabs .vertical-tabs-panes {
  background-color: #f2f2f2;
}
.page-node-add-workshop .vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane,
.node-type-workshop.page-node-edit .vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane {
  background-color: #fff;
  padding: 12px 20px;
  box-sizing: border-box;
}
.page-node-add-workshop .vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane input,
.node-type-workshop.page-node-edit .vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane input,
.page-node-add-workshop .vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane textarea,
.node-type-workshop.page-node-edit .vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane textarea,
.page-node-add-workshop .vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane select,
.node-type-workshop.page-node-edit .vertical-tabs .vertical-tabs-panes fieldset.vertical-tabs-pane select {
  border-color: #ccc;
}
#region-sidebar-second .item-list ul {
  margin-top: -10px;
}
p,
ul,
ol,
dl {
  margin-top: 0;
}
.node p {
  margin-top: 16px;
  margin-bottom: 20px;
}
.node p:last-child {
  margin-bottom: 16px;
}
.node ol,
.node ul {
  list-style-position: inside;
  margin-top: 14px;
  margin-left: 20px;
  padding: 0;
}
.node ol:last-child,
.node ul:last-child {
  margin-bottom: 16px;
  list-style: none;
}
.node ol.field-files,
.node ul.field-files,
.node ol.field-topics,
.node ul.field-topics,
.node ol.field-related-content,
.node ul.field-related-content {
  margin-left: 0 !important;
  list-style: none !important;
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  font-size: 14.5px;
  line-height: 2;
}
.node ol.field-files a,
.node ul.field-files a,
.node ol.field-topics a,
.node ul.field-topics a,
.node ol.field-related-content a,
.node ul.field-related-content a,
.node ol.field-files *:before,
.node ul.field-files *:before,
.node ol.field-topics *:before,
.node ul.field-topics *:before,
.node ol.field-related-content *:before,
.node ul.field-related-content *:before,
.node ol.field-files *:after,
.node ul.field-files *:after,
.node ol.field-topics *:after,
.node ul.field-topics *:after,
.node ol.field-related-content *:after,
.node ul.field-related-content *:after {
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  font-size: 14.5px;
  line-height: 2;
}
.node ol.field-topics li,
.node ul.field-topics li {
  display: inline-block;
  font-size: 0;
}
.node ol.field-topics li:after,
.node ul.field-topics li:after {
  content: ', ';
}
.node ol.field-topics li:first-child:last-child:after,
.node ul.field-topics li:first-child:last-child:after,
.node ol.field-topics li:last-child:after,
.node ul.field-topics li:last-child:after {
  content: none;
}
.file img {
  display: none;
}
.not-front .title-wrap {
  display: block;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.not-front .title-wrap:first-child {
  padding-top: 46px;
}
.page-checkout-complete.not-front .title-wrap:first-child {
  padding-bottom: 46px;
}
.not-front .title-wrap:last-child {
  padding-bottom: 46px;
}
.not-front .title-wrap:first-child:last-child {
  padding: 46px 0;
}
.node-type-page.not-front .title-wrap:first-child:last-child,
.page-taxonomy-term.not-front .title-wrap:first-child:last-child,
.node-type-news-item.not-front .title-wrap:first-child:last-child,
.page-search.not-front .title-wrap:first-child:last-child {
  padding-bottom: 0;
}
.not-front .block-block-10 {
  text-align: center;
}
h2.block-title,
h2.view-title {
  font-size: 24px;
  line-height: 1.5;
  margin-top: 0;
}
.context-trans-wellness .view-news.view-display-id-block_2 .block-title,
.context-trans-wellness .block-block-14 .block-title,
.context-trans-wellness .view-news.view-display-id-block_2 .view-title,
.context-trans-wellness .block-block-14 .view-title,
.context-trans-wellness .view-news.view-display-id-block_2 > .title,
.context-trans-wellness .block-block-14 > .title,
.context-trans-wellness .view-news.view-display-id-block_2 > h3,
.context-trans-wellness .block-block-14 > h3,
.context-trans-wellness .view-news.view-display-id-block_2 .registration-list > h2,
.context-trans-wellness .block-block-14 .registration-list > h2 {
  color: #890f5e;
  padding-bottom: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #e0275e;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 1.5;
}
.context-trans-wellness .view-news.view-display-id-block_2 .block-title a,
.context-trans-wellness .block-block-14 .block-title a,
.context-trans-wellness .view-news.view-display-id-block_2 .view-title a,
.context-trans-wellness .block-block-14 .view-title a,
.context-trans-wellness .view-news.view-display-id-block_2 > .title a,
.context-trans-wellness .block-block-14 > .title a,
.context-trans-wellness .view-news.view-display-id-block_2 > h3 a,
.context-trans-wellness .block-block-14 > h3 a,
.context-trans-wellness .view-news.view-display-id-block_2 .registration-list > h2 a,
.context-trans-wellness .block-block-14 .registration-list > h2 a {
  color: #890f5e;
}
.context-blog #zone-content-wrapper .block-title,
.context-news #zone-content-wrapper .block-title,
.context-blog #zone-content-wrapper .view-title,
.context-news #zone-content-wrapper .view-title {
  color: #ee482e;
  line-height: 1.34;
  margin-top: 4px;
  margin-bottom: 30px;
  padding-bottom: 12px;
  border-bottom: 3px solid #e5e5e5;
}
.page-taxonomy .view-header .view-title {
  display: none;
}
.subheading {
  color: #890f5e;
  margin-top: 28px;
  margin-bottom: 0;
  line-height: 1;
}
#zone-footer .subheading {
  color: #fff;
}
.view-mode-full .field-body ~ .field-label {
  color: #890f5e;
  margin-top: 28px;
  margin-bottom: 0;
  line-height: 1;
}
.entity-commerce-order .field-label {
  color: #890f5e;
  margin-top: 28px;
  margin-bottom: 0;
  line-height: 1;
  padding-top: 30px;
  border-top: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
.entity-commerce-order .registration-list {
  margin-top: 50px;
}
.field-overview-text * {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 0.7em;
}
@media only screen and (max-width: 768px) {
  .field-overview-text {
    margin-bottom: 40px;
  }
}
.views-field-field-signature-image {
  float: left;
  clear: left;
  margin-right: 10px;
}
.hidden {
  display: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
hr {
  margin: 20px 0;
  color: #E6E6E6;
  border: none;
  border-top: 1px solid;
}
/* --- [ Tables ] --------------- */
table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14.5px;
  table-layout: fixed;
}
caption {
  padding: 10px 16px;
}
th,
td {
  padding: 10px 16px;
  border-bottom: 1px solid #E6E6E6;
  border-left: 2px solid #fff;
  overflow: hidden;
  text-overflow: ellipsis;
}
th a,
td a {
  white-space: nowrap;
}
th:first-child,
td:first-child {
  border-left: none;
}
tr:last-child th,
tr:last-child td {
  border-bottom: none;
}
th {
  background-color: #f2f2f2;
  text-align: left;
  font-size: 16px;
  color: #570a3c;
}
th.active {
  background-color: #e5e5e5;
}
th a {
  position: relative;
  display: block;
  box-sizing: border-box;
}
th a:link {
  color: #570a3c;
}
th a.active {
  color: #570a3c;
}
th a:hover {
  color: #e0275e;
}
th a[href*="sort"] {
  padding-right: 20px;
}
th a > img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
tbody td {
  vertical-align: middle;
}
table.commerce-price-formatted-components {
  border-top: 2px solid #e5e5e5;
  margin-bottom: 40px;
}
table.commerce-price-formatted-components .component-title,
table.commerce-price-formatted-components .component-total {
  font-size: 21px;
}
table.commerce-price-formatted-components .component-title {
  color: #666;
}
table.commerce-price-formatted-components .component-total {
  font-weight: bold;
  color: #ee482e;
}
.line-item-summary .line-item-total {
  font-size: 21px;
  margin-bottom: 50px;
  border-top: 2px solid #e5e5e5;
  padding: 0 16px;
  padding-top: 16px;
  margin-top: 4px;
}
.line-item-summary .line-item-total .line-item-total-label {
  font-weight: normal;
}
.line-item-summary .line-item-total .line-item-total-raw {
  font-weight: bold;
  color: #ee482e;
}
/* --- [ Calendar tile ] --------------- */
.calendar-tile,
.calendar-tile-range {
  display: block;
  background-color: #f2f2f2;
  outline: 1px solid #E6E6E6;
  width: 70px;
  height: 70px;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.not-front .calendar-tile,
.not-front .calendar-tile-range {
  background-color: #fff;
}
.calendar-tile:hover,
.calendar-tile-range:hover {
  background-color: #ee482e;
  border-color: #ec3316;
}
.calendar-tile > .calendar-tile-inner,
.calendar-tile-range > .calendar-tile-inner,
.calendar-tile > .calendar-tile-range-inner,
.calendar-tile-range > .calendar-tile-range-inner {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.calendar-tile:before,
.calendar-tile-range:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 100%;
  margin-left: -1px;
}
.calendar-tile .calendar-tile-inner {
  padding: 8px;
  box-sizing: border-box;
}
.calendar-tile .calendar-tile-dow,
.calendar-tile .calendar-tile-daynum,
.calendar-tile .calendar-tile-month {
  display: block;
  font-family: 'Lovelo Black', sans-serif;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
}
.calendar-tile .calendar-tile-dow {
  display: none;
}
.calendar-tile .calendar-tile-daynum {
  font-size: 34px;
  font-weight: normal;
  color: #570a3c;
}
.calendar-tile .calendar-tile-month {
  color: #ee482e;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.calendar-tile:hover {
  color: #fff;
}
.calendar-tile:hover .calendar-tile-daynum {
  color: #fff;
}
.calendar-tile:hover .calendar-tile-month {
  color: #570a3c;
}
.calendar-tile-range .calendar-tile-range-inner {
  padding: 8px;
  box-sizing: border-box;
}
.calendar-tile-range .date-display-start,
.calendar-tile-range .date-separator,
.calendar-tile-range .date-display-end {
  display: block;
  font-family: 'Lovelo Black', sans-serif;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
}
.calendar-tile-range .date-separator {
  height: 1px;
  background-color: #ccc;
  margin: 6px 6px 7px;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.calendar-tile-range .date-separator > span {
  display: none;
}
.calendar-tile-range .day {
  color: #570a3c;
}
.calendar-tile-range .month {
  color: #ee482e;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.calendar-tile-range:hover {
  color: #fff;
}
.calendar-tile-range:hover .date-separator {
  background-color: #fff;
  opacity: 0.3;
}
.calendar-tile-range:hover .day {
  color: #fff;
}
.calendar-tile-range:hover .month {
  color: #570a3c;
}
/* --- [ Header ] --------------- */
#section-header {
  border-top: 6px solid #890f5e;
}
#section-header .zone-branding-wrapper {
  padding: 0 20px;
}
@media only screen and (max-width: 768px) {
  #section-header .zone-branding-wrapper {
    border-bottom: 1px solid #f2f2f2;
  }
}
@media only screen and (max-width: 768px) {
  #section-header .zone-branding-wrapper {
    padding: 0;
  }
}
#section-header .region-inner > .block[id^="block-menu-block-2"],
#section-header .region-inner > .block-user-menu {
  background: #890f5e;
  max-width: 510px;
  width: auto;
  float: right;
  position: relative;
  margin-top: -6px;
}
@media only screen and (max-width: 768px) {
  #section-header .region-inner > .block[id^="block-menu-block-2"],
  #section-header .region-inner > .block-user-menu {
    display: none;
  }
}
#section-header .region-inner > .block[id^="block-menu-block-2"] .menu,
#section-header .region-inner > .block-user-menu .menu {
  display: block;
  text-align: left;
  padding: 0;
  width: 100%;
  cursor: default;
  padding-left: 13px;
  padding-right: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#section-header .region-inner > .block[id^="block-menu-block-2"] .menu li,
#section-header .region-inner > .block-user-menu .menu li {
  display: inline-block;
  margin: 0;
  padding: 0;
}
#section-header .region-inner > .block[id^="block-menu-block-2"] .menu li a,
#section-header .region-inner > .block-user-menu .menu li a,
#section-header .region-inner > .block[id^="block-menu-block-2"] .menu li span,
#section-header .region-inner > .block-user-menu .menu li span {
  color: #fff;
  display: block;
  text-align: center;
  padding: 12px 13px 11px;
  cursor: pointer;
  font-size: 14px;
}
#section-header .region-inner > .block[id^="block-menu-block-2"] .menu li.menu-title-search,
#section-header .region-inner > .block-user-menu .menu li.menu-title-search {
  float: right;
}
#section-header .region-inner > .block[id^="block-menu-block-2"] .menu li #search-helper,
#section-header .region-inner > .block-user-menu .menu li #search-helper {
  position: relative;
  z-index: 10;
  display: block;
  background-color: #ee482e;
  background-image: url(../images/mag-glass_white.svg);
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -9999em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 46px;
}
#section-header .region-inner > .block[id^="block-menu-block-2"] .menu li #search-helper.searchOpen,
#section-header .region-inner > .block-user-menu .menu li #search-helper.searchOpen {
  background-image: url(../images/x.svg);
}
#section-header .region-inner > .block[id^="block-menu-block-2"] {
  width: 510px;
}
#section-header .region-inner > .block[id^="block-menu-block-2"] .menu {
  padding-right: 0;
}
#section-header .region-inner > .block-user-menu .menu {
  text-align: center;
}
#section-header #zone-menu-wrapper {
  position: relative;
  z-index: 40;
}
#section-header #block-megamenu-main-menu #megamenu-main-menu {
  background: transparent;
  text-align: center;
  position: relative;
}
#section-header #block-megamenu-main-menu #megamenu-main-menu > li {
  height: 50px;
  line-height: 50px;
  display: inline-block;
  position: static;
}
#section-header #block-megamenu-main-menu #megamenu-main-menu > li > h2 {
  font-family: 'PT Sans', sans-serif;
  display: inline-block;
  font-size: 14.5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
#section-header #block-megamenu-main-menu #megamenu-main-menu > li > h2 > a {
  color: #fff;
  font-weight: bold;
  padding: 0 30px;
  display: block;
  height: 50px;
  cursor: pointer;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
#section-header #block-megamenu-main-menu #megamenu-main-menu > li > h2 > a:hover {
  text-decoration: none;
  background-color: rgba(87, 10, 60, 0.7);
}
#section-header #block-megamenu-main-menu #megamenu-main-menu .megamenu-bin {
  border: none !important;
  outline: none;
  width: 100% !important;
  max-width: 1200px !important;
  margin-top: -1px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#section-header #block-megamenu-main-menu #megamenu-main-menu .megamenu-bin > li {
  width: 180px;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  background: transparent;
  margin: 0;
  padding: 0;
  height: auto;
  line-height: 1.5;
}
#section-header #block-megamenu-main-menu #megamenu-main-menu .megamenu-bin > li h3 {
  background: transparent;
}
#section-header #block-megamenu-main-menu #megamenu-main-menu .megamenu-bin > li a {
  color: #fff;
  background: transparent;
}
#section-header #block-megamenu-main-menu #megamenu-main-menu .megamenu-bin > li ul {
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}
@media only screen and (max-width: 768px) {
  #section-header .top-level-nav {
    display: none;
  }
}
#section-header .top-level-nav .menu-name-main-menu .menu-title-search,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu .menu-title-search,
#section-header .top-level-nav .menu-name-main-menu .menu-title-secondary-menu,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu .menu-title-secondary-menu,
#section-header .top-level-nav .menu-name-main-menu .menu-title-callout-links,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu .menu-title-callout-links,
#section-header .top-level-nav .menu-name-main-menu .menu-title-trans-wellness-callout-links,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu .menu-title-trans-wellness-callout-links {
  display: none !important;
}
#section-header .top-level-nav .menu-name-main-menu > .menu,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu {
  display: table;
  font-size: 0;
  text-align: center;
  width: 100%;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li {
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  display: table-cell;
  font-size: 13px;
  height: 50px;
  line-height: inherit;
  position: relative;
  text-align: left;
  vertical-align: middle;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > a,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > a {
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  color: #94105e;
  cursor: pointer;
  display: block;
  font-family: 'PT Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  padding: 24px 9px;
  text-align: center;
  text-transform: uppercase;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > a:hover,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > a:hover {
  text-decoration: none;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > .menu,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu {
  -webkit-transition: all 0ms ease 250ms;
  -moz-transition: all 0ms ease 250ms;
  -o-transition: all 0ms ease 250ms;
  transition: all 0ms ease 250ms;
  background-color: white;
  border: 1px solid #e5e5e5;
  display: none;
  flex-direction: column;
  left: 0;
  line-height: 1.4;
  max-width: 220px;
  min-width: 200px;
  overflow: hidden;
  position: absolute;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > .menu:before,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu:before {
  background-color: white;
  bottom: 0;
  content: ' ';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li:hover > .menu,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li:hover > .menu,
#section-header .top-level-nav .menu-name-main-menu > .menu > li:focus > .menu,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li:focus > .menu {
  display: flex;
  max-height: auto;
  padding: 18px 24px;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li:hover > .menu:before,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li:hover > .menu:before,
#section-header .top-level-nav .menu-name-main-menu > .menu > li:focus > .menu:before,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li:focus > .menu:before,
#section-header .top-level-nav .menu-name-main-menu > .menu > li:hover > .menu:after,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li:hover > .menu:after,
#section-header .top-level-nav .menu-name-main-menu > .menu > li:focus > .menu:after,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li:focus > .menu:after {
  content: " ";
  display: table;
  clear: both;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > .menu > li,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  font-size: 15px;
  padding: 10px 0;
  position: relative;
  vertical-align: top;
  z-index: 3;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > .menu > li > a,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li > a {
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  color: #2f2f2f;
  display: block;
  font-family: 'PT Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 23px;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > .menu > li:hover > a,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li:hover > a {
  color: #94105e;
  text-decoration: none;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > .menu > li.menu-item--aside,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li.menu-item--aside {
  color: #fff;
  display: block;
  font-size: 22px;
  font-style: italic;
  left: 50%;
  line-height: 1.4;
  padding-left: 5%;
  position: absolute;
  right: 40px;
  text-transform: none;
  top: 40px;
  width: 45%;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > .menu > li > .menu,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li > .menu {
  display: none;
}
#section-header .top-level-nav .menu-name-main-menu > .menu > li > .menu > li > .menu > li > a,
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li > .menu > li > a {
  color: #fff;
  display: none;
  font-size: 14px;
  line-height: 1.4;
  padding: 5px 15px;
  text-transform: none;
}
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu {
  max-width: 100%;
}
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li {
  padding: 10px 30px;
  text-align: center;
  width: 20%;
}
@supports (display: flex) {
  #section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-around;
  }
  #section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li {
    width: auto;
  }
}
#section-header .top-level-nav .menu-name-menu-trans-wellness-menu > .menu > li:hover > .menu {
  padding: 30px 40px 25px;
  column-count: 1;
}
#section-header .toggleNav {
  display: none;
}
@media only screen and (max-width: 767px) {
  #section-header .toggleNav {
    -webkit-transition: all 0.25s ease 0ms;
    -moz-transition: all 0.25s ease 0ms;
    -o-transition: all 0.25s ease 0ms;
    transition: all 0.25s ease 0ms;
    box-sizing: border-box;
    display: inline-block;
    float: none;
    height: 80px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 80px;
  }
  #section-header .toggleNav.isClosed {
    background-color: transparent;
  }
  #section-header .toggleNav.isOpen {
    background-color: #fff;
  }
  #section-header .toggleNav .text {
    -webkit-transition: all 0.25s ease 0ms;
    -moz-transition: all 0.25s ease 0ms;
    -o-transition: all 0.25s ease 0ms;
    transition: all 0.25s ease 0ms;
    background-color: #94105e;
    display: block;
    height: 3px;
    left: 30px;
    position: absolute;
    right: 20px;
    text-indent: -9999px;
    top: 50%;
    transform: translateY(-50%);
  }
  #section-header .toggleNav .text:before,
  #section-header .toggleNav .text:after {
    -webkit-transition: all 0.25s ease 0ms;
    -moz-transition: all 0.25s ease 0ms;
    -o-transition: all 0.25s ease 0ms;
    transition: all 0.25s ease 0ms;
    background-color: #94105e;
    content: ' ';
    height: 3px;
    left: 0;
    position: absolute;
    right: 0;
    transform-origin: 50%;
    width: 100%;
  }
  #section-header .toggleNav .text:before {
    transform: rotate(0) translateY(-8px);
  }
  #section-header .toggleNav .text:after {
    transform: rotate(0) translateY(8px);
  }
  #section-header .toggleNav.isOpen .text {
    background-color: transparent;
  }
  #section-header .toggleNav.isOpen .text:before,
  #section-header .toggleNav.isOpen .text:after {
    background-color: #94105e;
  }
  #section-header .toggleNav.isOpen .text:before {
    transform: rotate(45deg) translateY(0);
  }
  #section-header .toggleNav.isOpen .text:after {
    transform: rotate(-45deg) translateY(0);
  }
}
#section-header .mobile_main_menu {
  clear: both;
  display: none;
}
@media only screen and (max-width: 768px) {
  #section-header .mobile_main_menu {
    -webkit-transition: all 0.25s ease 0ms;
    -moz-transition: all 0.25s ease 0ms;
    -o-transition: all 0.25s ease 0ms;
    transition: all 0.25s ease 0ms;
    display: inline-block;
    overflow: hidden;
    width: 100%;
  }
  #section-header .mobile_main_menu.isClosed {
    max-height: 0;
  }
  #section-header .mobile_main_menu.isOpen {
    max-height: 3000px;
  }
}
#section-header .mobile_main_menu .menu-item--aside {
  display: none;
}
#section-header .mobile_main_menu .menu-title-trans-wellness-conference a,
#section-header .mobile_main_menu .menu-title-patient-portal a,
#section-header .mobile_main_menu .menu-title-donate-now a,
#section-header .mobile_main_menu .menu-title-submit-a-workshop a,
#section-header .mobile_main_menu .menu-title-tickets--registration a,
#section-header .mobile_main_menu .menu-title-general-conference-registration- a,
#section-header .mobile_main_menu .menu-title-professional-track-registration-only- a {
  padding-left: 64px !important;
  position: relative;
}
#section-header .mobile_main_menu .menu-title-trans-wellness-conference a:before,
#section-header .mobile_main_menu .menu-title-patient-portal a:before,
#section-header .mobile_main_menu .menu-title-donate-now a:before,
#section-header .mobile_main_menu .menu-title-submit-a-workshop a:before,
#section-header .mobile_main_menu .menu-title-tickets--registration a:before,
#section-header .mobile_main_menu .menu-title-general-conference-registration- a:before,
#section-header .mobile_main_menu .menu-title-professional-track-registration-only- a:before {
  background-color: rgba(56, 6, 39, 0.3);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  bottom: 0;
  content: ' ';
  left: 0;
  position: absolute;
  top: 0;
  width: 48px;
}
#section-header .mobile_main_menu .menu-title-trans-wellness-conference a:before {
  background-image: url('./images/icon-trans-wellness--white.svg');
}
#section-header .mobile_main_menu .menu-title-patient-portal a:before {
  background-image: url('./images/icon-patient-portal--white.svg');
}
#section-header .mobile_main_menu .menu-title-donate-now a:before {
  background-image: url('./images/icon-donate--white.svg');
}
#section-header .mobile_main_menu .menu-title-submit-a-workshop a:before {
  background-image: url('./images/icon-lightbulb--white.svg');
}
#section-header .mobile_main_menu .menu-title-tickets--registration a:before {
  background-image: url('./images/icon-tickets--white.svg');
}
#section-header .mobile_main_menu .menu-title-general-conference-registration- a:before {
  background-image: url('./images/icon-tickets--white.svg');
}
#section-header .mobile_main_menu .menu-title-professional-track-registration-only- a:before {
  background-image: url('./images/briefcase.svg');
}
#section-header .mobile_main_menu .menu-title-search .nolink {
  display: none;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input {
  background-color: #570a3c;
  border: none;
  border-bottom: 1px solid #380627;
  box-sizing: border-box;
  color: #fff;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  height: auto;
  line-height: 1.7;
  min-height: 0;
  padding: 10px 16px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input::-webkit-input-placeholder {
  color: #fff;
  letter-spacing: 0.3px;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input:-moz-placeholder {
  color: #fff;
  letter-spacing: 0.3px;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input::-moz-placeholder {
  color: #fff;
  letter-spacing: 0.3px;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input:-ms-input-placeholder {
  color: #fff;
  letter-spacing: 0.3px;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input:focus {
  background-color: #ee482e;
  color: #fff;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.25);
  text-shadow: none;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input:focus:-moz-placeholder {
  color: rgba(255, 255, 255, 0.25);
  text-shadow: none;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input:focus::-moz-placeholder {
  color: rgba(255, 255, 255, 0.25);
  text-shadow: none;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni input:focus:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.25);
  text-shadow: none;
}
#section-header .mobile_main_menu .menu-title-search .block-mazzoni .form-actions {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
#section-header .mobile_main_menu .menu-name-main-menu,
#section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu {
  border-top: 1px solid #e5e5e5;
  padding-top: 20px;
}
#section-header .mobile_main_menu .menu-name-main-menu a:not(.contextual-links-trigger),
#section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu a:not(.contextual-links-trigger) {
  background-color: white;
  color: #94105e;
  display: block;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 20px;
  text-transform: uppercase;
}
#section-header .mobile_main_menu .menu-name-main-menu a:not(.contextual-links-trigger):hover,
#section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu a:not(.contextual-links-trigger):hover {
  text-decoration: none;
}
#section-header .mobile_main_menu .menu-name-main-menu a.containsToggler,
#section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu a.containsToggler {
  padding-right: 56px;
  position: relative;
}
#section-header .mobile_main_menu .menu-name-main-menu a .toggler,
#section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu a .toggler {
  display: none;
}
@media only screen and (max-width: 768px) {
  #section-header .mobile_main_menu .menu-name-main-menu a .toggler,
  #section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu a .toggler {
    align-items: center;
    background-color: white;
    bottom: 0;
    color: #94105E;
    display: flex;
    font-family: 'Font Awesome 5 Free';
    font-size: 1.5rem;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    justify-content: center;
    position: absolute;
    right: 0;
    text-rendering: auto;
    top: 0;
    white-space: nowrap;
    width: 40px;
    z-index: 20;
  }
  #section-header .mobile_main_menu .menu-name-main-menu a .toggler:before,
  #section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu a .toggler:before {
    content: '\f107';
  }
  #section-header .mobile_main_menu .menu-name-main-menu a .toggler.toggleOpen:before,
  #section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu a .toggler.toggleOpen:before {
    content: '\f106';
  }
}
#section-header .mobile_main_menu .menu-name-main-menu > .menu > li > .menu,
#section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu > .menu > li > .menu {
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  #section-header .mobile_main_menu .menu-name-main-menu > .menu > li > .menu.hasToggler,
  #section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu > .menu > li > .menu.hasToggler {
    display: none;
  }
  #section-header .mobile_main_menu .menu-name-main-menu > .menu > li > .menu.hasToggler.menuOpen,
  #section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu > .menu > li > .menu.hasToggler.menuOpen {
    display: block;
  }
}
#section-header .mobile_main_menu .menu-name-main-menu > .menu > li > .menu > li > a,
#section-header .mobile_main_menu .menu-name-menu-trans-wellness-menu > .menu > li > .menu > li > a {
  background-color: white;
  text-transform: none;
  font-weight: normal;
  padding: 10px 20px;
  color: #2F2F2F;
}
#zone-content-wrapper {
  padding: 40px 20px;
}
.page-checkout .zone-content {
  max-width: 800px;
}
/* --- [ Front ] --------------- */
.front #page-title {
  text-align: center;
  color: #ee482e;
  font-size: 28px;
  margin: 37px 0 34px;
  line-height: 1;
  letter-spacing: -0.01em;
  word-spacing: 0.1em;
}
@media only screen and (max-width: 768px) {
  .front #page-title {
    margin: 20px 0;
    padding: 0 20px;
    font-size: 24px;
    line-height: 1.2;
  }
}
.front #block-views-homepage-mosaic-block > .block-inner > .content {
  position: relative;
}
.front #block-views-homepage-mosaic-block > .block-inner > .content .view-id-homepage_mosaic {
  overflow: hidden;
}
.front #block-views-homepage-mosaic-block .person-expanded .img img {
  width: 100%;
  height: auto;
}
.front #block-views-homepage-mosaic-block .person-expanded #person-expanded {
  position: absolute;
  top: 0;
  bottom: 30px;
  z-index: 30;
  overflow: hidden;
  height: auto;
  background: #890f5e;
  display: none;
  width: calc(40% - 15px);
}
.front #block-views-homepage-mosaic-block .person-expanded #person-expanded.show-person {
  display: block;
}
.front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-0 {
  left: 0;
}
.front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-1 {
  left: 20.5%;
}
.front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-2 {
  left: 41%;
}
.front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-3 {
  left: calc(60% + 15px);
}
.front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-4 {
  right: 0;
}
@media only screen and (max-width: 768px) {
  .front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-0,
  .front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-1,
  .front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-2,
  .front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-3,
  .front #block-views-homepage-mosaic-block .person-expanded #person-expanded.person-column-4 {
    left: 20px;
    bottom: 25px;
    width: 100%;
    right: 20px;
    max-width: 100%;
    width: auto;
  }
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay {
  background: rgba(137, 15, 94, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay h2 {
  font-size: 22px;
  letter-spacing: 2px;
  max-width: 350px;
  line-height: 1.5;
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay h2 {
    font-size: 18px;
    margin: 0;
    padding-right: 32px;
  }
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .quote-wrapper {
  clear: both;
  width: 100%;
  font-size: 16px;
  line-height: 1.8;
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .quote-wrapper .link:before {
  content: "\00a0";
}
@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .quote-wrapper .quote {
    position: absolute;
    top: 36%;
    bottom: 20%;
    left: 30px;
    right: 30px;
    overflow: auto;
  }
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .quote-wrapper .quote {
    position: absolute;
    top: 28%;
    bottom: 20%;
    left: 30px;
    right: 30px;
    overflow: auto;
    font-size: 15px;
    line-height: 1.5;
    margin-top: 10px;
  }
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay a {
  color: #fff;
  font-weight: bold;
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .related {
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 15px;
  bottom: 30px;
  width: 394px;
  line-height: 1;
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .related a:after {
  content: "\00a0\00BB";
}
@media only screen and (max-width: 768px) {
  .front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .related {
    width: 100%;
    width: auto;
    right: 30px;
    left: 30px;
  }
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .close-x {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 23px;
  right: 20px;
  background: transparent url('./images/x.svg') center center / 18px 18px no-repeat;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  opacity: 0.6;
  cursor: pointer;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.front #block-views-homepage-mosaic-block .person-expanded .person-expanded-content .overlay .close-x:hover {
  opacity: 1;
}
.front #block-views-homepage-mosaic-block .people-thumbnails {
  background: #fff;
  margin: -15px 0 15px;
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row {
  width: auto;
  min-width: 100%;
  margin: 0 -15px;
  font-size: 0;
}
@media only screen and (max-width: 768px) {
  .front #block-views-homepage-mosaic-block .people-thumbnails .row {
    margin: 0;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .front #block-views-homepage-mosaic-block .people-thumbnails .row:nth-of-type(n + 2) {
    display: none;
  }
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person {
  position: relative;
  width: 20%;
  padding: 15px;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
  .front #block-views-homepage-mosaic-block .people-thumbnails .row .person {
    width: 50%;
    padding: 10px;
  }
  .front #block-views-homepage-mosaic-block .people-thumbnails .row .person:nth-of-type(n + 5) {
    display: none;
  }
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person img {
  width: 100%;
  height: auto;
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person .person-content {
  position: absolute;
  width: auto;
  height: auto;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
}
@media only screen and (max-width: 768px) {
  .front #block-views-homepage-mosaic-block .people-thumbnails .row .person .person-content {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
  }
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person .person-content .person-hover {
  width: 100%;
  height: 100%;
  background: rgba(137, 15, 94, 0.8);
  color: #fff;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person .person-content .person-hover .person-text {
  display: table;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person .person-content .person-hover .person-text .person-text-inner {
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  text-align: center;
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person .person-content .person-hover .person-text .person-text-inner .info {
  font-family: 'Lovelo Black', sans-serif;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 1.5;
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person .person-content:hover .person-hover {
  opacity: 1;
}
.front #block-views-homepage-mosaic-block .people-thumbnails .row .person-last {
  margin-right: 0;
}
.front #block-views-homepage-featured-block {
  position: relative;
  margin-top: 11px;
}
.front #block-views-homepage-featured-block .view-content {
  color: #fff;
  margin: auto;
  position: relative;
  z-index: 20;
  width: 72%;
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-featured-block .view-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
    margin: 0;
  }
}
.front #block-views-homepage-featured-block .view-content a {
  color: #fff;
}
.front #block-views-homepage-featured-block .view-content .node {
  position: relative;
  padding-left: 84px;
}
.front #block-views-homepage-featured-block .view-content .node:before {
  content: ' ';
  width: 55px;
  height: 55px;
  position: absolute;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 20px;
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-featured-block .view-content .node:before {
    content: none;
  }
}
.front #block-views-homepage-featured-block .view-content .node.node-blog-post:before {
  background-image: url(./images/write_page.svg);
}
.front #block-views-homepage-featured-block .view-content .node.node-resource:before {
  background-image: url(./images/briefcase.svg);
}
.front #block-views-homepage-featured-block .view-content .node.node-news-item:before {
  background-image: url(./images/news.svg);
}
.front #block-views-homepage-featured-block .view-content .node.node-special-event:before {
  background-image: url(./images/icon-tickets--white.svg);
}
.front #block-views-homepage-featured-block .view-content .node.node-recurring-event:before {
  background-image: url(./images/calendar.svg);
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-featured-block .view-content .node {
    padding-left: 0;
  }
}
.front #block-views-homepage-featured-block .view-content .node .field-type h4,
.front #block-views-homepage-featured-block .view-content .node > h4 {
  color: #ee482e;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 5px;
  font-family: 'PT Sans', sans-serif;
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-featured-block .view-content .node .field-type h4,
  .front #block-views-homepage-featured-block .view-content .node > h4 {
    padding: 0 40px 17px;
    position: relative;
  }
  .front #block-views-homepage-featured-block .view-content .node .field-type h4:after,
  .front #block-views-homepage-featured-block .view-content .node > h4:after {
    content: ' ';
    position: absolute;
    bottom: 6px;
    left: 20%;
    right: 20%;
    border-bottom: 1px solid rgba(224, 39, 94, 0.3);
  }
}
.front #block-views-homepage-featured-block .view-content .node .field-title {
  position: relative;
}
.front #block-views-homepage-featured-block .view-content .node .field-title h3,
.front #block-views-homepage-featured-block .view-content .node .field-title > h3 {
  font-family: 'PT Sans', sans-serif;
  margin: 0;
  text-transform: none;
  font-size: 20px;
  line-height: 1.2;
}
.front #block-views-homepage-featured-block .view-content .node .field-post-date {
  color: #e5e5e5;
  font-size: 14px;
  margin-top: 3px;
}
.front #block-views-homepage-featured-block .view-content .node-recurring-event .field-title h2 {
  font-family: 'PT Sans', sans-serif;
  color: white;
  text-transform: none;
  font-size: 20px;
  font-weight: bold;
  margin: 0px;
}
.front #block-views-homepage-featured-block .view-content .node-recurring-event .field-event-type2 {
  color: #ee482e;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 5px;
  font-family: 'PT Sans', sans-serif;
  margin: 0px !important;
}
.front #block-views-homepage-featured-block .item-list {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 60px;
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-featured-block .item-list {
    top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.front #block-views-homepage-featured-block .item-list .pager {
  height: 60px;
  padding: 0;
  background: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.front #block-views-homepage-featured-block .item-list .pager .pager-current,
.front #block-views-homepage-featured-block .item-list .pager .ajax-progress {
  display: none;
}
.front #block-views-homepage-featured-block .item-list .pager .pager-previous,
.front #block-views-homepage-featured-block .item-list .pager .pager-next {
  position: absolute;
  background: transparent;
  top: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  width: auto;
}
.front #block-views-homepage-featured-block .item-list .pager .pager-previous span,
.front #block-views-homepage-featured-block .item-list .pager .pager-next span {
  display: none;
}
.front #block-views-homepage-featured-block .item-list .pager .pager-previous a,
.front #block-views-homepage-featured-block .item-list .pager .pager-next a {
  font-family: 'PT Sans', sans-serif;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  background: rgba(56, 6, 39, 0.3);
  display: block;
  height: 60px;
  padding: 0;
  font-size: 38px;
  text-align: center;
  width: 60px;
  color: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-indent: 0;
}
.front #block-views-homepage-featured-block .item-list .pager .pager-previous a:before,
.front #block-views-homepage-featured-block .item-list .pager .pager-next a:before {
  content: none;
}
.front #block-views-homepage-featured-block .item-list .pager .pager-previous a:hover,
.front #block-views-homepage-featured-block .item-list .pager .pager-next a:hover {
  background: rgba(56, 6, 39, 0.5);
  text-decoration: none;
}
.front #block-views-homepage-featured-block .item-list .pager .pager-previous {
  left: 3.25%;
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-featured-block .item-list .pager .pager-previous {
    margin: 0;
    left: 0;
  }
}
.front #block-views-homepage-featured-block .item-list .pager .pager-next {
  right: 3.25%;
}
@media only screen and (max-width: 480px) {
  .front #block-views-homepage-featured-block .item-list .pager .pager-next {
    margin: 0;
    right: 0;
  }
}
.front #block-views-homepage-featured-block .block-inner {
  background: rgba(112, 12, 77, 0.7);
  padding: 24px 30px;
}
.front .zone-preface-wrapper {
  padding: 0 20px;
}
@media only screen and (max-width: 768px) {
  .front .zone-preface-wrapper {
    padding: 0;
  }
}
.front #section-header .zone-user-wrapper .zone-user {
  max-width: none;
}
.front #section-header .zone-user-wrapper .zone-user .region {
  margin: 0;
  width: 100%;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block {
  color: #fff;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-bottom: 6px solid #890f5e;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner {
  padding: 65px 20px;
  position: relative;
}
@media only screen and (max-width: 480px) {
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner {
    padding: 70px 40px 60px;
  }
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner:before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  z-index: 2;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .introClose {
  position: absolute;
  left: 100%;
  top: -34px;
  margin-left: 36px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  display: block;
  padding: 0;
  border-radius: 2000px;
}
@media only screen and (max-width: 1370px) {
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .introClose {
    left: auto;
    right: 0;
    top: -46px;
  }
}
@media only screen and (max-width: 480px) {
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .introClose {
    top: -50px;
    right: -20px;
  }
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .introClose:after {
  content: ' ';
  position: absolute;
  background: transparent url('../images/small-x-pink.svg') center center no-repeat;
  background-size: 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .introClose:hover:after {
  background: transparent url('../images/small-x-purple.svg') center center no-repeat;
  background-size: 10px;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .introClose .text {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-sub-heading {
  width: 93%;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading *,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-sub-heading * {
  margin: 0;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading * [style*="line-height"],
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-sub-heading * [style*="line-height"] {
  line-height: inherit !important;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading > *,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-sub-heading > * {
  margin-bottom: 24px;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading > *:last-child,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-sub-heading > *:last-child {
  margin-bottom: 0;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content h2 {
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: normal;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 32px;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading:first-child,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content h2:first-child {
  margin-bottom: 5px;
}
@media only screen and (max-width: 480px) {
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading:first-child,
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content h2:first-child {
    margin-bottom: 12px;
  }
}
@media only screen and (max-width: 480px) {
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-heading,
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content h2 {
    font-size: 22px;
    line-height: 1.25;
  }
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-sub-heading,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content p,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content li,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content dt,
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content dd {
  font-size: 20px;
  line-height: 1.19;
}
@media only screen and (max-width: 480px) {
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .field-sub-heading,
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content p,
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content li,
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content dt,
  .front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content dd {
    font-size: 18px;
    line-height: 1.58;
  }
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .button {
  margin-top: 20px;
  margin-right: 20px;
}
.front #section-header .zone-user-wrapper .block-intro-banner-block > .block-inner > .content .button:hover a {
  color: #890f5e;
  text-decoration: none;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.front #region-sidebar-first .block,
.front #region-content .block {
  margin-bottom: 30px;
}
.front #region-sidebar-first .block#block-system-main,
.front #region-content .block#block-system-main {
  margin: 0;
}
.front #region-sidebar-first .block .content,
.front #region-content .block .content {
  font-size: 14.5px;
  line-height: 1.55;
}
.front #region-sidebar-first .block:last-child,
.front #region-content .block:last-child {
  margin: 0;
}
.front #region-sidebar-first h2.block-title,
.front #region-content h2.block-title {
  color: #ee482e;
  font-size: 22px;
  margin-bottom: 27px;
  line-height: 0.9;
}
.front #region-sidebar-first h3,
.front #region-content h3 {
  font-family: 'PT Sans', sans-serif;
  color: #890f5e;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.front #region-sidebar-first .views-row,
.front #region-content .views-row {
  margin: 0 0 7px 0;
}
.front #region-sidebar-first .views-row-last,
.front #region-content .views-row-last {
  margin: 0;
}
.front #region-sidebar-first #block-search-form,
.front #region-content #block-search-form {
  margin-bottom: 34px;
}
.front #region-sidebar-first #search-block-form,
.front #region-content #search-block-form,
.front #region-sidebar-first #search-block-form--2,
.front #region-content #search-block-form--2 {
  position: relative;
}
.front #region-sidebar-first #search-block-form .form-text,
.front #region-content #search-block-form .form-text,
.front #region-sidebar-first #search-block-form--2 .form-text,
.front #region-content #search-block-form--2 .form-text {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 9px 10px 8px 40px;
  height: auto;
  min-height: 40px;
  width: 100%;
}
.front #region-sidebar-first #search-block-form .form-text.form-autocomplete,
.front #region-content #search-block-form .form-text.form-autocomplete,
.front #region-sidebar-first #search-block-form--2 .form-text.form-autocomplete,
.front #region-content #search-block-form--2 .form-text.form-autocomplete {
  background-position: 100% center;
  background-position: right 10px center;
}
.front #region-sidebar-first #search-block-form .form-actions,
.front #region-content #search-block-form .form-actions,
.front #region-sidebar-first #search-block-form--2 .form-actions,
.front #region-content #search-block-form--2 .form-actions {
  display: block;
}
.front #region-sidebar-first #search-block-form .form-actions .form-submit,
.front #region-content #search-block-form .form-actions .form-submit,
.front #region-sidebar-first #search-block-form--2 .form-actions .form-submit,
.front #region-content #search-block-form--2 .form-actions .form-submit {
  height: 40px;
  width: 40px;
  padding: 0;
  border: none;
  background: transparent url('../images/mag-glass.svg') center center no-repeat !important;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  position: relative;
  margin: 0;
  margin-top: -40px;
}
/* --- [ Not Front ] --------------- */
.view-landing-pages.view-display-id-entity_view_1 > .view-content {
  font-size: 0;
  text-align: left;
  margin: -20px -20px 0;
}
@media only screen and (max-width: 768px) {
  .view-landing-pages.view-display-id-entity_view_1 > .view-content {
    margin: -10px -10px 0;
  }
}
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row {
  font-size: 14.5px;
  display: inline-block;
  width: 33.33%;
  vertical-align: top;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row {
    width: 50%;
    padding: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row {
    width: 100%;
  }
}
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-menuimage a,
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-menuimage > .field-signature-image {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(56, 6, 39, 0.5);
}
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-menuimage a:after,
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-menuimage > .field-signature-image:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #380627;
  opacity: 0.55;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-menuimage a:hover:after,
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-menuimage > .field-signature-image:hover:after {
  opacity: 0;
}
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-menuimage img {
  width: 100%;
  height: auto;
}
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-link-title {
  margin: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  pointer-events: none;
  font-size: 24px;
  line-height: 1.5;
}
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-link-title a {
  display: inline-block;
  vertical-align: middle;
  padding: 30px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  max-width: 90%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.view-landing-pages.view-display-id-entity_view_1 > .view-content .views-row .views-field-link-title:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 1px;
  margin-left: -1px;
}
.view-mode-full .view[class*="view-display-id-entity"] .field-title h3 {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
  color: #890f5e;
}
.view-mode-full .view[class*="view-display-id-entity"] .field-title h3 > a {
  color: #890f5e;
}
@media only screen and (max-width: 768px) {
  .view-staff-profiles.view-display-id-default .view-title,
  .view-staff-profiles.view-display-id-block_1 .view-title,
  .view-staff-profiles.view-display-id-page_1 .view-title {
    text-align: center;
  }
}
.view-staff-profiles.view-display-id-default > .view-content,
.view-staff-profiles.view-display-id-block_1 > .view-content,
.view-staff-profiles.view-display-id-page_1 > .view-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
}
@media only screen and (max-width: 768px) {
  .view-staff-profiles.view-display-id-default > .view-content,
  .view-staff-profiles.view-display-id-block_1 > .view-content,
  .view-staff-profiles.view-display-id-page_1 > .view-content {
    grid-template-columns: 1fr 1fr;
  }
}
.view-staff-profiles.view-display-id-default > .view-content .views-row,
.view-staff-profiles.view-display-id-block_1 > .view-content .views-row,
.view-staff-profiles.view-display-id-page_1 > .view-content .views-row {
  font-size: 14.5px;
  display: inline-block;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser {
  padding-bottom: 0;
  border: 1px solid #9B9B9B;
  height: 100%;
}
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .field-signature-image,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .field-signature-image,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .field-signature-image,
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .field-trans-image,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .field-trans-image,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .field-trans-image,
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .group-name,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .group-name,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .group-name {
  display: block;
}
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .field-signature-image,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .field-signature-image,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .field-signature-image,
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .field-trans-image,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .field-trans-image,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .field-trans-image {
  margin-bottom: 0;
}
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .group-name,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .group-name,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .group-name {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.25rem;
}
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .group-name .field-title,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .group-name .field-title,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .group-name .field-title,
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .group-name .field-title h3,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .group-name .field-title h3,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .group-name .field-title h3,
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .group-name .field-position-or-title,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .group-name .field-position-or-title,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .group-name .field-position-or-title {
  text-align: center;
}
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .group-name .field-title h3,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .group-name .field-title h3,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .group-name .field-title h3 {
  margin-bottom: 0.5rem !important;
}
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .group-name .field-title h3 > *,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .group-name .field-title h3 > *,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .group-name .field-title h3 > * {
  color: #e0275e;
  font-family: "PT Sans";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.view-staff-profiles.view-display-id-default .node-staff-profile.view-mode-teaser .group-name .field-position-or-title,
.view-staff-profiles.view-display-id-block_1 .node-staff-profile.view-mode-teaser .group-name .field-position-or-title,
.view-staff-profiles.view-display-id-page_1 .node-staff-profile.view-mode-teaser .group-name .field-position-or-title {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.view-staff-profiles.view-display-id-default ~ .view-display-id-default {
  margin-top: 30px;
}
@media only screen and (max-width: 768px) {
  .view-staff-profiles.view-display-id-default ~ .view-display-id-default {
    margin-top: 50px;
  }
}
.view-staff-profiles.view-display-id-block_2 > .view-content {
  font-size: 0;
  text-align: left;
  margin: -15px -15px 0;
}
@media only screen and (max-width: 768px) {
  .view-staff-profiles.view-display-id-block_2 > .view-content {
    margin: -10px -10px 0;
  }
}
.view-staff-profiles.view-display-id-block_2 > .view-content .views-row {
  font-size: 14.5px;
  display: inline-block;
  vertical-align: bottom;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
  width: 33.33%;
}
@media only screen and (max-width: 768px) {
  .view-staff-profiles.view-display-id-block_2 > .view-content .views-row {
    width: 50%;
    padding: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .view-staff-profiles.view-display-id-block_2 > .view-content .views-row {
    width: 100%;
  }
}
.context-staff .viewfield-wrapper {
  margin-bottom: 30px;
}
.context-staff .viewfield-wrapper:before,
.context-staff .viewfield-wrapper:after {
  content: " ";
  display: table;
  clear: both;
}
.context-blog #page-title,
.context-news.node-type-listing-page #page-title,
.context-resources.node-type-listing-page #page-title,
.page-taxonomy-term #page-title,
.page-search #page-title,
.context-checkout #page-title,
.context-events #page-title,
.page-user #page-title,
.page-newsletter-signup #page-title {
  color: #890f5e;
  font-size: 32px;
}
.field-primary-staff-contact ~ * {
  margin: 8px 0;
  font-size: 14.5px;
  line-height: 1;
}
.field-contact-phone {
  margin: 8px 0 0;
  font-size: 14.5px;
  line-height: 1;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.field-contact-phone:before {
  content: url('../images/Phone_orange.svg');
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  width: 20px;
}
.field-contact-email {
  margin: 8px 0 0;
  font-size: 14.5px;
  line-height: 1;
  display: block;
  white-space: nowrap;
}
.field-contact-email:before {
  content: url('../images/Mail_orange.svg');
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  width: 20px;
}
.field-contact-email a {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 85%;
  vertical-align: middle;
}
.field-signature-image + .field-contact-phone,
.field-signature-image + .field-contact-email {
  margin-top: 0;
}
.node-news-item.view-mode-tile > a {
  display: block;
  position: relative;
}
.node-news-item.view-mode-tile > a .field-signature-media img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.node-news-item.view-mode-tile > a .field-trans-image,
.node-news-item.view-mode-tile > a .field-signature-image-multi {
  position: relative;
  z-index: 0;
}
.node-news-item.view-mode-tile > a .field-trans-image:after,
.node-news-item.view-mode-tile > a .field-signature-image-multi:after {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0a0107;
  opacity: 0.55;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.node-news-item.view-mode-tile > a .field-trans-image:hover:after,
.node-news-item.view-mode-tile > a .field-signature-image-multi:hover:after {
  opacity: 0;
}
.node-news-item.view-mode-tile > a .field-signature-image-multi:after {
  opacity: 0.25;
}
.node-news-item.view-mode-tile > a .group-overlay {
  background: rgba(137, 15, 94, 0.8);
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 18px 18px 14px;
  -webkit-transition: background-color 0.25s ease 0ms;
  -moz-transition: background-color 0.25s ease 0ms;
  -o-transition: background-color 0.25s ease 0ms;
  transition: background-color 0.25s ease 0ms;
}
.node-news-item.view-mode-tile > a .group-overlay .field-type h4 {
  margin: 0;
  font-size: 11px;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
}
.node-news-item.view-mode-tile > a .group-overlay .field-title h3 {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  font-size: 15px;
  line-height: 1.4;
  font-weight: bold;
  text-transform: none;
}
.node-news-item.view-mode-tile > a:hover .group-overlay,
.node-news-item.view-mode-tile > a:focus .group-overlay {
  background: #380627;
}
.node-news-item.view-mode-tile > a:hover .group-overlay .field-title,
.node-news-item.view-mode-tile > a:focus .group-overlay .field-title {
  text-decoration: underline;
}
.node-news-item.view-mode-tile > a:hover .field-trans-image:after,
.node-news-item.view-mode-tile > a:focus .field-trans-image:after,
.node-news-item.view-mode-tile > a:hover .field-signature-image-multi:after,
.node-news-item.view-mode-tile > a:focus .field-signature-image-multi:after {
  opacity: 0;
}
.node-news-item.view-mode-tile > a:hover .field-signature-image-multi:after,
.node-news-item.view-mode-tile > a:focus .field-signature-image-multi:after {
  opacity: 0;
}
.node-news-item.view-mode-teaser .field-type h4,
.node-news-item.view-mode-teaser_alt .field-type h4 {
  font-family: 'PT Sans', sans-serif;
  font-size: 13.5px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  line-height: 1;
  position: relative;
  top: -2px;
  margin: 0;
  margin-bottom: 2px;
}
.node-news-item.view-mode-teaser .field-type h4 > a,
.node-news-item.view-mode-teaser_alt .field-type h4 > a {
  color: #e0275e;
}
.node-news-item.view-mode-teaser .field-title h3,
.node-news-item.view-mode-teaser_alt .field-title h3 {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.node-news-item.view-mode-teaser .field-title h3 > a,
.node-news-item.view-mode-teaser_alt .field-title h3 > a {
  color: #890f5e;
}
.context-trans-wellness .node-news-item.view-mode-teaser .field-title h3 > a,
.context-trans-wellness .node-news-item.view-mode-teaser_alt .field-title h3 > a {
  color: #e0275e;
}
.node-news-item.view-mode-teaser .field-post-date,
.node-news-item.view-mode-teaser_alt .field-post-date {
  color: #666;
}
.node-news-item.view-mode-teaser_alt .field-signature-media {
  float: right;
  width: 30.3%;
}
.node-news-item.view-mode-teaser_alt .field-signature-media ~ * {
  margin-right: 30.3%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 40px;
}
.node-news-item.view-mode-teaser_alt .field-signature-media img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.node-news-item.view-mode-teaser_alt .more-link {
  display: inline;
  padding-left: 0.5em;
}
.node-news-item.view-mode-teaser_alt .field-post-date {
  font-family: 'PT Sans', sans-serif;
  position: relative;
  top: -2px;
  margin: 0;
  margin-bottom: 2px;
}
.node-news-item.view-mode-teaser_alt .field-title h3 {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.node-news-item.view-mode-teaser_alt .field-title h3 > a {
  color: #890f5e;
}
.node-news-item.view-mode-teaser_alt .field-post-date {
  color: #666;
}
.node-news-item.view-mode-teaser_alt .field-body {
  margin-top: 12px;
  padding-top: 2px;
}
.node-news-item.view-mode-teaser_alt .field-body p {
  line-height: 1.9;
}
.node-news-item.view-mode-teaser_alt .field-body > *:last-child {
  margin-bottom: 0;
}
.view-news.view-display-id-block_1 > .view-content {
  font-size: 0;
  text-align: left;
  margin: -15px -15px 0;
}
@media only screen and (max-width: 768px) {
  .view-news.view-display-id-block_1 > .view-content {
    margin: -10px -10px 0;
  }
}
.view-news.view-display-id-block_1 > .view-content .views-row {
  font-size: 14.5px;
  display: inline-block;
  vertical-align: bottom;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
  width: 33.33%;
}
@media only screen and (max-width: 768px) {
  .view-news.view-display-id-block_1 > .view-content .views-row {
    width: 50%;
    padding: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .view-news.view-display-id-block_1 > .view-content .views-row {
    width: 100%;
  }
}
.view-news.view-display-id-default > .view-content .views-row ~ .views-row,
.view-news.view-display-id-block_2 > .view-content .views-row ~ .views-row {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #E6E6E6;
}
.view-events.view-display-id-block_1 > .view-content {
  font-size: 0;
  margin: -15px -15px 0;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-block_1 > .view-content {
    margin: -10px -10px 0;
  }
}
.view-events.view-display-id-block_1 > .view-content > .views-row {
  font-size: 16px;
  display: inline-block;
  width: 33.33%;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-block_1 > .view-content > .views-row {
    width: 50%;
    padding: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .view-events.view-display-id-block_1 > .view-content > .views-row {
    width: 100%;
  }
}
.view-events.view-display-id-default .view-filters,
.view-events.view-display-id-entity_view_1 .view-filters {
  margin-bottom: 30px;
}
.view-events.view-display-id-default .views-exposed-widgets,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets {
  margin: 0;
  background: #f2f2f2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px;
  display: table;
  width: 100%;
  position: relative;
}
.view-events.view-display-id-default .views-exposed-widgets > *,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets > * {
  float: none;
  padding: 0;
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  font-size: 14.5px;
}
.view-events.view-display-id-default .views-exposed-widgets label,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.view-events.view-display-id-default .views-exposed-widgets .description,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .description {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-combine,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-combine,
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-field_resource_type_tid,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-field_resource_type_tid,
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-field_event_type_tid,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-field_event_type_tid {
  width: 50%;
}
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-combine .form-item,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-combine .form-item,
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item,
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-field_event_type_tid .form-item,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-field_event_type_tid .form-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px;
}
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-combine .form-item.form-type-select .chosen-container,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-combine .form-item.form-type-select .chosen-container,
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item.form-type-select .chosen-container,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item.form-type-select .chosen-container,
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-field_event_type_tid .form-item.form-type-select .chosen-container,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-field_event_type_tid .form-item.form-type-select .chosen-container {
  width: 100%;
}
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-combine .form-item.form-type-select .chosen-container-single .chosen-single,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-combine .form-item.form-type-select .chosen-container-single .chosen-single,
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item.form-type-select .chosen-container-single .chosen-single,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item.form-type-select .chosen-container-single .chosen-single,
.view-events.view-display-id-default .views-exposed-widgets .views-widget-filter-field_event_type_tid .form-item.form-type-select .chosen-container-single .chosen-single,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-widget-filter-field_event_type_tid .form-item.form-type-select .chosen-container-single .chosen-single {
  width: 100%;
}
.view-events.view-display-id-default .views-exposed-widgets #edit-field-topics-tid-wrapper,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets #edit-field-topics-tid-wrapper {
  display: none;
}
.view-events.view-display-id-default .views-exposed-widgets .views-submit-button,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-submit-button {
  float: right;
  width: 0;
  height: 0;
  position: static;
}
.view-events.view-display-id-default .views-exposed-widgets .views-submit-button input,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-submit-button input {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.view-events.view-display-id-default .views-exposed-widgets .views-submit-button .ajax-progress-throbber,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-submit-button .ajax-progress-throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
}
.view-events.view-display-id-default .views-exposed-widgets .views-submit-button .ajax-progress-throbber .throbber,
.view-events.view-display-id-entity_view_1 .views-exposed-widgets .views-submit-button .ajax-progress-throbber .throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  background-image: url(../images/white-dots.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
  height: auto;
  margin: 0;
  animation: fadeinout 750ms infinite;
}
.view-events.view-display-id-default > .view-content .views-row ~ .views-row,
.view-events.view-display-id-entity_view_1 > .view-content .views-row ~ .views-row {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #E6E6E6;
}
.view-events.view-display-id-sliding_accordion .field-event-date,
.view-events.view-display-id-sliding_accordion .views-field[class*="event-date"] {
  font-weight: bold;
  text-transform: uppercase;
}
.view-events.view-display-id-sliding_accordion .field-title,
.view-events.view-display-id-sliding_accordion .views-field[class*="field-title"] {
  font-weight: bold;
  color: #e0275e;
}
.view-events.view-display-id-sliding_accordion .field-event-type2,
.view-events.view-display-id-sliding_accordion .views-field[class*="event-type"] {
  font-size: 12px;
  line-height: 1.2;
}
.view-events.view-display-id-sliding_accordion .field-body,
.view-events.view-display-id-sliding_accordion .views-field[class*="field-body"] {
  margin-top: 12px;
  line-height: 1.5;
}
.view-events.view-display-id-sliding_accordion .field-body .more-link,
.view-events.view-display-id-sliding_accordion .views-field[class*="field-body"] .more-link {
  display: block;
  font-weight: bold;
  margin-top: 8px;
}
.view-events.view-display-id-sliding_accordion .field-notification-text {
  font-weight: bold;
  margin-top: 12px;
  letter-spacing: 0.1px;
}
.view-events.view-display-id-sliding_accordion .field-notification-text > *:last-child {
  margin-bottom: 4px;
}
.view-events.view-display-id-sliding_accordion .grouping {
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.view-events.view-display-id-sliding_accordion .grouping ~ .grouping {
  margin-top: 30px;
}
.view-events.view-display-id-sliding_accordion .grouping .group-header {
  text-align: center;
  padding: 25px 16px 24px;
}
.view-events.view-display-id-sliding_accordion .grouping .group-header h3 {
  margin: 0 !important;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row {
  margin: 0 !important;
  font-size: 0;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row:nth-child(odd) {
  background-color: #f2f2f2;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row > * {
  font-size: 14.5px;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  float: none !important;
  padding: 24px 16px;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.left {
  width: 12.75%;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.left {
    width: 25%;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.middle {
  width: 67.5%;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.middle {
    width: 75%;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.right {
  width: 19.75%;
  text-align: right;
  padding-top: 24px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.right {
    width: 75%;
    margin-left: 25%;
    margin-top: -12px;
    text-align: left;
    padding-top: 0;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.right .button {
  background: transparent !important;
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  pointer-events: none;
  width: auto;
  box-sizing: border-box;
  padding: 4px 8px;
  margin-top: -4px;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.right .button:hover,
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.right .button:focus {
  border-color: #666 !important;
  color: #666 !important;
  opacity: 0.5;
  transform: none;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row .views-fieldset.right .button {
    margin-top: 0;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi {
  padding-right: 80px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi {
    padding-right: 55px;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .views-fieldset.left {
  width: 14.25%;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .views-fieldset.left {
    width: 25%;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .views-fieldset.middle {
  width: 67.5%;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .views-fieldset.middle {
    width: 75%;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .views-fieldset.right {
  width: 18.25%;
}
@media only screen and (max-width: 768px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .views-fieldset.right {
    width: 75%;
    margin-left: 25%;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .heidi {
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  overflow: hidden;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .heidi.isClosed {
  max-height: 0;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .heidi.isOpen {
  max-height: 2000px;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .heidiToggle {
  width: 80px;
  height: 44px;
  position: absolute;
  right: 0;
  top: 36px;
  transform: scale3d(1, 1, 1) translateY(-50%);
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  border: none;
  padding: 0;
  text-indent: -9999px;
  background-color: transparent;
  background-image: url(../images/down-caret--orange.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 480px) {
  .view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .heidiToggle {
    width: 55px;
  }
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .heidiToggle:focus {
  outline: none;
}
.view-events.view-display-id-sliding_accordion .grouping .group-content .views-row.containsHeidi .heidiToggle.isOpen {
  transform: scale3d(1, -1, 1) translateY(50%);
}
@media only screen and (max-width: 480px) {
  .view-events.view-display-id-sliding_accordion .owl-item .grouping .group-header {
    padding: 25px 55px 24px;
  }
}
.view-events .view-footer {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  text-transform: none;
  background: #E6E6E6;
  border: 1px solid #ccc;
  border-top: none;
  margin-top: -1px;
  z-index: 2;
  position: relative;
}
.view-events .view-footer a {
  padding: 10px 16px;
  display: block;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.view-events .view-footer a:hover {
  background: #ee482e;
  color: #fff;
  text-decoration: none;
}
.view-transhealth-schedule .group-header {
  background-color: #f2f2f2;
  color: #444;
  box-sizing: border-box;
  font-size: 14.5px;
  letter-spacing: 0.05em;
  line-height: 1.5;
  padding: 10px 16px 9px;
  margin-bottom: 20px;
}
.view-transhealth-schedule .group-header h3 {
  font-size: inherit;
  color: inherit;
  margin: 0;
}
.view-transhealth-schedule [class*="field-conf-track-"] {
  font-weight: bold;
  text-transform: uppercase;
  color: #666;
}
.view-transhealth-schedule h3.title {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.view-transhealth-schedule h3.title > a {
  color: #890f5e;
}
.view-transhealth-schedule .field-conf-room {
  font-weight: bold;
  text-transform: uppercase;
}
.view-transhealth-schedule.view-display-id-page .views-row:before,
.view-transhealth-schedule.view-display-id-page .views-row:after {
  content: " ";
  display: table;
  clear: both;
}
.view-transhealth-schedule.view-display-id-page .group-content > .views-row {
  display: table;
  width: 100%;
}
.view-transhealth-schedule.view-display-id-page .group-content > .views-row .field-conf-track-prof:before {
  content: ' ';
  display: inline-block;
  vertical-align: text-bottom;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  background: transparent center no-repeat;
  background-image: url('../images/briefcase--gray.svg');
  background-size: contain;
  opacity: 0.8;
}
.view-transhealth-schedule.view-display-id-page .group-content > .views-row > * {
  display: table-cell;
  vertical-align: middle;
}
.view-transhealth-schedule.view-display-id-page .group-content > .views-row ~ .views-row {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #E6E6E6;
}
.view-transhealth-schedule.view-display-id-page .group-content > .views-row .views-fieldset {
  box-sizing: border-box;
}
.view-transhealth-schedule.view-display-id-page .group-content > .views-row .views-fieldset.left,
.view-transhealth-schedule.view-display-id-page .group-content > .views-row .views-fieldset.right {
  float: none;
}
.view-transhealth-schedule.view-display-id-page .group-content > .views-row .views-fieldset.left {
  width: 90%;
}
.view-transhealth-schedule.view-display-id-page .group-content > .views-row .views-fieldset.right {
  width: 10%;
  padding-left: 20px;
  text-align: right;
}
.view-sponsorship-products .views-fieldset.text {
  display: inline-block;
  width: 75%;
  box-sizing: border-box;
  padding-right: 20px;
  vertical-align: middle;
}
.view-sponsorship-products .views-field-add-to-cart-form {
  display: inline-block;
  width: 25%;
  box-sizing: border-box;
  vertical-align: middle;
  text-align: right;
}
.view-sponsorship-products .views-field-add-to-cart-form .form-wrapper[id*="edit-line-item"] input {
  margin-bottom: 12px;
}
.view-sponsorship-products .views-field-add-to-cart-form .form-submit {
  width: 100%;
}
.view-sponsorship-products .field-type h4 {
  font-family: 'PT Sans', sans-serif;
  font-size: 13.5px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  line-height: 1;
  position: relative;
  top: -2px;
  margin: 0;
  margin-bottom: 2px;
}
.view-sponsorship-products .field-type h4 > a {
  color: #e0275e;
}
.view-sponsorship-products h4,
.view-sponsorship-products .views-field-title {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.view-sponsorship-products h4 > a,
.view-sponsorship-products .views-field-title > a,
.view-sponsorship-products h4 > span,
.view-sponsorship-products .views-field-title > span {
  color: #890f5e;
}
.view-sponsorship-products .field-event-time,
.view-sponsorship-products .field-post-date {
  color: #666;
}
.view-sponsorship-products .field-notification-text {
  margin: 0;
}
.view-sponsorship-products .field-notification-text * {
  margin: 0;
}
.view-sponsorship-products.view-display-id-entity_view_1 > .view-content .views-row {
  font-size: 0;
}
.view-sponsorship-products.view-display-id-entity_view_1 > .view-content .views-row > * {
  font-size: 14.5px;
  line-height: 1.9;
}
.view-sponsorship-products.view-display-id-entity_view_1 > .view-content .views-row ~ .views-row {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #E6E6E6;
}
.view-transhealth-dashboards.view-id-transhealth_dashboards ~ .view-transhealth-dashboards {
  margin-top: 50px;
}
.view-transhealth-dashboards.view-id-transhealth_dashboards .view-filters {
  margin-bottom: 30px;
}
.view-transhealth-dashboards.view-id-transhealth_dashboards th,
.view-transhealth-dashboards.view-id-transhealth_dashboards td {
  padding: 16px;
}
.view-transhealth-dashboards.view-id-transhealth_dashboards tbody a {
  white-space: normal;
}
.view-transhealth-dashboards.view-id-transhealth_dashboards tbody [class*="field-conf-track"] {
  font-family: 'PT Sans', sans-serif;
  font-size: 13.5px;
  letter-spacing: 0.03em;
  line-height: 1.34;
  position: relative;
  margin: 0;
  margin-bottom: 2px;
  text-transform: uppercase;
  font-weight: bold;
  color: #e0275e;
}
.view-transhealth-dashboards.view-id-transhealth_dashboards tbody h6 {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 6px;
  margin-bottom: 3px;
  line-height: 1.2;
}
.view-transhealth-dashboards.view-id-transhealth_dashboards tbody h6 > a {
  color: #890f5e;
}
.node-resource.view-mode-tile > a {
  display: block;
  position: relative;
}
.node-resource.view-mode-tile > a .field-signature-media img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.node-resource.view-mode-tile > a .field-trans-image {
  position: relative;
  z-index: 0;
}
.node-resource.view-mode-tile > a .field-trans-image:after {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0a0107;
  opacity: 0.55;
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
}
.node-resource.view-mode-tile > a .field-trans-image:hover:after {
  opacity: 0;
}
.node-resource.view-mode-tile > a .group-overlay {
  background: rgba(137, 15, 94, 0.8);
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 18px 18px 14px;
  -webkit-transition: background-color 0.25s ease 0ms;
  -moz-transition: background-color 0.25s ease 0ms;
  -o-transition: background-color 0.25s ease 0ms;
  transition: background-color 0.25s ease 0ms;
}
.node-resource.view-mode-tile > a .group-overlay .field-type h4 {
  margin: 0;
  font-size: 11px;
  font-family: 'PT Sans', sans-serif;
  text-transform: uppercase;
}
.node-resource.view-mode-tile > a .group-overlay .field-title h3 {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
  font-size: 15px;
  line-height: 1.4;
  font-weight: bold;
  text-transform: none;
}
.node-resource.view-mode-tile > a:hover .group-overlay,
.node-resource.view-mode-tile > a:focus .group-overlay {
  background: #380627;
}
.node-resource.view-mode-tile > a:hover .group-overlay .field-title,
.node-resource.view-mode-tile > a:focus .group-overlay .field-title {
  text-decoration: underline;
}
.node-resource.view-mode-tile > a:hover .field-trans-image:after,
.node-resource.view-mode-tile > a:focus .field-trans-image:after {
  opacity: 0;
}
.node-resource.view-mode-teaser .field-signature-media {
  float: right;
  width: 30.3%;
}
.node-resource.view-mode-teaser .field-signature-media ~ * {
  margin-right: 30.3%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 40px;
}
.node-resource.view-mode-teaser .field-signature-media img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.node-resource.view-mode-teaser .field-type h4 {
  font-family: 'PT Sans', sans-serif;
  font-size: 13.5px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  line-height: 1;
  position: relative;
  top: -2px;
  margin: 0;
  margin-bottom: 2px;
}
.node-resource.view-mode-teaser .field-type h4 > a {
  color: #e0275e;
}
.node-resource.view-mode-teaser .field-title h3 {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.node-resource.view-mode-teaser .field-title h3 > a {
  color: #890f5e;
}
.node-resource.view-mode-teaser .field-post-date {
  color: #666;
}
.node-resource.view-mode-teaser .field-body {
  margin-top: 12px;
  padding-top: 2px;
}
.node-resource.view-mode-teaser .field-body p {
  line-height: 1.9;
}
.node-resource.view-mode-teaser .field-body > *:last-child {
  margin-bottom: 0;
}
.view-resources.view-display-id-block_2 > .view-content,
.view-resources.view-display-id-block_1 > .view-content {
  font-size: 0;
  text-align: left;
  margin: -15px -15px 0;
}
@media only screen and (max-width: 768px) {
  .view-resources.view-display-id-block_2 > .view-content,
  .view-resources.view-display-id-block_1 > .view-content {
    margin: -10px -10px 0;
  }
}
.view-resources.view-display-id-block_2 > .view-content .views-row,
.view-resources.view-display-id-block_1 > .view-content .views-row {
  font-size: 14.5px;
  display: inline-block;
  vertical-align: bottom;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
  width: 33.33%;
}
@media only screen and (max-width: 768px) {
  .view-resources.view-display-id-block_2 > .view-content .views-row,
  .view-resources.view-display-id-block_1 > .view-content .views-row {
    width: 50%;
    padding: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .view-resources.view-display-id-block_2 > .view-content .views-row,
  .view-resources.view-display-id-block_1 > .view-content .views-row {
    width: 100%;
  }
}
.view-resources.view-display-id-default .view-filters {
  margin-bottom: 30px;
}
.view-resources.view-display-id-default .views-exposed-widgets {
  margin: 0;
  background: #f2f2f2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px;
  display: table;
  width: 100%;
  position: relative;
}
.view-resources.view-display-id-default .views-exposed-widgets > * {
  float: none;
  padding: 0;
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  font-size: 14.5px;
}
.view-resources.view-display-id-default .views-exposed-widgets label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.view-resources.view-display-id-default .views-exposed-widgets .description {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.view-resources.view-display-id-default .views-exposed-widgets .views-widget-filter-combine,
.view-resources.view-display-id-default .views-exposed-widgets .views-widget-filter-field_resource_type_tid {
  width: 50%;
}
.view-resources.view-display-id-default .views-exposed-widgets .views-widget-filter-combine .form-item,
.view-resources.view-display-id-default .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 6px;
}
.view-resources.view-display-id-default .views-exposed-widgets .views-widget-filter-combine .form-item.form-type-select .chosen-container,
.view-resources.view-display-id-default .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item.form-type-select .chosen-container {
  width: 100%;
}
.view-resources.view-display-id-default .views-exposed-widgets .views-widget-filter-combine .form-item.form-type-select .chosen-container-single .chosen-single,
.view-resources.view-display-id-default .views-exposed-widgets .views-widget-filter-field_resource_type_tid .form-item.form-type-select .chosen-container-single .chosen-single {
  width: 100%;
}
.view-resources.view-display-id-default .views-exposed-widgets #edit-field-topics-tid-wrapper {
  display: none;
}
.view-resources.view-display-id-default .views-exposed-widgets .views-submit-button {
  float: right;
  width: 0;
  height: 0;
  position: static;
}
.view-resources.view-display-id-default .views-exposed-widgets .views-submit-button input {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.view-resources.view-display-id-default .views-exposed-widgets .views-submit-button .ajax-progress-throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
}
.view-resources.view-display-id-default .views-exposed-widgets .views-submit-button .ajax-progress-throbber .throbber {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  background-image: url(../images/white-dots.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
  height: auto;
  margin: 0;
  animation: fadeinout 750ms infinite;
}
.view-resources.view-display-id-default > .view-content .views-row ~ .views-row {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #E6E6E6;
}
.view-taxonomy-term.view-display-id-page > .view-content {
  font-size: 0;
  text-align: left;
  margin: -15px -15px 0;
}
.view-taxonomy-term.view-display-id-page > .view-content .views-row {
  font-size: 14.5px;
  display: inline-block;
  vertical-align: bottom;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
  width: 33.33%;
}
.view-taxonomy-term.view-display-id-default > .view-content .views-row > * {
  max-width: 620px;
}
.view-taxonomy-term.view-display-id-default > .view-content .views-row ~ .views-row {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #E6E6E6;
}
.view-taxonomy-term.view-display-id-default .field-title h3 {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.view-taxonomy-term.view-display-id-default .field-title h3 > a {
  color: #890f5e;
}
.view-taxonomy-term.view-display-id-default .field-body {
  margin: 0;
  margin-top: 12px;
  padding-top: 2px;
}
.view-taxonomy-term.view-display-id-default .field-body p {
  line-height: 1.9;
}
.view-taxonomy-term.view-display-id-default .field-body > *:last-child {
  margin-bottom: 0;
}
.view-taxonomy-term .node-staff-profile.view-mode-teaser {
  font-size: 0;
  padding-bottom: 0;
}
.view-taxonomy-term .node-staff-profile.view-mode-teaser .field-signature-image,
.view-taxonomy-term .node-staff-profile.view-mode-teaser .field-trans-image,
.view-taxonomy-term .node-staff-profile.view-mode-teaser .group-name {
  display: inline-block;
  vertical-align: top;
}
.view-taxonomy-term .node-staff-profile.view-mode-teaser .field-signature-image,
.view-taxonomy-term .node-staff-profile.view-mode-teaser .field-trans-image {
  width: 25%;
  margin-bottom: 0;
}
.view-taxonomy-term .node-staff-profile.view-mode-teaser .group-name {
  width: 75%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 14px;
  padding-top: 8%;
}
.view-taxonomy-term .node-staff-profile.view-mode-teaser .group-name .field-title,
.view-taxonomy-term .node-staff-profile.view-mode-teaser .group-name .field-title h3,
.view-taxonomy-term .node-staff-profile.view-mode-teaser .group-name .field-position-or-title {
  text-align: left;
}
.page-search .search-form .container-inline {
  letter-spacing: -1em;
}
.page-search .search-form .container-inline > .form-item {
  letter-spacing: normal;
  margin-right: 16px;
}
@media only screen and (max-width: 768px) {
  .page-search .search-form .container-inline > .form-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.page-search .search-form .container-inline > * {
  display: inline-block;
  vertical-align: bottom;
}
@media only screen and (max-width: 768px) {
  .page-search .search-form .container-inline .form-submit {
    width: 100%;
  }
}
.page-search .search-form .search-advanced {
  max-width: 620px;
  margin: 0;
  margin-top: 18px;
}
.page-search .search-form ~ .node {
  margin-top: 34px;
  padding-top: 30px;
  border-top: 1px solid #E6E6E6;
}
.page-search .search-form ~ .node > * {
  max-width: 620px;
}
.page-search .field-title h3 {
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.page-search .field-title h3 > a {
  color: #890f5e;
}
.not-front .region-sidebar-first .block .content {
  background: #f2f2f2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 30px;
}
@media only screen and (max-width: 768px) {
  .not-front .region-sidebar-first .block .content.navRevealPane {
    max-height: 2000px;
    overflow: hidden;
    transform-origin: 0 0;
    transform: scale3d(1, 1, 1);
    transition: transform 150ms ease-out 0ms, max-height 0ms linear 150ms;
  }
  .not-front .region-sidebar-first .block .content.navRevealPane.isOpen {
    transition: transform 150ms ease-out 0ms, max-height 0ms linear 0ms;
  }
  .not-front .region-sidebar-first .block .content.navRevealPane.isClosed {
    max-height: 0;
    transform: scale3d(1, 0, 1);
  }
}
.not-front .region-sidebar-first .block .content h3 {
  margin-bottom: 12px;
  margin-top: 12px;
}
.not-front .region-sidebar-first .block .content .field-type > *:first-child {
  margin-top: 0;
}
.not-front .region-sidebar-first .block .content .field-type h3 {
  font-family: 'PT Sans', sans-serif;
  font-size: 14.5px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.not-front .region-sidebar-first .block .content .field-type h3 > a {
  color: #e0275e;
}
.not-front .region-sidebar-first .block .content .field-post-date {
  color: #666;
}
.not-front .region-sidebar-first .block .content h3.field-label {
  font-family: 'PT Sans', sans-serif;
  font-size: 14.5px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  line-height: 2;
  color: #890f5e;
}
.not-front .region-sidebar-first .block .content .field-event-date {
  font-weight: bold;
}
.not-front .region-sidebar-first .block .content .field-event-date .date-display-range {
  display: inline;
}
.not-front .region-sidebar-first .block .content .field-media-contact > *:first-child {
  margin-top: 0;
}
.not-front .region-sidebar-first .block .content .field-media-contact > *:last-child {
  margin-bottom: 0;
}
.not-front .region-sidebar-first .block .content .field-conf-track-prof {
  max-width: 100%;
  overflow: hidden;
}
.not-front .region-sidebar-first .block .content .field-conf-track-prof a {
  word-break: break-word;
}
.not-front .region-sidebar-first .block-menu-block .content {
  padding: 0;
}
.not-front .region-sidebar-first .block-menu-block .content .menu-block-wrapper > .menu {
  padding: 7px 0;
}
.not-front .region-sidebar-first .block-menu-block .content .menu-block-wrapper > .menu > li > a {
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3.5px 14px;
}
.not-front .region-sidebar-first .block-menu-block .content .menu-block-wrapper > .menu > li > a.active {
  font-weight: bold;
  color: #570a3c;
}
.not-front .region-sidebar-first .block-menu-block .content .menu-block-wrapper > .menu > li > a.active:hover {
  pointer-events: none;
  text-decoration: none;
  cursor: default;
}
.not-front .region-sidebar-first .block-menu-block .content .menu-block-wrapper > .menu > .menu-item--aside {
  display: none;
}
.not-front .region-sidebar-first .block-block-3 .content {
  padding-top: 0;
}
.not-front .region-sidebar-first .block-staff-profile-left-sidebar .field-signature-image {
  margin: -20px -30px 20px;
}
.js_hide {
  display: none !important;
}
.flexslider .flex-direction-nav.js_hide {
  display: none !important;
}
body.js_no-admin-margin {
  margin-top: 0 !important;
  margin-left: 0 !important;
  padding-top: 0 !important;
}
.ma-left {
  float: left !important;
  margin-right: 20px !important;
}
.ma-right {
  float: right !important;
  margin-left: 20px !important;
}
.ma-small {
  width: 33.33% !important;
}
.ma-med {
  width: 50% !important;
}
.ma-large {
  width: 66.66% !important;
}
.context-blog #zone-content-wrapper .node .block-newsletter-email {
  margin: 3rem 0 1rem;
}
.context-blog #zone-content-wrapper .node .block-newsletter-email h2 {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.context-blog #zone-content-wrapper .node .block-newsletter-email .all-day-auto-processed {
  font-weight: bold;
  text-transform: uppercase;
  color: #890f5e;
  padding-bottom: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0px;
  font-size: 14.5px;
  border-bottom: 0px;
  line-height: 1;
}
.context-blog #zone-content-wrapper .node .block-newsletter-email label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.context-blog #zone-content-wrapper .node .block-newsletter-email .form-type-textfield {
  margin-top: 14px;
  display: inline-block;
  width: calc(100% - 126px);
}
.context-blog #zone-content-wrapper .node .block-newsletter-email .form-type-textfield input {
  padding: 10px 10px 11px;
}
.block-app {
  background-color: #f2f2f2;
  margin-top: 0;
  width: auto;
  padding: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 960px) {
  .block-app {
    float: none;
    margin: 1em auto;
  }
}
@media only screen and (min-width: 768px) {
  .block-app {
    margin-top: -32px;
    background-color: transparent;
    width: 424px;
  }
}
@media only screen and (min-width: 960px) {
  .block-app {
    width: auto;
    float: right;
    clear: none;
    position: relative;
    padding-top: 61px;
  }
}
.block-app .content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  float: right;
  clear: right;
}
.block-app .content .icon img {
  float: right;
  width: 100%;
  height: auto;
}
.block-app .content .callout {
  margin-left: 1em;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.3;
}
.block-app .content .callout a {
  color: #890f5e;
}
.webform-client-form .webform-component {
  margin-bottom: 2rem;
}
.webform-client-form .webform-component textarea,
.webform-client-form .webform-component select,
.webform-client-form .webform-component input[type="date"],
.webform-client-form .webform-component input[type="datetime"],
.webform-client-form .webform-component input[type="datetime-local"],
.webform-client-form .webform-component input[type="email"],
.webform-client-form .webform-component input[type="month"],
.webform-client-form .webform-component input[type="number"],
.webform-client-form .webform-component input[type="password"],
.webform-client-form .webform-component input[type="search"],
.webform-client-form .webform-component input[type="tel"],
.webform-client-form .webform-component input[type="text"],
.webform-client-form .webform-component input[type="time"],
.webform-client-form .webform-component input[type="url"],
.webform-client-form .webform-component input[type="week"],
.webform-client-form .webform-component input[type="checkbox"],
.webform-client-form .webform-component input[type="radio"] {
  border: 1px solid #e5e5e5;
  box-shadow: none;
}
.webform-client-form fieldset legend {
  margin-bottom: 1rem;
}
.webform-progressbar {
  width: 98%;
  margin: 0 auto 4em 0;
  text-align: center;
}
.webform-progressbar-inner {
  height: 1em;
  background-color: #890f5e;
}
.webform-progressbar-outer {
  position: relative;
  border: 1px solid #890f5e;
  width: 100%;
  height: 1em;
  margin: 2em 0;
  background-color: #fff;
}
@media only screen and (max-width: 480px) {
  .webform-progressbar-outer {
    display: none;
  }
}
.webform-progressbar-page {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  margin: -1.3em -0.25em;
  border: 1px solid #890f5e;
  background-color: #fff;
}
.webform-progressbar-page.completed {
  background-color: #890f5e;
  border: 1px solid #890f5e;
}
.webform-progressbar-page.current {
  background-color: #e0275e;
  border: 1px solid #e0275e;
}
.webform-progressbar-page .webform-progressbar-page-number {
  display: none;
}
.webform-progressbar-page .webform-progressbar-page-label {
  position: relative;
  top: 10px;
  margin: 0 -10em;
}
@media only screen and (max-width: 768px) {
  .context-trans-wellness #section-header .branding-data img {
    width: auto !important;
    height: auto !important;
  }
}
@media only screen and (max-width: 768px) {
  .context-trans-wellness .desktop-logo {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .context-trans-wellness .mobile-logo {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .context-trans-wellness .block[id^="block-menu-block-6"] {
    width: 125px!important;
  }
}
@media only screen and (max-width: 768px) {
  .context-trans-wellness .first.last.leaf.menu-mlid-2812.menu-title-donate-now {
    padding-right: 0px !important;
    float: none !important;
  }
}
.context-trans-wellness button,
.context-trans-wellness input[type="reset"],
.context-trans-wellness input[type="submit"],
.context-trans-wellness input[type="button"],
.context-trans-wellness input.button,
.context-trans-wellness a.button,
.context-trans-wellness div.button > a,
.context-trans-wellness span.button > a,
.context-trans-wellness .pager-load-more > li.pager-next a {
  background: #00aae2;
  color: #ffffff !important;
  border: 2px solid #00aae2 !important;
}
.context-trans-wellness button:hover,
.context-trans-wellness input[type="reset"]:hover,
.context-trans-wellness input[type="submit"]:hover,
.context-trans-wellness input[type="button"]:hover,
.context-trans-wellness input.button:hover,
.context-trans-wellness a.button:hover,
.context-trans-wellness div.button > a:hover,
.context-trans-wellness span.button > a:hover,
.context-trans-wellness .pager-load-more > li.pager-next a:hover,
.context-trans-wellness button:focus,
.context-trans-wellness input[type="reset"]:focus,
.context-trans-wellness input[type="submit"]:focus,
.context-trans-wellness input[type="button"]:focus,
.context-trans-wellness input.button:focus,
.context-trans-wellness a.button:focus,
.context-trans-wellness div.button > a:focus,
.context-trans-wellness span.button > a:focus,
.context-trans-wellness .pager-load-more > li.pager-next a:focus {
  background: #fff;
  color: #00aae2 !important;
  border: 2px solid #00aae2;
  text-decoration: none!important;
}
.context-trans-wellness a:link,
.context-trans-wellness a:visited,
.context-trans-wellness a:active {
  color: #00aae2;
  text-decoration: none;
}
.context-trans-wellness a:link:hover,
.context-trans-wellness a:visited:hover,
.context-trans-wellness a:active:hover,
.context-trans-wellness a:link:focus,
.context-trans-wellness a:visited:focus,
.context-trans-wellness a:active:focus {
  text-decoration: underline;
}
.context-trans-wellness.not-front .region-sidebar-first .block-event-left-sidebar .block-title {
  background: #ec1b8b;
}
.context-trans-wellness.not-front .region-sidebar-first .block-event-left-sidebar h3.field-label {
  color: #ec1b8b;
}
.context-trans-wellness #block-system-main .right-inset h3,
.context-trans-wellness .view-sponsorship-products h4 > a,
.context-trans-wellness .view-sponsorship-products .views-field-title > a,
.context-trans-wellness .view-sponsorship-products h4 > span,
.context-trans-wellness .view-sponsorship-products .views-field-title > span {
  color: #ec1b8b;
}
.context-trans-wellness #section-footer #region-footer-second {
  background-color: #00aae2;
}
.trans-wellness-home.not-front .region-preface-first {
  background-image: none;
  background-color: #fff;
  padding: 25px 20px 0px 20px;
}
.trans-wellness-home.not-front .region-preface-first #page-title {
  color: #ec1b8b;
  font-size: 48px;
}
.trans-wellness-home.not-front .region-preface-second .region-blockgroup-chooser > .region-inner > .block > .block-inner {
  border: 11px solid #eb1a7c;
  background-color: white;
  border-right-color: #00aae2;
  border-bottom-color: #00aae2;
}
.trans-wellness-home.not-front .region-preface-second .region-blockgroup-chooser > .region-inner > .block > .block-inner a.button {
  background: #fff;
  color: #00aae2 !important;
  border: 2px solid #00aae2;
}
.trans-wellness-home.not-front .region-preface-second .region-blockgroup-chooser > .region-inner > .block > .block-inner a.button:hover,
.trans-wellness-home.not-front .region-preface-second .region-blockgroup-chooser > .region-inner > .block > .block-inner a.button:focus {
  background: #00aae2;
  color: #ffffff !important;
  border: 2px solid #00aae2 !important;
}
.trans-wellness-home.not-front .region-preface-second .region-blockgroup-chooser > .region-inner > .block > .block-inner .content *:last-child {
  align-self: flex-end;
}
.trans-wellness-home.not-front .region-preface-second .region-blockgroup-chooser > .region-inner > .block .block-title {
  color: #eb1a7c;
  font-family: 'Lovelo Black', sans-serif;
  line-height: 1.3;
}
.trans-wellness-home.not-front #zone-content-wrapper h2.block-title {
  color: #eb1a7c;
  border-bottom: 1px solid #d69bba;
}
.region-branding-inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .region-branding-inner {
    flex-wrap: nowrap;
    margin: 10px 0;
  }
}
.branding-data {
  flex-grow: 1;
  padding-left: 20px;
}
@media only screen and (min-width: 992px) {
  .branding-data {
    padding: 0;
  }
}
#logo {
  width: 240px;
}
@media only screen and (min-width: 992px) {
  #logo {
    width: 284px;
  }
}
.mobileNavOpen .menu-name-menu-call-out-links .menu {
  display: flex !important;
}
.menu-name-menu-call-out-links .menu {
  display: none;
  flex-direction: column;
  margin-left: 22px;
  margin-right: 16px;
  margin-top: 1em;
  padding-top: 2em;
  padding-bottom: 1em;
  border-top: 1px solid #979797;
  row-gap: 1.5em;
  width: calc(100vw - 38px);
}
@media only screen and (min-width: 768px) {
  .menu-name-menu-call-out-links .menu {
    flex-direction: row;
    margin-left: 2rem;
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    align-items: center;
    border-top: 0;
    width: auto;
    padding: 0;
  }
}
@media only screen and (min-width: 768px) {
  .menu-name-menu-call-out-links .menu > li {
    margin-left: 2rem;
  }
}
.menu-name-menu-call-out-links .menu > li > a,
.menu-name-menu-call-out-links .menu > li > span {
  color: #e0275e;
  font-family: "PT Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: uppercase;
}
.menu-name-menu-call-out-links .menu > li > a:before,
.menu-name-menu-call-out-links .menu > li > span:before {
  color: #e42a5d;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  padding-right: 0.5rem;
  text-rendering: auto;
}
.menu-name-menu-call-out-links .menu .menu-title-donate-now > a {
  align-items: center;
  display: flex;
}
.menu-name-menu-call-out-links .menu .menu-title-donate-now > a:before {
  content: "\f4b9";
}
.menu-name-menu-call-out-links .menu .menu-title-patient-portal > a {
  align-items: center;
  display: flex;
}
.menu-name-menu-call-out-links .menu .menu-title-patient-portal > a:before {
  content: "\f812";
}
.menu-name-menu-call-out-links .menu .menu-title-search > span {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 0;
}
.menu-name-menu-call-out-links .menu .menu-title-search > span:before {
  content: "\f002";
  font-size: 1.5rem;
  margin-right: -7px;
}
.menu-name-menu-call-out-links .menu .menu-title-search > span:focus {
  outline: none;
}
#search-helper.searchOpen:before {
  content: "";
  margin-right: 0;
}
#block-search-form #search-close {
  border: 0;
  padding: 0;
}
#block-search-form #search-close:hover,
#block-search-form #search-close:focus {
  cursor: pointer;
}
#block-search-form #search-close span:before {
  border: 0;
  color: #cbccce;
  content: "\f00d";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  margin-right: 0;
  text-rendering: auto;
}
#block-search-form #search-submit {
  border: 0;
  margin-right: 1rem;
  padding: 0;
}
#block-search-form #search-submit:hover,
#block-search-form #search-submit:focus {
  cursor: pointer;
}
#block-search-form #search-submit span:before {
  border: 0;
  color: #e42a5d;
  content: "\f002";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  margin-right: 0;
  text-rendering: auto;
}
#block-search-form .form-submit {
  display: none;
}
.cta--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0;
}
@media only screen and (min-width: 992px) {
  .cta--container {
    margin: 64px 0;
  }
}
@media only screen and (min-width: 992px) {
  .cta--container {
    flex-direction: row;
  }
}
.cta--container .field-pg-cta {
  padding: 0 8px;
  text-align: center;
}
.cta--container .field-pg-cta a {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #e0275e;
  border-color: #e0275e;
  color: #fff !important;
}
.cta--container .field-pg-cta a:hover,
.cta--container .field-pg-cta a:focus {
  text-decoration: none;
}
.cta--container .field-pg-cta a:hover,
.cta--container .field-pg-cta a:focus {
  background-color: #94105e;
  border-color: #94105e;
}
.cta--container .field-pg-cta:last-child {
  margin-top: 1rem;
}
@media only screen and (min-width: 992px) {
  .cta--container .field-pg-cta:last-child {
    margin-top: 0;
  }
}
.cta--container .field-pg-cta:last-child a {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  border-color: #e0275e;
  color: #94105e !important;
}
.cta--container .field-pg-cta:last-child a:hover,
.cta--container .field-pg-cta:last-child a:focus {
  text-decoration: none;
}
.cta--container .field-pg-cta:last-child a:hover,
.cta--container .field-pg-cta:last-child a:focus {
  background-color: #e0275e;
  border-color: #e0275e;
  color: #fff !important;
}
.cta--container .field-pg-cta:only-child a {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #e0275e;
  border-color: #e0275e;
  color: #fff !important;
}
.cta--container .field-pg-cta:only-child a:hover,
.cta--container .field-pg-cta:only-child a:focus {
  text-decoration: none;
}
.cta--container .field-pg-cta:only-child a:hover,
.cta--container .field-pg-cta:only-child a:focus {
  background-color: #94105e;
  border-color: #94105e;
}
#section-header #zone-menu-wrapper {
  background-color: #fff !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1) !important;
  position: relative  !important;
  z-index: 40  !important;
}
#block-megamenu-main-menu #megamenu-main-menu {
  background: transparent;
  position: relative;
  text-align: center;
}
#block-megamenu-main-menu #megamenu-main-menu > li {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  position: static;
}
#block-megamenu-main-menu #megamenu-main-menu > li > h2 {
  font-family: 'PT Sans', sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  font-size: 14.5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
#block-megamenu-main-menu #megamenu-main-menu > li > h2 > a {
  -webkit-transition: all 0.25s ease 0ms;
  -moz-transition: all 0.25s ease 0ms;
  -o-transition: all 0.25s ease 0ms;
  transition: all 0.25s ease 0ms;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: bold;
  height: 50px;
  padding: 0 30px;
}
#block-megamenu-main-menu #megamenu-main-menu > li > h2 > a:hover {
  background-color: rgba(87, 10, 60, 0.7);
  text-decoration: none;
}
#block-megamenu-main-menu #megamenu-main-menu .megamenu-bin {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none !important;
  margin-top: -1px;
  max-width: 1200px !important;
  outline: none;
  width: 100% !important;
}
#block-megamenu-main-menu #megamenu-main-menu .megamenu-bin > li {
  background: transparent;
  display: inline-block;
  height: auto;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: top;
  width: 180px;
}
#block-megamenu-main-menu #megamenu-main-menu .megamenu-bin > li h3 {
  background: transparent;
}
#block-megamenu-main-menu #megamenu-main-menu .megamenu-bin > li a {
  background: transparent;
  color: #fff;
}
#block-megamenu-main-menu #megamenu-main-menu .megamenu-bin > li ul {
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}
.top-level-nav .menu-name-main-menu > .menu > li {
  border-top: 2px solid transparent;
}
.top-level-nav .menu-name-main-menu > .menu > li:hover,
.top-level-nav .menu-name-main-menu > .menu > li:focus {
  border-top-color: #94105e;
}
.top-level-nav .menu-name-main-menu > .menu > .menu-title-about-us {
  background: #f7f7f7;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}
.top-level-nav .menu-name-main-menu > .menu > .menu-title-about-us:hover,
.top-level-nav .menu-name-main-menu > .menu > .menu-title-about-us:focus {
  border-top-color: #ee482e;
}
.top-level-nav .menu-name-main-menu > .menu > .menu-title-about-us > a {
  color: #ee482e !important;
}
#block-mazzoni-sitewide-search {
  display: none;
}
#region-user-first {
  grid-column: span 12;
}
#block-search-form {
  display: none;
  height: 102px;
  left: 50%;
  /* Move 50% from left */
  /* Move 10px from bottom */
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  z-index: 9999;
  max-width: 1200px;
}
#block-search-form form {
  background: white;
  margin: 0 auto;
  height: 102px;
}
@media only screen and (min-width: 1200px) {
  #block-search-form form {
    margin: 0 -20px;
  }
}
#block-search-form form > div {
  display: flex;
  align-items: center;
  width: 95%;
  height: 102px;
  margin: 0 auto;
}
#block-search-form .container-inline {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-top: 12px;
  width: 100%;
}
#block-search-form .container-inline:before {
  -webkit-font-smoothing: antialiased;
  color: #cbccce;
  content: '\f002';
  font-family: 'Font Awesome 5 Free';
  font-size: 1.5rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  text-rendering: auto;
  white-space: nowrap;
}
#block-search-form .form-item {
  flex-grow: 1;
}
#block-search-form #edit-search-block-form--2 {
  border: 0;
  color: #cbccce;
  font-family: 'PT Sans', sans-serif;
  font-size: 30px;
  letter-spacing: -0.1px;
  line-height: 39px;
}
#block-search-form #edit-search-block-form--2:focus {
  border: 0;
  outline: none;
}
#block-search-form #edit-search-block-form--2::placeholder {
  color: #cbccce;
}
#block-search-form .form-actions {
  display: flex;
  align-items: center;
}
#block-search-form .form-submit {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
.field-ds-subnav {
  border: 1px solid #979797;
  border-top: 4px solid #ee482e;
  padding: 25px 40px;
  margin-bottom: 2rem;
}
.field-ds-subnav h2 > a {
  color: #ee482e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
}
.field-ds-subnav .menu {
  margin-left: 0;
}
.field-ds-subnav .menu > li > a {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.75px;
  line-height: 28px;
  text-transform: uppercase;
}
.field-ds-subnav .menu > li > a.active-trail {
  text-decoration: underline;
}
.node-type-landing-page.not-front .block-site-alert {
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  order: 1;
}
.block-site-alert {
  position: relative;
}
div.site-alert {
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  position: relative !important;
  right: 50% !important;
  width: 100vw !important;
  font-weight: normal;
}
div.site-alert div.low,
div.site-alert div.high,
div.site-alert div.info {
  background-image: none;
  border: 0;
}
div.site-alert div.high {
  background-color: #e0275e;
}
div.site-alert div.high div.text {
  color: #fff;
}
div.site-alert div.high div.text a {
  color: #fff;
  text-decoration: underline;
}
div.site-alert div.low {
  background-color: #eaeaea;
}
div.site-alert div.low div.text {
  color: #2f2f2f;
}
div.site-alert div.low div.text a {
  color: #2f2f2f;
  text-decoration: underline;
}
div.site-alert div.text {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px 30px;
  text-align: center;
}
div.site-alert div.text p:last-child {
  margin-bottom: 0;
}
.paragraphs-item-billboard {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .paragraphs-item-billboard {
    left: 50% !important;
    margin-right: -50vw !important;
    margin-left: -50vw !important;
    position: relative !important;
    right: 50% !important;
    width: 100vw !important;
  }
}
.paragraphs-item-billboard .group-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}
.paragraphs-item-billboard .field-pg-image img {
  height: 100%;
  width: 100%;
}
.paragraphs-item-billboard .group-footer {
  align-items: center;
  background: rgba(224, 39, 94, 0.75);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  margin: auto;
  margin: 0;
  padding: 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-billboard .group-footer {
    margin: 80px 162px;
    padding: 3rem;
  }
}
.paragraphs-item-billboard .group-footer .field-pg-heading {
  color: #fff;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 40px;
  letter-spacing: 0.63px;
  text-align: center;
  line-height: 1.1;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-billboard .group-footer .field-pg-heading {
    font-size: 80px;
    letter-spacing: 1.25px;
    line-height: 93.14px;
  }
}
.paragraphs-item-billboard .group-footer .field-pg-body {
  color: #fff;
  font-family: 'PT Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-billboard .group-footer .field-pg-body {
    color: #fff;
    font-family: 'PT Sans';
    font-size: 22px;
    letter-spacing: 0;
    line-height: 32.99px;
    text-align: center;
  }
}
.node-blog-post.view-mode-tile > a .group-overlay {
  background: white !important;
  border: 1px solid #efefef;
  border-top: 3px solid #ed5025;
  position: relative;
}
.node-blog-post.view-mode-tile > a .group-overlay .field-type h4 {
  color: #ed5025;
  font-family: 'PT Sans';
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0.6px !important;
  line-height: 24px !important;
}
.node-blog-post.view-mode-tile > a .group-overlay .field-title h3 {
  color: #94105e !important;
  font-family: 'PT Sans';
  font-size: 24px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.node-blog-post.view-mode-tile > a .group-overlay .field-post-date {
  color: #e0275e;
  font-family: 'PT Sans';
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}
.view-display-id-activities_homepage .view-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
}
.node-recurring-event.view-mode-featured {
  border-top: 1px solid #979797;
  padding: 20px 0;
}
.node-recurring-event.view-mode-featured .field-title h2 {
  color: #94105e;
  font-family: 'PT Sans';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.node-recurring-event.view-mode-featured .field-event-date-time-info {
  color: #e0275e;
  font-family: 'PT Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.node-recurring-event.view-mode-featured .field-body {
  color: #2f2f2f;
  font-family: 'PT Sans';
  font-size: 17px;
  letter-spacing: 0;
  line-height: 24px;
}
.front.logged-in #zone-preface-wrapper {
  display: none;
}
.paragraphs-item-mosaic {
  margin: 80px 0;
}
.paragraphs-item-mosaic .group-header {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 24px;
  row-gap: 48px;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-mosaic .group-header {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.paragraphs-item-mosaic .field-pg-heading {
  color: #ee482e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 36px;
  letter-spacing: 0.56px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: center;
  grid-column: span 1;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-mosaic .field-pg-heading {
    font-size: 48px;
    letter-spacing: 0.75px;
    margin-bottom: 3rem;
  }
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-mosaic .field-pg-heading {
    grid-column: span 3;
  }
}
.paragraphs-item-mosaic .field-pg-content .vocabulary-service-type {
  border: 1px solid #9b9b9b;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3rem 1.25rem;
}
.paragraphs-item-mosaic .field-pg-content .vocabulary-service-type .field-term-image {
  margin-bottom: 16px;
  text-align: center;
}
.paragraphs-item-mosaic .field-pg-content .vocabulary-service-type h2 {
  color: #94105e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}
.paragraphs-item-mosaic .field-pg-content .vocabulary-service-type .taxonomy-term-description {
  color: #2f2f2f;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5;
  text-align: left;
  margin: 0;
}
.paragraphs-item-mosaic .field-pg-content .vocabulary-service-type .taxonomy-term-description p:last-child {
  margin-bottom: 0;
}
.paragraphs-item-custom-feed {
  padding: 5rem 0;
}
.paragraphs-item-custom-feed .field-pg-heading {
  color: #ee482e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 36px;
  letter-spacing: 0.56px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-custom-feed .field-pg-heading {
    font-size: 48px;
    letter-spacing: 0.75px;
    margin-bottom: 3rem;
  }
}
.paragraphs-item-custom-feed .view-content {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.5rem;
  row-gap: 3rem;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-custom-feed .view-content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.view-display-id-activities_homepage .view-content {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.25rem;
  row-gap: 3rem;
}
@media only screen and (min-width: 992px) {
  .view-display-id-activities_homepage .view-content {
    grid-template-columns: 1fr 1fr;
  }
}
.view-id-menu_links.view-display-id-default .views-row > a {
  display: block;
  position: relative;
}
@media only screen {
  .view-id-menu_links.view-display-id-default .views-row > a {
    min-height: 213px;
  }
}
.view-id-menu_links.view-display-id-default .views-row > a::before {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.view-id-menu_links.view-display-id-default .views-row > a img {
  min-height: 240px;
}
.view-id-menu_links.view-display-id-default .views-row > a .field-title {
  color: #fff;
  font-family: 'PT Sans';
  font-size: 24px;
  font-weight: bold;
  left: 50%;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translate(-50%, -50%);
}
.view-id-menu_links.view-display-id-default .views-row > a:hover:before,
.view-id-menu_links.view-display-id-default .views-row > a:focus:before {
  background: rgba(148, 16, 94, 0.5);
}
.paragraphs-item-signpost-container {
  padding-top: 1.5rem;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-signpost-container {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}
.paragraphs-item-signpost-container .field-pg-heading {
  color: #ee482e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 36px;
  letter-spacing: 0.56px;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-signpost-container .field-pg-heading {
    font-size: 48px;
    letter-spacing: 0.75px;
    margin-bottom: 3rem;
  }
}
.paragraphs-items-field-pg-signposts {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}
@media only screen and (min-width: 992px) {
  .paragraphs-items-field-pg-signposts {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.paragraphs-items-field-pg-signposts .field-pg-signposts {
  position: relative;
  display: flex;
}
.paragraphs-items-field-pg-signposts .paragraphs-item-signpost[onclick] {
  border: 1px solid #9B9B9B;
  padding: 1.5rem;
  cursor: pointer;
}
.paragraphs-items-field-pg-signposts .paragraphs-item-signpost[onclick]:hover,
.paragraphs-items-field-pg-signposts .paragraphs-item-signpost[onclick]:focus {
  border-color: #EE482E;
}
.paragraphs-items-field-pg-signposts .field-signpost-image {
  margin-bottom: 2rem;
}
.paragraphs-items-field-pg-signposts .field-signpost-heading {
  color: #94105e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 0.5rem;
}
.paragraphs-item-story {
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  position: relative !important;
  right: 50% !important;
  width: 100vw !important;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story {
    background: linear-gradient(to right, #e0275e 0, #e0275e 25%, #f9f9f9 25%, #f9f9f9 100%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0;
    row-gap: 0;
  }
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story .group-header {
    grid-column: span 2;
  }
}
.paragraphs-item-story .group-left {
  background: linear-gradient(to bottom, #e0275e 0, #e0275e 60%, #fff 60%, #fff 100%);
  padding: 22px 18px 0 18px;
  width: auto;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story .group-left {
    background: transparent;
    float: none;
    padding: 80px 0;
  }
}
.paragraphs-item-story .group-right {
  padding: 22px 18px 0 18px;
  width: auto;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story .group-right {
    align-items: center;
    display: flex;
    float: none;
    padding-top: 80px;
    padding-right: 80px;
    padding-bottom: 0;
    padding-left: 80px;
  }
}
.paragraphs-item-story .group-right .story-body-container .field-pg-heading {
  color: #94105e;
  font-family: 'PT Sans';
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 58px;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story .group-right .story-body-container .field-pg-heading {
    font-size: 40px;
    letter-spacing: 0;
    line-height: 58px;
  }
}
.paragraphs-item-story .group-right .story-body-container .field-pg-body p {
  color: #2f2f2f;
  font-family: 'PT Sans';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story .group-right .story-body-container .field-pg-body p {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 38px;
  }
}
.paragraphs-item-story .cta--container {
  justify-content: flex-start;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story.img-right {
    background: linear-gradient(to right, #f9f9f9 0, #f9f9f9 75%, #e0275e 75%, #e0275e 100%);
  }
}
.paragraphs-item-story.img-right .group-right {
  background: linear-gradient(to bottom, #e0275e 0, #e0275e 60%, #fff 60%, #fff 100%);
  float: none;
  padding: 22px 18px 0 18px;
  width: auto;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story.img-right .group-right {
    background: transparent;
    padding: 80px 0;
  }
}
@media only screen and (max-width: 767px) {
  .paragraphs-item-story.img-right .group-right {
    order: 1;
  }
}
.paragraphs-item-story.img-right .group-left {
  padding: 22px 18px 0 18px;
  width: auto;
  background: transparent;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story.img-right .group-left {
    align-items: center;
    display: flex;
    float: none;
    padding-top: 80px;
    padding-right: 80px;
    padding-bottom: 0;
    padding-left: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .paragraphs-item-story.img-right .group-left {
    order: 2;
  }
}
.paragraphs-item-story.img-right .group-left .story-body-container .field-pg-heading {
  color: #94105e;
  font-family: 'PT Sans';
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 58px;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story.img-right .group-left .story-body-container .field-pg-heading {
    font-size: 40px;
    letter-spacing: 0;
    line-height: 58px;
  }
}
.paragraphs-item-story.img-right .group-left .story-body-container .field-pg-body p {
  color: #2f2f2f;
  font-family: 'PT Sans';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}
@media only screen and (min-width: 992px) {
  .paragraphs-item-story.img-right .group-left .story-body-container .field-pg-body p {
    font-size: 25px;
    letter-spacing: 0;
    line-height: 38px;
  }
}
.node-blog-post.view-mode-tile > a .field-signature-media,
.node-landing-page.view-mode-tile > a .field-signature-media,
.node-page.view-mode-tile > a .field-signature-media,
.node-service-detail.view-mode-tile > a .field-signature-media,
.node-recurring-event.view-mode-tile > a .field-signature-media,
.node-special-event.view-mode-tile > a .field-signature-media,
.node-news-item.view-mode-tile > a .field-signature-media,
.node-blog-post.view-mode-tile > a .field-signature-image,
.node-landing-page.view-mode-tile > a .field-signature-image,
.node-page.view-mode-tile > a .field-signature-image,
.node-service-detail.view-mode-tile > a .field-signature-image,
.node-recurring-event.view-mode-tile > a .field-signature-image,
.node-special-event.view-mode-tile > a .field-signature-image,
.node-news-item.view-mode-tile > a .field-signature-image {
  margin: 0 16px;
}
.node-blog-post.view-mode-tile > a .group-overlay,
.node-landing-page.view-mode-tile > a .group-overlay,
.node-page.view-mode-tile > a .group-overlay,
.node-service-detail.view-mode-tile > a .group-overlay,
.node-recurring-event.view-mode-tile > a .group-overlay,
.node-special-event.view-mode-tile > a .group-overlay,
.node-news-item.view-mode-tile > a .group-overlay {
  background: white !important;
  border: 1px solid #efefef;
  border-top: 3px solid #ed5025;
  padding: 24px;
  position: relative;
}
.node-blog-post.view-mode-tile > a .group-overlay .field-type h4,
.node-landing-page.view-mode-tile > a .group-overlay .field-type h4,
.node-page.view-mode-tile > a .group-overlay .field-type h4,
.node-service-detail.view-mode-tile > a .group-overlay .field-type h4,
.node-recurring-event.view-mode-tile > a .group-overlay .field-type h4,
.node-special-event.view-mode-tile > a .group-overlay .field-type h4,
.node-news-item.view-mode-tile > a .group-overlay .field-type h4,
.node-blog-post.view-mode-tile > a .group-overlay .field-activity-type h4,
.node-landing-page.view-mode-tile > a .group-overlay .field-activity-type h4,
.node-page.view-mode-tile > a .group-overlay .field-activity-type h4,
.node-service-detail.view-mode-tile > a .group-overlay .field-activity-type h4,
.node-recurring-event.view-mode-tile > a .group-overlay .field-activity-type h4,
.node-special-event.view-mode-tile > a .group-overlay .field-activity-type h4,
.node-news-item.view-mode-tile > a .group-overlay .field-activity-type h4 {
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 0;
}
.node-blog-post.view-mode-tile > a .group-overlay .field-title h3,
.node-landing-page.view-mode-tile > a .group-overlay .field-title h3,
.node-page.view-mode-tile > a .group-overlay .field-title h3,
.node-service-detail.view-mode-tile > a .group-overlay .field-title h3,
.node-recurring-event.view-mode-tile > a .group-overlay .field-title h3,
.node-special-event.view-mode-tile > a .group-overlay .field-title h3,
.node-news-item.view-mode-tile > a .group-overlay .field-title h3 {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 0;
}
.node-blog-post.view-mode-tile > a .group-overlay .field-post-date,
.node-landing-page.view-mode-tile > a .group-overlay .field-post-date,
.node-page.view-mode-tile > a .group-overlay .field-post-date,
.node-service-detail.view-mode-tile > a .group-overlay .field-post-date,
.node-recurring-event.view-mode-tile > a .group-overlay .field-post-date,
.node-special-event.view-mode-tile > a .group-overlay .field-post-date,
.node-news-item.view-mode-tile > a .group-overlay .field-post-date,
.node-blog-post.view-mode-tile > a .group-overlay .field-date-time h4,
.node-landing-page.view-mode-tile > a .group-overlay .field-date-time h4,
.node-page.view-mode-tile > a .group-overlay .field-date-time h4,
.node-service-detail.view-mode-tile > a .group-overlay .field-date-time h4,
.node-recurring-event.view-mode-tile > a .group-overlay .field-date-time h4,
.node-special-event.view-mode-tile > a .group-overlay .field-date-time h4,
.node-news-item.view-mode-tile > a .group-overlay .field-date-time h4 {
  color: #e0275e;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 0;
}
.node-blog-post.view-mode-tile > a:hover,
.node-landing-page.view-mode-tile > a:hover,
.node-page.view-mode-tile > a:hover,
.node-service-detail.view-mode-tile > a:hover,
.node-recurring-event.view-mode-tile > a:hover,
.node-special-event.view-mode-tile > a:hover,
.node-news-item.view-mode-tile > a:hover,
.node-blog-post.view-mode-tile > a:focus,
.node-landing-page.view-mode-tile > a:focus,
.node-page.view-mode-tile > a:focus,
.node-service-detail.view-mode-tile > a:focus,
.node-recurring-event.view-mode-tile > a:focus,
.node-special-event.view-mode-tile > a:focus,
.node-news-item.view-mode-tile > a:focus {
  text-decoration: none !important;
}
.node-blog-post.view-mode-tile > a:hover .field-signature-media,
.node-landing-page.view-mode-tile > a:hover .field-signature-media,
.node-page.view-mode-tile > a:hover .field-signature-media,
.node-service-detail.view-mode-tile > a:hover .field-signature-media,
.node-recurring-event.view-mode-tile > a:hover .field-signature-media,
.node-special-event.view-mode-tile > a:hover .field-signature-media,
.node-news-item.view-mode-tile > a:hover .field-signature-media,
.node-blog-post.view-mode-tile > a:focus .field-signature-media,
.node-landing-page.view-mode-tile > a:focus .field-signature-media,
.node-page.view-mode-tile > a:focus .field-signature-media,
.node-service-detail.view-mode-tile > a:focus .field-signature-media,
.node-recurring-event.view-mode-tile > a:focus .field-signature-media,
.node-special-event.view-mode-tile > a:focus .field-signature-media,
.node-news-item.view-mode-tile > a:focus .field-signature-media,
.node-blog-post.view-mode-tile > a:hover .field-signature-image,
.node-landing-page.view-mode-tile > a:hover .field-signature-image,
.node-page.view-mode-tile > a:hover .field-signature-image,
.node-service-detail.view-mode-tile > a:hover .field-signature-image,
.node-recurring-event.view-mode-tile > a:hover .field-signature-image,
.node-special-event.view-mode-tile > a:hover .field-signature-image,
.node-news-item.view-mode-tile > a:hover .field-signature-image,
.node-blog-post.view-mode-tile > a:focus .field-signature-image,
.node-landing-page.view-mode-tile > a:focus .field-signature-image,
.node-page.view-mode-tile > a:focus .field-signature-image,
.node-service-detail.view-mode-tile > a:focus .field-signature-image,
.node-recurring-event.view-mode-tile > a:focus .field-signature-image,
.node-special-event.view-mode-tile > a:focus .field-signature-image,
.node-news-item.view-mode-tile > a:focus .field-signature-image {
  position: relative;
}
.node-blog-post.view-mode-tile > a:hover .field-signature-media:before,
.node-landing-page.view-mode-tile > a:hover .field-signature-media:before,
.node-page.view-mode-tile > a:hover .field-signature-media:before,
.node-service-detail.view-mode-tile > a:hover .field-signature-media:before,
.node-recurring-event.view-mode-tile > a:hover .field-signature-media:before,
.node-special-event.view-mode-tile > a:hover .field-signature-media:before,
.node-news-item.view-mode-tile > a:hover .field-signature-media:before,
.node-blog-post.view-mode-tile > a:focus .field-signature-media:before,
.node-landing-page.view-mode-tile > a:focus .field-signature-media:before,
.node-page.view-mode-tile > a:focus .field-signature-media:before,
.node-service-detail.view-mode-tile > a:focus .field-signature-media:before,
.node-recurring-event.view-mode-tile > a:focus .field-signature-media:before,
.node-special-event.view-mode-tile > a:focus .field-signature-media:before,
.node-news-item.view-mode-tile > a:focus .field-signature-media:before,
.node-blog-post.view-mode-tile > a:hover .field-signature-image:before,
.node-landing-page.view-mode-tile > a:hover .field-signature-image:before,
.node-page.view-mode-tile > a:hover .field-signature-image:before,
.node-service-detail.view-mode-tile > a:hover .field-signature-image:before,
.node-recurring-event.view-mode-tile > a:hover .field-signature-image:before,
.node-special-event.view-mode-tile > a:hover .field-signature-image:before,
.node-news-item.view-mode-tile > a:hover .field-signature-image:before,
.node-blog-post.view-mode-tile > a:focus .field-signature-image:before,
.node-landing-page.view-mode-tile > a:focus .field-signature-image:before,
.node-page.view-mode-tile > a:focus .field-signature-image:before,
.node-service-detail.view-mode-tile > a:focus .field-signature-image:before,
.node-recurring-event.view-mode-tile > a:focus .field-signature-image:before,
.node-special-event.view-mode-tile > a:focus .field-signature-image:before,
.node-news-item.view-mode-tile > a:focus .field-signature-image:before {
  background-color: rgba(148, 16, 94, 0.5);
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.node-blog-post.view-mode-tile > a:hover .group-overlay,
.node-landing-page.view-mode-tile > a:hover .group-overlay,
.node-page.view-mode-tile > a:hover .group-overlay,
.node-service-detail.view-mode-tile > a:hover .group-overlay,
.node-recurring-event.view-mode-tile > a:hover .group-overlay,
.node-special-event.view-mode-tile > a:hover .group-overlay,
.node-news-item.view-mode-tile > a:hover .group-overlay,
.node-blog-post.view-mode-tile > a:focus .group-overlay,
.node-landing-page.view-mode-tile > a:focus .group-overlay,
.node-page.view-mode-tile > a:focus .group-overlay,
.node-service-detail.view-mode-tile > a:focus .group-overlay,
.node-recurring-event.view-mode-tile > a:focus .group-overlay,
.node-special-event.view-mode-tile > a:focus .group-overlay,
.node-news-item.view-mode-tile > a:focus .group-overlay {
  border-color: #ed5025;
}
.node-blog-post.view-mode-tile > a:hover .group-overlay .field-type h4,
.node-landing-page.view-mode-tile > a:hover .group-overlay .field-type h4,
.node-page.view-mode-tile > a:hover .group-overlay .field-type h4,
.node-service-detail.view-mode-tile > a:hover .group-overlay .field-type h4,
.node-recurring-event.view-mode-tile > a:hover .group-overlay .field-type h4,
.node-special-event.view-mode-tile > a:hover .group-overlay .field-type h4,
.node-news-item.view-mode-tile > a:hover .group-overlay .field-type h4,
.node-blog-post.view-mode-tile > a:focus .group-overlay .field-type h4,
.node-landing-page.view-mode-tile > a:focus .group-overlay .field-type h4,
.node-page.view-mode-tile > a:focus .group-overlay .field-type h4,
.node-service-detail.view-mode-tile > a:focus .group-overlay .field-type h4,
.node-recurring-event.view-mode-tile > a:focus .group-overlay .field-type h4,
.node-special-event.view-mode-tile > a:focus .group-overlay .field-type h4,
.node-news-item.view-mode-tile > a:focus .group-overlay .field-type h4 {
  text-decoration: none;
}
.node-blog-post.view-mode-tile > a:hover .group-overlay .field-post-date,
.node-landing-page.view-mode-tile > a:hover .group-overlay .field-post-date,
.node-page.view-mode-tile > a:hover .group-overlay .field-post-date,
.node-service-detail.view-mode-tile > a:hover .group-overlay .field-post-date,
.node-recurring-event.view-mode-tile > a:hover .group-overlay .field-post-date,
.node-special-event.view-mode-tile > a:hover .group-overlay .field-post-date,
.node-news-item.view-mode-tile > a:hover .group-overlay .field-post-date,
.node-blog-post.view-mode-tile > a:focus .group-overlay .field-post-date,
.node-landing-page.view-mode-tile > a:focus .group-overlay .field-post-date,
.node-page.view-mode-tile > a:focus .group-overlay .field-post-date,
.node-service-detail.view-mode-tile > a:focus .group-overlay .field-post-date,
.node-recurring-event.view-mode-tile > a:focus .group-overlay .field-post-date,
.node-special-event.view-mode-tile > a:focus .group-overlay .field-post-date,
.node-news-item.view-mode-tile > a:focus .group-overlay .field-post-date {
  text-decoration: none;
}
.node-blog-post.view-mode-tile > a:hover .group-overlay .field-title h3,
.node-landing-page.view-mode-tile > a:hover .group-overlay .field-title h3,
.node-page.view-mode-tile > a:hover .group-overlay .field-title h3,
.node-service-detail.view-mode-tile > a:hover .group-overlay .field-title h3,
.node-recurring-event.view-mode-tile > a:hover .group-overlay .field-title h3,
.node-special-event.view-mode-tile > a:hover .group-overlay .field-title h3,
.node-news-item.view-mode-tile > a:hover .group-overlay .field-title h3,
.node-blog-post.view-mode-tile > a:focus .group-overlay .field-title h3,
.node-landing-page.view-mode-tile > a:focus .group-overlay .field-title h3,
.node-page.view-mode-tile > a:focus .group-overlay .field-title h3,
.node-service-detail.view-mode-tile > a:focus .group-overlay .field-title h3,
.node-recurring-event.view-mode-tile > a:focus .group-overlay .field-title h3,
.node-special-event.view-mode-tile > a:focus .group-overlay .field-title h3,
.node-news-item.view-mode-tile > a:focus .group-overlay .field-title h3 {
  text-decoration: underline;
}
.region-footer-first,
.region-footer-second {
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  position: relative !important;
  right: 50% !important;
  width: 100vw !important;
  background: #f9f9f9;
  color: #2f2f2f;
  padding-top: 2rem;
}
@media only screen and (min-width: 992px) {
  .region-footer-first,
  .region-footer-second {
    padding-top: 4rem;
  }
}
.region-footer-first-inner {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 20px;
  max-width: 1200px;
  column-gap: 27px;
  row-gap: 27px;
}
@media only screen and (min-width: 992px) {
  .region-footer-first-inner {
    grid-template-columns: 3fr 5fr 2fr 2fr;
    margin: 0 auto;
    padding: 0 20px;
  }
}
.region-footer-first-inner:before {
  display: none !important;
}
@media only screen and (min-width: 992px) {
  .region-footer-second-inner {
    padding: 0 20px;
  }
}
#section-footer #region-postscript-second {
  background: white;
  padding: 32px 0;
}
@media only screen and (min-width: 992px) {
  #section-footer #region-postscript-second {
    padding: 64px 0 0 0;
  }
}
@media only screen and (min-width: 1200px) {
  #section-footer #region-postscript-second {
    padding: 64px 0;
  }
}
#section-footer #region-postscript-second .region-inner {
  background: #94105e;
  margin-right: 20px;
  margin-left: 20px;
  max-width: 1104px;
  padding: 1.5rem 1rem;
}
@media only screen and (min-width: 992px) {
  #section-footer #region-postscript-second .region-inner {
    margin-right: auto;
    margin-left: auto;
    padding: 3rem;
  }
}
#section-footer #region-postscript-second .region-inner .content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (min-width: 992px) {
  #section-footer #region-postscript-second .region-inner .content {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
#section-footer #region-postscript-second .region-inner .cta--copy {
  align-self: flex-start;
}
@media only screen and (min-width: 992px) {
  #section-footer #region-postscript-second .region-inner .cta--copy {
    flex-grow: 1;
  }
}
#section-footer #region-postscript-second .region-inner .cta--copy h2 {
  color: #fff;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 32px;
  letter-spacing: 0.5px;
  line-height: 1.1;
  margin-top: 0;
  max-width: 32ch;
}
#section-footer #region-postscript-second .region-inner .cta--copy p {
  color: #fff;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 64ch;
}
#section-footer #region-postscript-second .region-inner .cta--copy p:last-child {
  margin-bottom: 0;
}
#section-footer #region-postscript-second .region-inner .cta--buttons {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
@media only screen and (min-width: 992px) {
  #section-footer #region-postscript-second .region-inner .cta--buttons {
    align-self: unset;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
  }
}
#section-footer #region-postscript-second .region-inner .cta--buttons .btn {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #380627;
  border-color: #380627;
  color: #fff !important;
  margin-bottom: 1rem;
  padding: 10px 15px;
  padding: 16px 23px;
}
#section-footer #region-postscript-second .region-inner .cta--buttons .btn:hover,
#section-footer #region-postscript-second .region-inner .cta--buttons .btn:focus {
  text-decoration: none;
}
#section-footer #region-postscript-second .region-inner .cta--buttons .btn:hover,
#section-footer #region-postscript-second .region-inner .cta--buttons .btn:focus {
  background-color: #fff;
  border-color: #380627;
  color: #380627 !important;
}
@media only screen and (min-width: 992px) {
  #section-footer #region-postscript-second .region-inner .cta--buttons .btn {
    min-width: auto;
  }
}
@media only screen and (min-width: 992px) {
  #section-footer #region-postscript-second .region-inner .cta--buttons .btn:nth-child(2) {
    margin-left: 1rem;
  }
}
#section-footer #region-postscript-second .region-inner .block-title {
  color: #fff;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 96px;
}
#section-footer #region-postscript-second .region-inner p {
  color: #fff;
  font-family: 'PT Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
#logo_footer {
  margin: 0 auto;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  #logo_footer {
    margin-top: -30px;
  }
}
#logo_footer img {
  height: 200px;
}
@media only screen and (min-width: 992px) {
  #logo_footer img {
    height: 200px;
  }
}
#block-block-5 {
  margin-top: 1rem;
}
@media only screen and (min-width: 992px) {
  #block-block-5 {
    margin: 0;
    width: 100% !important;
    grid-row: span 2;
  }
}
#block-block-5 .block-title {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
#block-block-5 .subheading {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1rem;
  margin-top: 0;
}
#block-menu-menu-footer-quick-links {
  margin-top: 1rem;
}
@media only screen and (min-width: 992px) {
  #block-menu-menu-footer-quick-links {
    margin: 0;
    grid-row: span 2;
  }
}
#block-menu-menu-footer-quick-links .block-title {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
#block-menu-menu-footer-quick-links ul > li:first-child > a {
  padding-top: 0;
}
#block-menu-menu-footer-quick-links ul > li > a {
  color: #e0275e;
  font-family: 'PT Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  display: inline-block;
  padding: 0.5rem 0;
}
#block-block-7 {
  margin-top: 1rem;
}
@media only screen and (min-width: 992px) {
  #block-block-7 {
    margin: 0;
    grid-row: span 2;
  }
}
#block-block-7 .block-title {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  margin-top: 0;
}
#block-block-7 #social-links {
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
  padding-left: 0;
}
#block-block-7 #social-links > li {
  margin-right: 0.5rem;
}
#block-block-7 #social-links > li > a {
  align-items: center;
  border: 2px solid #e0275e;
  border-radius: 1000px;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}
#block-block-7 #social-links > li > a img {
  display: none;
}
#block-block-7 #social-links > li > a:before {
  -webkit-font-smoothing: antialiased;
  color: #94105e;
  display: inline-block;
  font-family: 'Font Awesome 5 Brands';
  font-size: 1rem;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
#block-block-7 #social-links > li > a.facebook-link:before {
  content: '\f39e';
}
#block-block-7 #social-links > li > a.twitter-link:before {
  content: '\f099';
  padding: 5px 15px;
}
#block-block-7 #social-links > li > a.google-plus-link:before {
  content: '\f16d';
}
#block-block-7 #social-links > li > a:hover,
#block-block-7 #social-links > li > a:focus {
  background-color: #e0275e;
  text-decoration: none;
}
#block-block-7 #social-links > li > a:hover:before,
#block-block-7 #social-links > li > a:focus:before {
  color: white;
}
#maz-addys {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
#maz-addys .addy {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  width: 100% !important;
}
#block-menu-menu-footer-menu {
  display: none;
}
#block-mazzoni-sf-newsletter-email {
  margin-top: 1rem;
  grid-row: 2;
}
@media only screen and (min-width: 992px) {
  #block-mazzoni-sf-newsletter-email {
    margin-top: 0;
  }
}
#block-mazzoni-sf-newsletter-email .block-title {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  margin-bottom: 0;
}
#block-mazzoni-sf-newsletter-email form > div {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  letter-spacing: 0;
  line-height: 1.5;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0;
}
#block-mazzoni-sf-newsletter-email form > div div {
  margin-bottom: 0.5rem;
  width: 100%;
}
#block-mazzoni-sf-newsletter-email .form-item {
  margin-bottom: 0;
  max-width: 168px;
  width: 100%;
}
@media only screen and (min-width: 1200px) {
  #block-mazzoni-sf-newsletter-email .form-item {
    max-width: 160px;
  }
}
#block-mazzoni-sf-newsletter-email .form-item label {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
#block-mazzoni-sf-newsletter-email .form-submit {
  border: 2px solid transparent;
  border-radius: 3px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #e0275e;
  border-color: #e0275e;
  color: #fff !important;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  margin-left: 0;
  margin-left: 0.5rem;
  min-width: auto;
  padding: 8px 20px;
  margin-bottom: 0;
}
#block-mazzoni-sf-newsletter-email .form-submit:hover,
#block-mazzoni-sf-newsletter-email .form-submit:focus {
  text-decoration: none;
}
#block-mazzoni-sf-newsletter-email .form-submit:hover,
#block-mazzoni-sf-newsletter-email .form-submit:focus {
  text-decoration: none;
}
#block-mazzoni-sf-newsletter-email .form-submit:hover,
#block-mazzoni-sf-newsletter-email .form-submit:focus {
  background-color: #94105e;
  border-color: #94105e;
}
#block-block-8 {
  border-top: 1px solid #979797;
  margin: 1rem 20px;
  max-width: 1200px;
  padding-top: 1rem;
}
@media only screen and (min-width: 992px) {
  #block-block-8 {
    margin: 1rem auto;
  }
}
#block-block-8 p {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.6px;
  line-height: 26px;
}
#block-block-9 {
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  position: relative !important;
  right: 50% !important;
  width: 100vw !important;
  background-color: #94105e;
  color: white;
}
#block-block-9 .block-inner {
  display: flex;
  margin: 0 20px;
  max-width: 1200px;
  padding: 1.5rem 0;
}
@media only screen and (min-width: 992px) {
  #block-block-9 .block-inner {
    margin-right: auto;
    margin-left: auto;
    padding: 1.5rem 20px;
  }
}
#block-block-9 .block-inner p {
  color: #fff;
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-bottom: 0;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  #block-block-9 .block-inner p {
    font-size: 16px;
    letter-spacing: 0.6px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 992px) {
  #block-block-9 .block-inner p {
    text-align: left;
  }
}
#block-block-9 .block-inner p a {
  color: white;
}
#block-block-9 .block-inner p a:hover,
#block-block-9 .block-inner p a:focus {
  text-decoration: underline;
}
#block-ma-ux-ma-jawn {
  display: none;
}
#directions-link {
  display: none;
}
.sidebar-container,
.block-mazzoni-filter-topic,
.block-mazzoni-filter-type,
.block-mazzoni-filter-activity {
  border: 1px solid #979797;
  border-top: 4px solid #ee482e;
  padding: 25px 40px;
  margin-bottom: 2rem;
}
.sidebar-container h2,
.block-mazzoni-filter-topic h2,
.block-mazzoni-filter-type h2,
.block-mazzoni-filter-activity h2 {
  color: #ee482e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
  border-bottom: 0 !important;
  margin-bottom: 1rem;
}
.sidebar-container .menu,
.block-mazzoni-filter-topic .menu,
.block-mazzoni-filter-type .menu,
.block-mazzoni-filter-activity .menu,
.sidebar-container ul,
.block-mazzoni-filter-topic ul,
.block-mazzoni-filter-type ul,
.block-mazzoni-filter-activity ul {
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.sidebar-container .menu > li > a,
.block-mazzoni-filter-topic .menu > li > a,
.block-mazzoni-filter-type .menu > li > a,
.block-mazzoni-filter-activity .menu > li > a,
.sidebar-container ul > li > a,
.block-mazzoni-filter-topic ul > li > a,
.block-mazzoni-filter-type ul > li > a,
.block-mazzoni-filter-activity ul > li > a {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.75px;
  line-height: 28px;
  text-transform: uppercase;
}
.sidebar-container .menu > li.selected a,
.block-mazzoni-filter-topic .menu > li.selected a,
.block-mazzoni-filter-type .menu > li.selected a,
.block-mazzoni-filter-activity .menu > li.selected a,
.sidebar-container ul > li.selected a,
.block-mazzoni-filter-topic ul > li.selected a,
.block-mazzoni-filter-type ul > li.selected a,
.block-mazzoni-filter-activity ul > li.selected a {
  text-decoration: underline;
}
.sidebar-container .event__date,
.block-mazzoni-filter-topic .event__date,
.block-mazzoni-filter-type .event__date,
.block-mazzoni-filter-activity .event__date,
.sidebar-container .event__location,
.block-mazzoni-filter-topic .event__location,
.block-mazzoni-filter-type .event__location,
.block-mazzoni-filter-activity .event__location,
.sidebar-container .event__contact,
.block-mazzoni-filter-topic .event__contact,
.block-mazzoni-filter-type .event__contact,
.block-mazzoni-filter-activity .event__contact {
  display: table;
}
.sidebar-container .event__date:before,
.block-mazzoni-filter-topic .event__date:before,
.block-mazzoni-filter-type .event__date:before,
.block-mazzoni-filter-activity .event__date:before,
.sidebar-container .event__location:before,
.block-mazzoni-filter-topic .event__location:before,
.block-mazzoni-filter-type .event__location:before,
.block-mazzoni-filter-activity .event__location:before,
.sidebar-container .event__contact:before,
.block-mazzoni-filter-topic .event__contact:before,
.block-mazzoni-filter-type .event__contact:before,
.block-mazzoni-filter-activity .event__contact:before {
  -webkit-font-smoothing: antialiased;
  color: #94105e;
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-size: 1.4rem;
  margin-right: 1rem;
  font-weight: 900;
}
.sidebar-container .event__date .field__items,
.block-mazzoni-filter-topic .event__date .field__items,
.block-mazzoni-filter-type .event__date .field__items,
.block-mazzoni-filter-activity .event__date .field__items,
.sidebar-container .event__location .field__items,
.block-mazzoni-filter-topic .event__location .field__items,
.block-mazzoni-filter-type .event__location .field__items,
.block-mazzoni-filter-activity .event__location .field__items,
.sidebar-container .event__contact .field__items,
.block-mazzoni-filter-topic .event__contact .field__items,
.block-mazzoni-filter-type .event__contact .field__items,
.block-mazzoni-filter-activity .event__contact .field__items {
  display: table-cell;
  font-size: 1.2rem;
}
.sidebar-container .event__date,
.block-mazzoni-filter-topic .event__date,
.block-mazzoni-filter-type .event__date,
.block-mazzoni-filter-activity .event__date {
  margin-bottom: 1rem;
}
.sidebar-container .event__date:before,
.block-mazzoni-filter-topic .event__date:before,
.block-mazzoni-filter-type .event__date:before,
.block-mazzoni-filter-activity .event__date:before {
  content: '\f073';
}
.sidebar-container .event__location:before,
.block-mazzoni-filter-topic .event__location:before,
.block-mazzoni-filter-type .event__location:before,
.block-mazzoni-filter-activity .event__location:before {
  content: '\f3c5';
}
.sidebar-container .event__contact:before,
.block-mazzoni-filter-topic .event__contact:before,
.block-mazzoni-filter-type .event__contact:before,
.block-mazzoni-filter-activity .event__contact:before {
  content: '\f007';
}
.sidebar-container .event__directions,
.block-mazzoni-filter-topic .event__directions,
.block-mazzoni-filter-type .event__directions,
.block-mazzoni-filter-activity .event__directions {
  margin-left: 2.1rem;
  font-size: 1.1rem;
}
.sidebar-related h2 {
  color: #ee482e;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32px;
}
.sidebar-related .field-related-content {
  margin-bottom: 2rem;
}
.node-recurring-event.view-mode-featured {
  border-top: 1px solid #979797;
  padding: 20px 0;
}
.node-recurring-event.view-mode-featured .field-title h2 a {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.node-recurring-event.view-mode-featured .field-event-date-time-info {
  color: #e0275e;
  font-family: 'PT Sans';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 24px;
}
.node-recurring-event.view-mode-featured .field-body {
  color: #000;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}
.node-recurring-event.view-mode-teaser,
.node-special-event.view-mode-teaser {
  border-bottom: 1px solid #979797;
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 0;
  column-gap: 27px;
}
.node-recurring-event.view-mode-teaser.has-media,
.node-special-event.view-mode-teaser.has-media {
  grid-template-columns: 3fr 5fr;
}
.node-recurring-event.view-mode-teaser .group-right .field-activity-type,
.node-special-event.view-mode-teaser .group-right .field-activity-type {
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-transform: uppercase;
  margin-top: 0;
}
.node-recurring-event.view-mode-teaser .group-right h3,
.node-special-event.view-mode-teaser .group-right h3 {
  margin-top: 0;
}
.node-recurring-event.view-mode-teaser .group-right h3 > a,
.node-special-event.view-mode-teaser .group-right h3 > a {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.node-recurring-event.view-mode-teaser .group-right .field-event-date-time-info,
.node-special-event.view-mode-teaser .group-right .field-event-date-time-info {
  color: #e0275e;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.node-recurring-event.view-mode-full,
.node-special-event.view-mode-full {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 4rem;
  column-gap: 27px;
}
.node-recurring-event.view-mode-full .group-header,
.node-special-event.view-mode-full .group-header {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-recurring-event.view-mode-full .group-header,
  .node-special-event.view-mode-full .group-header {
    grid-column: span 8;
  }
}
.node-recurring-event.view-mode-full .group-left,
.node-special-event.view-mode-full .group-left {
  display: flex;
  flex-direction: column;
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-recurring-event.view-mode-full .group-left,
  .node-special-event.view-mode-full .group-left {
    grid-column: span 8;
  }
}
.node-recurring-event.view-mode-full .group-left .field-signature-image,
.node-special-event.view-mode-full .group-left .field-signature-image,
.node-recurring-event.view-mode-full .group-left .field-signature-media,
.node-special-event.view-mode-full .group-left .field-signature-media {
  margin-bottom: 2rem;
}
.node-recurring-event.view-mode-full .group-left .field-body,
.node-special-event.view-mode-full .group-left .field-body {
  font-size: 17px;
}
@media only screen and (min-width: 1200px) {
  .node-recurring-event.view-mode-full .group-left .field-body,
  .node-special-event.view-mode-full .group-left .field-body {
    padding: 0 72px;
  }
}
@media only screen and (min-width: 1200px) {
  .node-recurring-event.view-mode-full .group-left .assets-container,
  .node-special-event.view-mode-full .group-left .assets-container {
    padding: 0 72px;
  }
}
.node-recurring-event.view-mode-full .group-left .assets-container .field-label,
.node-special-event.view-mode-full .group-left .assets-container .field-label {
  border-bottom: 1px solid #b2b2b2;
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.68px;
  line-height: 24px;
  padding-bottom: 0.5rem;
}
.node-recurring-event.view-mode-full .group-left .assets-container .field-files a,
.node-special-event.view-mode-full .group-left .assets-container .field-files a,
.node-recurring-event.view-mode-full .group-left .assets-container .field-links a,
.node-special-event.view-mode-full .group-left .assets-container .field-links a {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 22px;
}
.node-recurring-event.view-mode-full .group-left .assets-container .field-files a:hover,
.node-special-event.view-mode-full .group-left .assets-container .field-files a:hover,
.node-recurring-event.view-mode-full .group-left .assets-container .field-links a:hover,
.node-special-event.view-mode-full .group-left .assets-container .field-links a:hover,
.node-recurring-event.view-mode-full .group-left .assets-container .field-files a:focus,
.node-special-event.view-mode-full .group-left .assets-container .field-files a:focus,
.node-recurring-event.view-mode-full .group-left .assets-container .field-links a:focus,
.node-special-event.view-mode-full .group-left .assets-container .field-links a:focus {
  border-bottom: 1px solid #e0275e;
  text-decoration: none;
}
.node-recurring-event.view-mode-full .group-right,
.node-special-event.view-mode-full .group-right {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-recurring-event.view-mode-full .group-right,
  .node-special-event.view-mode-full .group-right {
    grid-column: span 4;
  }
}
.node-recurring-event.view-mode-full .group-footer,
.node-special-event.view-mode-full .group-footer {
  grid-column: span 12;
}
.node-type-recurring-event .title-wrap,
.node-type-special-event .title-wrap {
  display: none;
}
.view-display-id-activies_related .view-content .views-row {
  margin-bottom: 2rem;
}
.view-display-id-events_listing .view-content {
  margin-top: 34px !important;
}
.view-display-id-events_listing .group-header h3 {
  margin-top: 2.5rem;
  border-bottom: 1px solid #979797;
  padding-bottom: 2.5rem;
  margin-bottom: 0;
}
.view-display-id-events_listing .group-header:first-child h3 {
  margin-top: 0;
}
.view-display-id-events_listing .view-content {
  margin-top: 3rem;
}
.node-page.view-mode-full {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 27px;
}
.node-page.view-mode-full .group-header {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-page.view-mode-full .group-header {
    grid-column: span 8;
  }
}
.node-page.view-mode-full .group-left {
  grid-column: span 12;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 992px) {
  .node-page.view-mode-full .group-left {
    grid-column: span 8;
  }
}
.node-page.view-mode-full .group-left .field-signature-image,
.node-page.view-mode-full .group-left .field-signature-media {
  margin-bottom: 2rem;
}
.node-page.view-mode-full .group-left .field-body {
  font-size: 17px;
}
@media only screen and (min-width: 1200px) {
  .node-page.view-mode-full .group-left .field-body {
    padding: 0 72px;
  }
}
@media only screen and (min-width: 1200px) {
  .node-page.view-mode-full .group-left .assets-container {
    padding: 0 72px;
  }
}
.node-page.view-mode-full .group-left .assets-container .field-label {
  border-bottom: 1px solid #b2b2b2;
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.68px;
  line-height: 24px;
  padding-bottom: 0.5rem;
}
.node-page.view-mode-full .group-left .assets-container .field-files a,
.node-page.view-mode-full .group-left .assets-container .field-links a {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 22px;
}
.node-page.view-mode-full .group-left .assets-container .field-files a:hover,
.node-page.view-mode-full .group-left .assets-container .field-links a:hover,
.node-page.view-mode-full .group-left .assets-container .field-files a:focus,
.node-page.view-mode-full .group-left .assets-container .field-links a:focus {
  border-bottom: 1px solid #e0275e;
  text-decoration: none;
}
.node-page.view-mode-full .group-right {
  grid-column: span 12;
  margin-top: 3rem;
}
@media only screen and (min-width: 992px) {
  .node-page.view-mode-full .group-right {
    grid-column: span 4;
    margin-top: 0;
  }
}
.node-page.view-mode-full .group-footer {
  grid-column: span 12;
}
.node-type-page .title-wrap {
  display: none;
}
.front #zone-preface-wrapper {
  display: block !important;
}
.front #zone-preface-wrapper .region-preface-first {
  height: auto;
}
.front #zone-preface-wrapper .region-inner {
  flex-direction: column;
}
.front #zone-preface-wrapper .region-inner .title-wrap {
  display: none;
}
.node-type-landing-page .region-preface-first {
  height: 450px;
}
.node-type-landing-page .region-preface-first-inner {
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
}
.node-type-landing-page .region-preface-first-inner:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 1px;
  margin-left: -1px;
}
.node-type-landing-page .region-preface-first-inner > .title-wrap {
  border-top: 6px solid #EE482E;
  width: 90vw;
  max-width: 1200px;
  align-self: flex-end;
  background-color: #fff;
  margin: 0 auto;
  padding-bottom: 0 !important;
  order: 2;
}
.node-type-landing-page .region-preface-first-inner > .title-wrap .title {
  color: #ed5025;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.17px;
  line-height: 1.1;
  text-align: center;
  margin: 0 auto;
  max-width: 90%;
}
@media only screen and (min-width: 992px) {
  .node-type-landing-page .region-preface-first-inner > .title-wrap .title {
    font-size: 50px;
    letter-spacing: 0.17px;
    line-height: 1.1;
  }
}
.node-landing-page.view-mode-full .field-overview-text {
  color: #2F2F2F;
  font-family: "PT Sans";
  font-size: 20px;
  letter-spacing: 0.14px;
  line-height: 34px;
  text-align: center;
  max-width: 64ch;
  margin: 0 auto;
}
.node-type-listing-page .grid-9 {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-type-listing-page .grid-9 {
    grid-column: span 8;
  }
}
.node-type-listing-page .grid-3 {
  margin-top: 86px;
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-type-listing-page .grid-3 {
    grid-column: span 4;
  }
}
.node-type-listing-page .page-title {
  color: #ed5025;
  font-family: 'Lovelo Black', sans-serif;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.17px;
  line-height: 1.1;
  border-bottom: 1px solid #979797;
  line-height: 49px;
  padding-bottom: 2rem;
}
@media only screen and (min-width: 992px) {
  .node-type-listing-page .page-title {
    font-size: 50px;
    letter-spacing: 0.17px;
    line-height: 1.1;
  }
}
.node-type-listing-page .view-filters {
  display: none;
}
.node-type-listing-page .title-wrap {
  display: none;
}
#node-listing-page-12328 .page-title {
  border-bottom: 0;
}
.node-blog-post.view-mode-full {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 2rem;
  column-gap: 27px;
}
@media only screen and (min-width: 992px) {
  .node-blog-post.view-mode-full {
    margin-top: 4rem;
  }
}
.node-blog-post.view-mode-full .group-header {
  margin-bottom: 2rem;
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-blog-post.view-mode-full .group-header {
    grid-column: span 8;
  }
}
.node-blog-post.view-mode-full .group-header .field-post-date {
  color: #e0275e;
  font-family: 'PT Sans';
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.node-blog-post.view-mode-full .group-left {
  display: flex;
  flex-direction: column;
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-blog-post.view-mode-full .group-left {
    grid-column: span 8;
  }
}
.node-blog-post.view-mode-full .group-left .field-signature-image,
.node-blog-post.view-mode-full .group-left .field-signature-media {
  margin-bottom: 2rem;
}
.node-blog-post.view-mode-full .group-left .field-body {
  font-size: 17px;
}
@media only screen and (min-width: 1200px) {
  .node-blog-post.view-mode-full .group-left .field-body {
    padding: 0 72px;
  }
}
@media only screen and (min-width: 1200px) {
  .node-blog-post.view-mode-full .group-left .assets-container {
    padding: 0 72px;
  }
}
.node-blog-post.view-mode-full .group-left .assets-container .field-label {
  border-bottom: 1px solid #b2b2b2;
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.68px;
  line-height: 24px;
  padding-bottom: 0.5rem;
}
.node-blog-post.view-mode-full .group-left .assets-container .field-files a,
.node-blog-post.view-mode-full .group-left .assets-container .field-links a {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 22px;
}
.node-blog-post.view-mode-full .group-left .assets-container .field-files a:hover,
.node-blog-post.view-mode-full .group-left .assets-container .field-links a:hover,
.node-blog-post.view-mode-full .group-left .assets-container .field-files a:focus,
.node-blog-post.view-mode-full .group-left .assets-container .field-links a:focus {
  border-bottom: 1px solid #e0275e;
  text-decoration: none;
}
.node-blog-post.view-mode-full .group-right {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-blog-post.view-mode-full .group-right {
    grid-column: span 4;
  }
}
.node-blog-post.view-mode-full .group-footer {
  grid-column: span 12;
}
.node-blog-post.view-mode-teaser {
  border-bottom: 1px solid #979797;
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 0;
  column-gap: 27px;
}
.node-blog-post.view-mode-teaser.has-media {
  grid-template-columns: 3fr 5fr;
}
.node-blog-post.view-mode-teaser .group-right h4 {
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-transform: uppercase;
  margin-top: 0;
}
.node-blog-post.view-mode-teaser .group-right h3 > a {
  color: #94105e;
  font-family: 'PT Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.node-blog-post.view-mode-teaser .group-right .field-post-date {
  color: #e0275e;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.node-type-blog-post .title-wrap {
  display: none;
}
.view-display-id-news_related .view-content .views-row {
  margin-bottom: 2rem;
}
.author-container {
  display: flex;
  font-size: 1.1rem;
  margin-top: 1rem;
}
.author-container .author-label {
  margin-right: 0.25rem;
}
.author-container .author-name {
  font-weight: 700;
}
.node-service-detail.view-mode-full {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 27px;
}
@media only screen and (min-width: 992px) {
  .node-service-detail.view-mode-full {
    margin-top: 4rem;
  }
}
.node-service-detail.view-mode-full .group-header {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-service-detail.view-mode-full .group-header {
    grid-column: span 8;
  }
}
.node-service-detail.view-mode-full .group-left {
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
@media only screen and (min-width: 992px) {
  .node-service-detail.view-mode-full .group-left {
    grid-column: span 8;
  }
}
.node-service-detail.view-mode-full .group-left .field-body {
  font-size: 17px;
  grid-column: span 8;
}
@media only screen and (min-width: 992px) {
  .node-service-detail.view-mode-full .group-left .field-body {
    grid-column: 2 / span 6;
  }
}
.node-service-detail.view-mode-full .group-right {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-service-detail.view-mode-full .group-right {
    grid-column: span 4;
  }
}
.node-service-detail.view-mode-full .group-footer {
  grid-column: span 12;
}
.node-type-service-detail .title-wrap {
  display: none;
}
.field-patient-portal-link {
  margin-top: 2rem;
}
.field-patient-portal-link a {
  border: 2px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.39px;
  line-height: 20px;
  margin: 0 auto;
  min-width: 160px;
  padding: 18px 23px;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  border-color: #e0275e;
  color: #94105e !important;
}
.field-patient-portal-link a:hover,
.field-patient-portal-link a:focus {
  text-decoration: none;
}
.field-patient-portal-link a:hover,
.field-patient-portal-link a:focus {
  text-decoration: none;
}
.field-patient-portal-link a:hover,
.field-patient-portal-link a:focus {
  background-color: #e0275e;
  border-color: #e0275e;
  color: #fff !important;
}
.node-staff-profile.node-teaser {
  padding-bottom: 17px;
}
.node-staff-profile.node-teaser .field-signature-image,
.node-staff-profile.node-teaser .field-trans-image {
  overflow: hidden;
}
.node-staff-profile.node-teaser .field-trans-image {
  background: #e5e5e5;
}
.node-staff-profile.node-teaser .field-trans-image img {
  width: 100%;
}
.node-staff-profile.node-teaser .field-title {
  text-align: center;
}
.node-staff-profile.node-teaser .field-title h3 {
  font-family: 'PT Sans', sans-serif;
  font-size: 14.5px;
  letter-spacing: 0.05em;
  margin: 0 !important;
  text-align: center;
  text-transform: uppercase;
}
.node-staff-profile.node-teaser .field-title h3 > * {
  color: #890f5e;
}
.node-staff-profile.node-teaser .field-position-or-title {
  font-size: 11px;
  line-height: 1;
  text-align: center;
}
.node-staff-profile.view-mode-full.view-mode-full {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 4rem;
  column-gap: 27px;
}
.node-staff-profile.view-mode-full.view-mode-full .group-header {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-staff-profile.view-mode-full.view-mode-full .group-header {
    grid-column: span 8;
  }
}
.node-staff-profile.view-mode-full.view-mode-full .group-header .field-position-or-title {
  color: #e0275e;
  font-family: "PT Sans";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}
.node-staff-profile.view-mode-full.view-mode-full .group-left {
  display: flex;
  flex-direction: column;
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-staff-profile.view-mode-full.view-mode-full .group-left {
    grid-column: span 8;
  }
}
.node-staff-profile.view-mode-full.view-mode-full .group-left .field-signature-image {
  margin-bottom: 2rem;
  margin-left: 72px;
  margin-top: 2rem;
}
.node-staff-profile.view-mode-full.view-mode-full .group-left .field-signature-image img {
  max-width: 260px;
  aspect-ratio: 1;
}
.node-staff-profile.view-mode-full.view-mode-full .group-left .field-body {
  font-size: 17px;
}
@media only screen and (min-width: 1200px) {
  .node-staff-profile.view-mode-full.view-mode-full .group-left .field-body {
    padding: 0 72px;
  }
}
@media only screen and (min-width: 1200px) {
  .node-staff-profile.view-mode-full.view-mode-full .group-left .assets-container {
    padding: 0 72px;
  }
}
.node-staff-profile.view-mode-full.view-mode-full .group-left .assets-container .field-label {
  border-bottom: 1px solid #b2b2b2;
  color: #ed5025;
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.68px;
  line-height: 24px;
  padding-bottom: 0.5rem;
}
.node-staff-profile.view-mode-full.view-mode-full .group-left .assets-container .field-files a,
.node-staff-profile.view-mode-full.view-mode-full .group-left .assets-container .field-links a {
  color: #2f2f2f;
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0.57px;
  line-height: 22px;
}
.node-staff-profile.view-mode-full.view-mode-full .group-left .assets-container .field-files a:hover,
.node-staff-profile.view-mode-full.view-mode-full .group-left .assets-container .field-links a:hover,
.node-staff-profile.view-mode-full.view-mode-full .group-left .assets-container .field-files a:focus,
.node-staff-profile.view-mode-full.view-mode-full .group-left .assets-container .field-links a:focus {
  border-bottom: 1px solid #e0275e;
  text-decoration: none;
}
.node-staff-profile.view-mode-full.view-mode-full .group-right {
  grid-column: span 12;
}
@media only screen and (min-width: 992px) {
  .node-staff-profile.view-mode-full.view-mode-full .group-right {
    grid-column: span 4;
  }
}
.node-staff-profile.view-mode-full.view-mode-full .group-footer {
  grid-column: span 12;
}
.node-type-staff-profile .title-wrap {
  display: none;
}
/* --- [ Donate Now ] --------------- */
.page-donate #zone-content-wrapper,
.context-donate-now #zone-content-wrapper {
  padding-top: 0;
}
#fsPage1213473-1 {
  overflow: hidden;
}
table tr {
  border-bottom: 1px solid #dedede;
}
#fsCell15531034,
#fsCell15498037 {
  margin-bottom: 20px;
  padding-bottom: 20px;
}
#fsCell15531034 legend,
#fsCell15498037 legend {
  margin-bottom: 5px;
}
#fsCell15531034 input,
#fsCell15498037 input,
#fsCell15531034 label,
#fsCell15498037 label {
  float: left;
}
#fsCell15531034 label,
#fsCell15498037 label {
  margin: -3px 10px 0 5px;
}
#fsCell15531034 #field15531034_othervalue,
#fsCell15498037 #field15531034_othervalue {
  margin-top: -8px;
}
.fsForm .fsCell {
  padding: 9px !important;
}
input[type=button] {
  background: #008576;
  color: #fff;
  padding: 3px 15px;
  -webkit-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.3), 2px 2px 1px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.3), 2px 2px 1px rgba(0, 0, 0, 0.15) !important;
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.3), 2px 2px 1px rgba(0, 0, 0, 0.15) !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  line-height: 1;
  font-size: 13px !important;
  border: 0;
  cursor: pointer;
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
input[type=button]:hover {
  background: #e8941a;
}
.fsPagination {
  padding-right: 4px;
}
.page-node-10258 .view-mode-full .field-body {
  max-width: none;
  width: 100%;
}
