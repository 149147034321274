
// Media Queries
// ================================

@mobilemax: 480px;
@tabletmax: 768px;
@widemin: 1220px;





// Responsive
// ================================

.resp(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}
.resp-min(@minWidth; @rules) {
  @media only screen and (min-width: @minWidth) {
    @rules();
  }
}
.resp-range(@minWidth; @maxWidth; @rules) {
  @media only screen and (min-width: @minWidth) and (max-width: @maxWidth) {
    @rules();
  }
}





// JS Breaks
// ================================

.js-breaks__ {
  &mobilemax { width: @mobilemax; }
  &tabletmax { width: @tabletmax; }
  &widemin { width: @widemin; }
}