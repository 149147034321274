.region-branding-inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;

  @media @sm-and-up {
    flex-wrap: nowrap;
    margin: 10px 0;
  }
}

.branding-data {
  flex-grow: 1;
  padding-left: 20px;

  @media @md-and-up {
    padding: 0;
  }
}



#logo {
  width: 240px;
  @media @md-and-up {
    width: 284px;
  }
}


.mobileNavOpen {
  .menu-name-menu-call-out-links .menu {
    display: flex !important;


  }
}

.menu-name-menu-call-out-links .menu {

  display: none;
  flex-direction: column;
  margin-left: 22px;
  margin-right: 16px;
  margin-top: 1em;
  padding-top: 2em;
  padding-bottom: 1em;
  border-top: 1px solid #979797;
  row-gap: 1.5em;
  width: calc(100vw - 38px);

  @media @sm-and-up {
    flex-direction: row;
    margin-left: 2rem;
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    align-items: center;
    border-top: 0;
    width: auto;
    padding: 0
  }


  > li {



      @media @sm-and-up {
        margin-left: 2rem;
      }
    > a,
    > span {
      color: #e0275e;
      font-family: "PT Sans";
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-transform: uppercase;
      &:before {
        color: #e42a5d;
        display: inline-block;
        font-family: "Font Awesome 5 Free";
        font-size: 1.5rem;
        font-style: normal;
        font-variant: normal;
        padding-right: .5rem;
        text-rendering: auto;
      }
    }
  }


  .menu-title-donate-now > a {
    align-items: center;
    display: flex;
    &:before {
      content: "\f4b9";
    }
  }

  .menu-title-patient-portal > a {
    align-items: center;
    display: flex;
    &:before {
      content: "\f812";
    }
  }

  .menu-title-search > span {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 0;
    &:before {
      content: "\f002";
      font-size: 1.5rem;
      margin-right: -7px;
    }

    &:focus {
      outline: none;
    }
  }
}

#search-helper {
  &.searchOpen {
    &:before {
      content: "";
      margin-right: 0;
    }
  }
}

#block-search-form #search-close {
  border: 0;
  padding: 0;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  span:before {
    border: 0;
    color: #cbccce;
    content: "\f00d";
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-size: 1.5rem;
    font-style: normal;
    font-variant: normal;
    margin-right: 0;
    text-rendering: auto;
  }
}

#block-search-form #search-submit {
  border: 0;
  margin-right: 1rem;
  padding: 0;

  &:hover,
  &:focus {
    cursor: pointer;
  }
  span:before {
    border: 0;
    color: #e42a5d;
    content: "\f002";
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-size: 1.5rem;
    font-style: normal;
    font-variant: normal;
    margin-right: 0;
    text-rendering: auto;
  }
}

#block-search-form {
  .form-submit {
    display: none;
  }
}
