/* --- [ Reusable Styles ] --------------- */

.cf {
  &:before,
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

.abs(@top:auto; @right:auto; @bottom:auto; @left:auto) {
  position: absolute;
  top: @top;
  right: @right;
  bottom: @bottom;
  left: @left;
}

.abs-0 {
  .abs(0, 0, 0, 0);
}

.unbullet {
  margin: 0;
  padding: 0;
  list-style: none;
}

.r(@radius:3px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.shadow(@off-x:1px,@off-y:1px,@blur:1px,@color:rgba(0,0,0,0.25)) {
  text-shadow: @off-x @off-y @blur @color;
}

.noshadow {
  text-shadow: 0 0 0 transparent;
}

.boxshadow(@off-x:4px,@off-y:4px,@blur:0,@spread:0,@color:rgba(0,0,0,0.25)) {
  -webkit-box-shadow: @off-x @off-y @blur @spread @color;
  -moz-box-shadow: @off-x @off-y @blur @spread @color;
  box-shadow: @off-x @off-y @blur @spread @color;
}

.noboxshadow {
  -webkit-box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
}

.trans(@prop:all,@time:0.25s,@mode:ease,@delay:0ms) {
  -webkit-transition: @prop @time @mode @delay;
  -moz-transition: @prop @time @mode @delay;
  -o-transition: @prop @time @mode @delay;
  transition: @prop @time @mode @delay;
}

.sizing(@mode:border-box) {
  -webkit-box-sizing: @mode;
  -moz-box-sizing: @mode;
  box-sizing: @mode;
}

.font-smoothing(@value) when (@value = on) {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

.font-smoothing(@value) when (@value = off) {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-smoothing: subpixel-antialiased;
}

.transform(@value:none) {
  -webkit-transform: @value;
  -moz-transform: @value;
  -o-transform: @value;
  -ms-transform: @value;
  transform: @value;
}

.grad {
  background-image: linear-gradient(
    top,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(255, 255, 255, 0.3)),
    color-stop(1, rgba(255, 255, 255, 0))
  );
}

.tile {
  //background: @p2 url("../images/bg-tile.jpg") center center repeat;
}

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

.element-visible {
  position: static !important;
  clip: auto;
  overflow: hidden;
  height: auto;
}

// For inputs
.placeholder(@rules) {
  &::-webkit-input-placeholder {
    @rules();
  }
  &:-moz-placeholder {
    @rules();
  }
  &::-moz-placeholder {
    @rules();
  }
  &:-ms-input-placeholder {
    @rules();
  }
}

.button {
  .r(0);

  display: inline-block;
  float: none;
  background-image: none !important;
  background: @white;
  padding: 10px 30px;
  color: @pink-500 !important;
  border: 2px solid @pink-500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  .noshadow;
  .trans;
  .pt;

  &:hover {
    border-color: @p2 !important;
    color: @p2 !important;
    text-decoration: none;
  }

  &:focus {
    transform: translateY(2px);
    -webkit-transform: translateY(2px);
    //.boxshadow(0,2px,0,@b0);
  }

  &.disabled,
  &.cancelled {
    background: transparent !important;
    border-color: @g1 !important;
    color: @g1 !important;
    opacity: 0.5;
    pointer-events: none;

    &:hover,
    &:focus {
      border-color: @g1 !important;
      color: @g1 !important;
      opacity: 0.5;
      transform: none;
    }
  }
}

.full-bleed {
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  position: relative !important;
  right: 50% !important;
  width: 100vw !important;
}

.sidebar-wrapper {
  border: 1px solid #979797;
  border-top: 4px solid #ee482e;
  padding: 25px 40px;
  margin-bottom: 2rem;
}

.rem-calc(@sizeValue) {
  @pxValue: @sizeValue;
  @remValue: (@sizeValue / 16);
  font-size: ~"@{pxValue}px";
  font-size: ~"@{remValue}rem";
}
