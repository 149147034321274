/* --- [ Videos ] --------------- */

.embedded-video {
  background: @g2;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 100%;
  .region-content .node & {
    margin-left: 0;
    max-width: 620px;
  }
}

.field-signature-video {
  position: relative;
}
.video-embed-description {
  .sizing;

  background: fade(@p2, 80%);
  bottom: 0;
  color: @white;
  left: 0;
  padding: 12px 18px;
  position: absolute;
  right: 0;
}

.player {
  padding-bottom: 56.33%;
  position: relative;

  .ratio {
    display: block;
    height: auto;
    width: 100%;
  }

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
