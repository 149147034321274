.node-staff-profile {
  &.node-teaser {
    padding-bottom: 17px;

    .field-signature-image,
    .field-trans-image {

      overflow: hidden;
    }
    .field-trans-image {
      background: @g3;

      img {
        width: 100%;
      }
    }

    .field-title {
      text-align: center;

      h3 {
        .pt;

        font-size: 14.5px;
        letter-spacing: .05em;
        margin: 0 !important;
        text-align: center;
        text-transform: uppercase;

        > * {
          color: @p2;
        }
      }
    }

    .field-position-or-title {
      font-size: 11px;
      line-height: 1;
      text-align: center;
    }
  }

  &.view-mode-full {
    &.view-mode-full {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      margin-top: 4rem;

      column-gap: @grid-gutter-width;


      .group-header {
        grid-column: span 12;
        @media @md-and-up {
          grid-column: span 8;
        }

        .field-position-or-title {
          color: #e0275e;
          font-family: "PT Sans";
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
        }
      }

      .group-left {
        display: flex;
        flex-direction: column;
        grid-column: span 12;
        @media @md-and-up {
          grid-column: span 8;
        }




        .field-signature-image {
          margin-bottom: 2rem;
          margin-left: 72px;
          margin-top: 2rem;

          img {
            max-width: 260px;

            aspect-ratio: 1/1;
          }
        }

        .field-body {
          font-size: 17px;
          @media @lg-and-up {
            padding: 0 72px;
          }
        }

        .assets-container {
          @media @lg-and-up {
            padding: 0 72px;
          }
          .field-label {
            .assets-item-heading;
          }

          .field-files,
          .field-links {
            a {
              .assets-item;
            }
          }
        }
      }

      .group-right {
        grid-column: span 12;
        @media @md-and-up {
          grid-column: span 4;
        }
      }

      .group-footer {
        grid-column: span 12;
      }
    }
  }
}

.node-type-staff-profile .title-wrap {
  display: none;
}
