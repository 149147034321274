.node-page {
  &.view-mode-full {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: @grid-gutter-width;


    .group-header {
      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 8;
      }
    }

    .group-left {
      grid-column: span 12;
      @media @md-and-up {
        grid-column: span 8;
      }

      display: flex;
      flex-direction: column;


      .field-signature-image,
      .field-signature-media {
        margin-bottom: 2rem;
      }

      .field-body {
        font-size: 17px;
        @media @lg-and-up {
         padding: 0 72px;
        }
      }

      .assets-container {
        @media @lg-and-up {
          padding: 0 72px;
         }
        .field-label {
        .assets-item-heading;
        }

        .field-files,
        .field-links {
          a {
            .assets-item;
          }
        }

      }
    }

    .group-right {
      grid-column: span 12;
      margin-top: 3rem;
      @media @md-and-up {
        grid-column: span 4;
        margin-top: 0;
      }
    }

    .group-footer {
      grid-column: span 12;
    }
  }
}
.node-type-page .title-wrap {
  display: none;
}
