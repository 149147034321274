.paragraphs-item-story {
  .full-bleed;

  display: flex;
  flex-direction: column;


  @media @md-and-up {
    background: linear-gradient(to right, #e0275e 0, #e0275e 25%, #f9f9f9 25%, #f9f9f9 100%);
    display: grid;
    grid-template-columns: 1fr 1fr;

    column-gap: 0;
    row-gap: 0;
  }

  .group-header {
    @media @md-and-up {
      grid-column: span 2;
    }
  }

  .group-left {
    background: linear-gradient(to bottom, #e0275e 0, #e0275e 60%, #fff 60%, #fff 100%);
    padding: 22px 18px 0 18px;
    width: auto;
    @media @md-and-up {
      background: transparent;
      float: none;
      padding: 80px 0;
    }
  }

  .group-right {
    padding: 22px 18px 0 18px;
    width: auto;
    @media @md-and-up {
      align-items: center;
      display: flex;
      float: none;
      padding-top: 80px;
      padding-right: 80px;
      padding-bottom: 0;
      padding-left: 80px;
    }


    .story-body-container {
      .field-pg-heading {
        .story-heading;
      }

      .field-pg-body p {
        .story-text;
      }
    }
  }

  .cta--container {
    justify-content: flex-start;
  }

  &.img-right {

    @media @md-and-up {
    background: linear-gradient(to right, #f9f9f9 0, #f9f9f9 75%, #e0275e 75%, #e0275e 100%);
    }

    .group-right {
      background: linear-gradient(to bottom, #e0275e 0, #e0275e 60%, #fff 60%, #fff 100%);
      float: none;
      padding: 22px 18px 0 18px;
      width: auto;

      @media @md-and-up {
        background: transparent;
        padding: 80px 0;
      }

      @media @xs-only {
        order: 1;
      }
    }

    .group-left {
      padding: 22px 18px 0 18px;
      width: auto;
      background: transparent;
      @media @md-and-up {
        align-items: center;
        display: flex;
        float: none;
        padding-top: 80px;
        padding-right: 80px;
        padding-bottom: 0;
        padding-left: 80px;
      }



      @media @xs-only {
        order: 2;
      }


      .story-body-container {
        .field-pg-heading {
          .story-heading;
        }

        .field-pg-body p {
          .story-text;
        }
      }
    }
  }
}
