.field-ds-subnav {
  .sidebar-wrapper;

  h2 > a {
    .sidebar-item-heading;
  }

  .menu {
    margin-left: 0;
    > li {
      > a {
        .tag-filter;

        &.active-trail {
          text-decoration: underline;
        }
      }
    }
  }
}
