
blockquote {
  .blockquote-copy;


  border-left: 4px solid #ED5025;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: @grid-gutter-width;

  p {
    .blockquote-copy;
  }
}
